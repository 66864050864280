import React,{useState} from 'react';
import {Inputs,PostAsync} from '../helpers/Functions';
import InputSimple from '../components/InputSimple';
import Button from 'react-bootstrap/Button';
const App=(props)=>{
  const context = props.context;
  const [inputs, setInputs] = useState({});
  const onChange=(e)=>{
    Inputs(e,inputs, setInputs)
  }
  const onSubmit=(e)=>{
    e.preventDefault()
    let inputs_={...inputs}
    PostAsync("Users","SetNewPass",inputs_,{...context}).then((response) => {
      context.setModalShow({
                              show:false,
                              message: "",
                              size:""
                            }
                          )
    })
  }
  return  <form onSubmit={onSubmit}>
            <div>
              <InputSimple  className="form-control"
                      required={true}
                      type="password"
                      name="password"
                      placeholder={"Contraseña nueva..."}
                      onChange={onChange}
              />
            </div>
            <div className="mt-3">
              <Button type="submit">
                Actualizar
              </Button>
            </div>
          </form>
}
export default App
