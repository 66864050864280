import React,{useState,useEffect} from 'react';
import UploadFile from '../../components/uploadFile'
import StateContext from '../../helpers/ContextState';
import {Inputs,PostAsync} from '../../helpers/Functions';
const App=()=>{
  const context                   =   React.useContext(StateContext);
  const [uploadHojaVida, setUploadHojaVida] = useState(false);
  const [data, setData]           =   useState([]);

  const onSubmit=(e)=>{
    e.preventDefault()
    PostAsync("Inventario","Upload",{file:JSON.stringify(uploadHojaVida)},{...context}).then((response) => {
      if (response.data) {
        setData(response.data)
      }
    })
  }
  return  <div className="container">
            <form className="row" onSubmit={onSubmit}>
              <div className="col">
                <UploadFile
                            upload={uploadHojaVida}
                            setUpload={setUploadHojaVida}
                            title={"Seleccione archivo"}/>
              </div>
              <div className="col">
                <button type="submit" className="btn btn-primary btn-block">
                  Enviar
                </button>
              </div>
            </form>
            <div className="row">
              <table className="table">
                <thead>
                  <th className="text-center">Producto</th>
                  <th className="text-end">Precio Asociado</th>
                  <th className="text-end">Precio No asociado</th>
                  <th className="text-end">IVA</th>
                  <th className="text-center">Uni Medida</th>
                </thead>
                <tbody>
                  {data.map((row,key)=>{
                    return  <tr key={key}>
                              <td className="text-center">
                                {row.Producto}
                              </td>
                              <td className="text-end">
                                {row["Precio Asociado"]}
                              </td>
                              <td className="text-end">
                                {row["Precio No Asociado"]}
                              </td>
                              <td className="text-end">
                                {row["IVA"]}
                              </td>
                              <td className="text-center">
                                {row["Unidad Medida"]}
                              </td>
                            </tr>
                  })}
                </tbody>
              </table>
            </div>
          </div>
}
export default App
