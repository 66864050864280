import React, { useState, useEffect } from 'react';
import { PostAsync } from '../helpers/Functions';
import Input from './InputSimple';
//import Select from './Select';
import SelectorMultiple from "./SelectorMultiple"
import StateContext from '../helpers/ContextState'
import FormControl from 'react-bootstrap/FormControl'
let filter = "";
const App = ({ onChange, inputs, setInputs }) => {
  const context = React.useContext(StateContext);
  const [semanas, setSemanas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [inputs2, setInputs2] = useState({});

  const onChangeLocal = () => {

  }

  useEffect(() => {
    getInit()
  }, [])

  const getInit = () => {
    PostAsync("Inventario", "getFiltersData", {}, context).then((response) => {
      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas)
      }
      if (response.data && response.data.proveedores) {
        setProveedores(response.data.proveedores)
      }
      if (response.data && response.data.marcas) {
        // console.log(response.data.marcas);
        setMarcas(response.data.marcas)
      }
      if (response.data && response.data.clientes) {
        setClientes(response.data.clientes)
      }
      if (response.data && response.data.productos) {
        setProductos(response.data.productos)
      }
    })
  }
  return <div className="card" style={{ marginTop: '50px' }}>
    <div className="card-header bg-primary text-white">Filtros de búsqueda</div>
    <div className="card-body">
      <div className="row">
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            title="Fecha desde"
            placeholder="Fecha desde"
            type="date"
            name="fecha_inicio"
            className="form-control"
            onChange={onChange}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            title="Fecha hasta"
            placeholder="Fecha hasta"
            type="date"
            name="fecha_fin"
            className="form-control"
            onChange={onChange}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            name="semana"
            title="Semana"
            selectDefault="Semana"
            data={semanas}
            inputs={inputs}
            setInputs={setInputs}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            name="op_inventario_items_id"
            url="/Inventario/ListadoProductos"
            title="Producto"
            selectDefault="Producto"
            data={productos}
            inputs={inputs}
            setInputs={setInputs}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            name="proveedor_id"
            url="/Usuarios/Lista"
            title="Proveedor"
            selectDefault="Proveedor"
            data={proveedores}
            inputs={inputs}
            setInputs={setInputs}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <SelectorMultiple
            url="/Inventario/ListadoMarcas"
            name="marca_id"
            title="Marca"
            selectDefault="Marca"
            data={marcas}
            inputs={inputs}
            setInputs={setInputs}
            className="form-control"
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <FormControl
            title="Marca"
            name="filter"
            onChange={onChange}
            placeholder="Observación"
            aria-describedby="basic-addon2"
          />
        </div>
      </div>
    </div>
  </div>
}

export default App
