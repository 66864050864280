import React,{useEffect,useState} from 'react';
import StateContext from '../../helpers/ContextState';
import {Inputs,PostAsync} from '../../helpers/Functions';
import banner from '../../assets/images/banner.jpg';
import logo from '../../assets/images/logo.jpg';
import { useParams, useHistory  } from "react-router-dom";

const defaultValue={}

const App=()=>{
  const params              =   useParams();
  let history               =   useHistory();
  const context             =   React.useContext(StateContext);
  const [inputs, setInputs] =   useState(defaultValue);
  const [send, setSend]     =   useState(false);

  useEffect(() => {
    context.loading(false)
    //TokenIvalid
  },[context,send])

  const onSubmit=(e)=>{
    e.preventDefault()
    let input_    = {...inputs}
        input_.id = params.id
    PostAsync("Users","RecoverSetPassword",input_,{...context}).then((response)=>{
      history.push("../");
    })
  }

  const onChange=(e)=>{
    Inputs(e,inputs, setInputs)
  }

  return    <>
              {!context.loadingStatus?<>
                <div className={"container-fluid h-100"}>
                      <div className="row h-100">
                        <div className="col-12 col-sm-6 h-100 d-none d-sm-block">
                          <div className="App-header">
                            <div className="h1 text-white" >
                              <img src={banner} alt="programandoweb"  className="img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 right h-100">
                          <div className="App-header">
                            <img src={logo} alt="programandoweb"  />
                            <form onSubmit={onSubmit} className="row justify-content-center">
                              <div className="col-9 mb-3 h3 text-center border-bottom  border-danger border-5 pb-2">
                                Recuperación de contraseña
                              </div>
                              {params.id!=="TokenIvalid"?<>
                                  <div className="col-10">
                                    <div className="row justify-content-center">
                                      <div className="col-12 mb-2">
                                        <input  className="form-control"
                                                required={true}
                                                type="password"
                                                name="password"
                                                placeholder="Ingrese su nueva contraseña"
                                                onChange={onChange}
                                        />
                                      </div>
                                      <div className="col-12 mb-2">
                                        <div className="d-grid gap-2">
                                          <button type="submit" className="btn btn-danger">Reiniciar contraseña</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>:<>
                                <div className="col-10 h2 text-center">
                                  Token inválido
                                </div>
                              </>}

                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
              </>:false}
            </>



}
export default App
