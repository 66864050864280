import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import SelectorGenerico from '../../components/SelectorGenerico';
import StateContext from '../../helpers/ContextState';
import { PostAsync, formatoFecha, format, Inputs } from '../../helpers/Functions';
import Input from '../../components/InputSimple';
import { useParams } from "react-router-dom";
import Config from '../../helpers/Config';
import SelectorMultiple from "../../components/SelectorMultiple"
import BotonVolverHome from '../../components/BotonVolverHome';
import FormControl from 'react-bootstrap/FormControl'

import Store from '../../helpers/Store'

import { faCheck, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type } from 'os';

let btn_back = false
var previousState = 0
window.addEventListener('popstate', (event) => {
  if (event.state > previousState) {
    btn_back = false
  } else {
    btn_back = true
  }
  previousState = event.state
})

let hoy = formatoFecha(new Date(), "yy-mm-dd");
let paginacion = []
// let deudaFinal = [1,2,3,4,5];
let sumaDeuda = 0;

const InventarioCartera = (props) => {
  let limit, limit2 = parseInt(process.env.REACT_APP_NUM_X_PAGE);
  const [deudaTotalFinal, setDeudaTotalFinal] = useState([]);
  const [deudaFinal, setDeudaFinal] = useState([]);
  const context = React.useContext(StateContext);
  const [param, setParam] = useState({});
  const [data, setData] = useState([]);
  const [sumatotal, setSumaTotal] = useState([]);
  const [btn, setBtn] = useState(false);
  const [totalLineas, setTotalLineas] = useState([]);//////
  const [totalLineasPeso, setTotalLineasPeso] = useState([]);//////
  const [totalLineasTotal, setTotalLineasTotal] = useState([]);//////
  const [btnPagar, setBtnPagar] = useState([]);//////
  const [totalMenosAbonos, setTotalMenosAbonos] = useState(0);//////
  const [totalGeneralFooter, setTotalGeneralFooter] = useState(0);//////
  const [cliente, setCliente] = useState("Sin cliente");
  const [abonos, setAbonos] = useState([]);//////
  const params = useParams();
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [inputs, setInputs] = useState({});
  const [semanas, setSemanas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [inputs2, setInputs2] = useState({});
  const [reset, setReset] = useState(false);
  const [precio_totalizado_final_string, setprecio_totalizado_final_string] = useState("");
  const [cantidad_totalizado_final_string, setcantidad_totalizado_final_string] = useState("");
  const [peso_totalizado_final_string, setpeso_totalizado_final_string] = useState("");
  const [total_rows_empty, setTotal_rows_empty] = useState(true);
  const [open, setOpen] = useState(false);
  const [plantas, setPlantas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [totalizacion, setTotalizacion] = useState({});
  const [paginas, setPaginas] = useState([]);

  const [paginaActual, setPaginaActual] = useState({ label: 1, start: 0 });
  const params_url = useParams();


  let filter = "";
  useEffect(() => {
    basicInputs()
  }, [])

  useEffect(() => {
    getInit()
  }, [search])

  useEffect(() => {
    if (param.ma_periodicidad_id) {
      getInit()
    } else {
      setData([])
      setTotal(0)
    }
  }, [param])

  useEffect(() => {
    getInit()
    console.log("inputs2: "+inputs2.value);
    console.log("inputs: "+inputs);
  }, [inputs, inputs2])

  const getInitAbonos = () => {
    setData([])


  }

  const getInit = (obj) => {
    PostAsync("Inventario", "cartera_detalle", { id: params.id }, context).then((response) => {

      response.cliente && setCliente(response.cliente);
      response.abonos && setAbonos(response.abonos);
      response.total_menos_abonos && setTotalMenosAbonos(response.total_menos_abonos);

      if (response.data && Object.entries(response.data).length > 0) {
        //data_.push({label:row[0],items:row[1],uid:row[1].token,key:key,pagar:true})

        let data_ = []
        let totalGeneral_ = {}
        let totalLineas_ = 0
        let totalLineas__ = []

        let totalLineasPeso_ = 0
        let totalLineasPeso__ = []

        let totalLineasTotal_ = 0
        let totalLineasTotal__ = []

        let btnPagar_ = []
        let btnPagar__ = []
        let activeOnBtn__ = false

        let totalGeneralFooter = 0

        Object.entries(response.data).map((row, key) => {
          data_.push({ label: row[0], items: row[1] })

          totalLineas_ = 0
          totalLineasPeso_ = 0
          totalLineasTotal_ = 0
          btnPagar_ = []

          row[1].map((row2, key2) => {
            totalLineas_ += parseFloat(row2.cantidad)
            totalLineasPeso_ += parseFloat(row2.peso)
            totalLineasTotal_ += parseFloat(row2.total_restante)
            btnPagar_.push({ ...row2, uid: row2.token, key: key2, pagar: true })
            activeOnBtn__ = true
            totalGeneralFooter += parseFloat(row2.total_restante)
          })

          setTotalGeneralFooter(totalGeneralFooter)
          totalLineas__.push(totalLineas_)
          totalLineasPeso__.push(totalLineasPeso_)
          totalLineasTotal__.push(totalLineasTotal_)
          btnPagar__.push(btnPagar_)

        })

        setBtn(activeOnBtn__)
        setBtnPagar(btnPagar__);
        setTotalLineas(totalLineas__);
        setTotalLineasPeso(totalLineasPeso__);
        setTotalLineasTotal(totalLineasTotal__);
        setData(data_)

      }
    })
    //console.log(obj);
    setData([])
    let data_ = { ...props }
    if (props.params && typeof props.params === "object") {
      Object.entries(props.params).map((row, key) => {
        return data_[row[0]] = row[1]
      })
      delete (data_.params);
    }

    delete (data_.td);
    data_.fecha = hoy;
    data_.limit = process.env.REACT_APP_NUM_X_PAGE
    data_.start = obj ? obj.paginaActual.start : paginaActual.start
    if (filter !== '') {
      data_.filter = filter
    }
    let parameters_send = { ...data_, ...inputs, filtroMultiple: JSON.stringify(inputs2), ...params_url, active: true }
    let parameters_save = { data: data_, inputs: inputs, inputs2: inputs2, params_url: params_url, active: true }

    if (!btn_back || (btn_back && !Store.get(window.location.pathname).active)) {
      //console.log("si llena");
      Store.set(window.location.pathname, parameters_send)
      Store.set(window.location.pathname + "save", parameters_save)
    } else if (btn_back && Store.get(window.location.pathname).active) {
      //console.log("llena del store",Store.get(window.location.pathname));
      parameters_send = Store.get(window.location.pathname)
      parameters_save = Store.get(window.location.pathname + "save")
      btn_back = false
      setInputs(parameters_save.inputs)
      console.log(parameters_save.inputs);
      setInputs2(parameters_save.inputs2)
      console.log(inputs);
      setReset(true)
      setTimeout(function () {
        setReset(false)
      }, 1000);
    }
    // Store.set(window.location.pathname,{...data_,...inputs,filtroMultiple:JSON.stringify(inputs2),...params_url})

    PostAsync("Inventario", "cartera", { ...inputs, ...param, filtroMultiple: JSON.stringify(inputs2), search: search }, context).then((response) => {
      console.log(JSON.stringify(inputs));
      setprecio_totalizado_final_string(response.precio_totalizado_final_string)
      setcantidad_totalizado_final_string(response.cantidad_totalizado_final_string)
      setpeso_totalizado_final_string(response.peso_totalizado_final_string)
      //return setOpen(true);
      if (response === undefined || response.data === undefined) {
        return false;
      }

      if (response.data && (response.data.total_rows === undefined || response.data.total_rows === '0')) {
        setData([])
        setTotal_rows_empty(false)
        setOpen(true)
      }

      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas)
      }

      if (response.data && response.data.plantas) {
        setPlantas(response.data.plantas)
      }

      // if (response.data && response.data.proveedores) {
      //   setProveedores(response.data.proveedores)
      // }
      //console.log(response.data);
      if (response.data && response.data.marcas) {
        setMarcas(response.data.marcas)
      }

      if (response.data && response.data.clientes) {
        setClientes(response.data.clientes)
      }


      if (response.data && response.data.productos) {
        setProductos(response.data.productos)
      }

      if (response.totalizacion) {
        setTotalizacion(response.totalizacion)
      }

      let trows = (response.data.total_rows !== undefined) ? parseInt(response.data.total_rows) : 0;
      limit = parseInt(process.env.REACT_APP_NUM_X_PAGE)
      paginacion = Math.ceil(trows / process.env.REACT_APP_NUM_X_PAGE)
      setData(response.data.rows)
      // console.log(response.sumaTotal.suma_total)
      setSumaTotal(response.sumaTotal)
      // setDeudaFinal(response.data.rows)

      for (let index = 0; index < response.data.rows.length; index++) {
        // setDeudaFinal(response.data.rows[index].total_deuda_actual_string);

      }



      if (response.data && response.data.total_rows && response.data.total_rows !== "0") {
        //setTotal_rows_empty(false)
        setTotal_rows_empty(response.data.total_rows)
      }

      /*cambio*/
      let pages = []
      let ultimo = 0
      for (var i = 0; i < paginacion; i++) {
        ultimo = limit2 * (i)
        pages.push({ label: i + 1, start: ultimo })
      }
      setPaginas(pages)

      setOpen(true)
    })
  }


  const basicInputs = () => {
    PostAsync("Inventario", "cartera", { ...param }, context).then((response) => {

      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas)
      }

      //console.log(response.data);
      if (response.data && response.data.marcas) {
        setMarcas(response.data.marcas)
      }

      if (response.data && response.data.clientes) {
        setClientes(response.data.clientes)
      }

    })
  }

  const selection = (row, key) => {
    let data_ = [...data]
    data_[key].pagar = (data_[key].pagar === true) ? false : true;
    setData(data_)
    if (checkActive()) {
      setBtn(true)
    } else {
      setBtn(false)
    }

    let checkedItems = data_.filter((elem) => elem.pagar)
    data.length === checkedItems.length ? setSelectAll(true) : setSelectAll(false)
  }

  const handleSubmitPagar = () => {
    if (checkActive()) {
      PostAsync("Pagos", "SetPagos", { data: JSON.stringify(data) }, context).then((response) => {
        getInit()
      })
    }
  }

  const checkActive = () => {
    const boolean = data.find(search => search.pagar === true)
    return (boolean !== undefined) ? true : false
  }

  const onChangeCustom = (e) => {
    Inputs(e, inputs, setInputs)
  }

  const handleCheck = () => {
    let data_ = []
    let seleccion = selectAll

    data.map((row, key) => {
      row.pagar = seleccion ? false : true
      data_.push(row)
    })
    setData(data_)
    setSelectAll((seleccion) ? false : true)
  }

  // parseInt(deudaFinal)
  // new Intl.NumberFormat('de-DE').format(sumaDeuda)
  // for(let i of deudaFinal)
  // sumaDeuda+=i
  // console.log(deudaFinal[0]+'SUMAAAA:'+sumaDeuda + 'Tipo:' +typeof sumaDeuda)
  // parseFloat(deudaFinal[0])
  // parseFloat(deudaFinal[1])
  // sumaDeuda = deudaFinal[0] + deudaFinal[1]
  // // console.log(sumaDeuda+ 'Tipo: '+typeof sumaDeuda)
  // // console.log(parseFloat(deudaFinal[1]))
  // for (let index = 2; index < deudaFinal.length; index++) {
  //   sumaDeuda += deudaFinal[index]

  // }
  // console.log(sumaDeuda)
  // setDeudaTotalFinal(sumaDeuda)
  const objetoSerializado = encodeURIComponent(JSON.stringify(inputs2));

  return (
    <div className="container">
      <BotonVolverHome />
      <div className="row mb-3 pt-1">
        <a className="btn btn-primary col-1 m-1 " href={Config.ConfigApirest +
          "PDF/Inventario/cartera/any?pdfBool=true&fecha_inicio=" +
          inputs.fecha_inicio +
          "&fecha_fin=" + inputs.fecha_fin + "&ma_periodicidad_id="+param.ma_periodicidad_id +
          "&filtroMultiple=" + objetoSerializado} target="_blank">PDF</a>
          
        {/* <div onClick="" className="btn btn-secondary col-1 m-1" href={props.toPDF} target="_blank">Exportar</div> */}
      </div>
      {context.user.tipo_usuario_id == "4" ? false
        :
        <div className="row justify-content-center mb-3">
          <div className="col-12">
            <SelectorGenerico modelo="Maestros"
              metodo="get_tablas_maestras"
              tabla="ma_periodicidad"
              param={param}
              lg="3"
              name="ma_periodicidad_id"
              setParam={setParam} />
          </div>
        </div>}

      <div className="row bg-gray">
        <b>Filtros de búsqueda</b>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            title="Fecha desde"
            placeholder="Fecha desde"
            type="date"
            name="fecha_inicio"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            title="Fecha hasta"
            placeholder="Fecha hasta"
            type="date"
            name="fecha_fin"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            name="semana"
            title="Semana"
            selectDefault="Semana"
            data={semanas}
            inputs={inputs2}
            setInputs={setInputs2}
            onChange={onChangeCustom}
            className="form-control"
          />
        </div>
        <div className="col-3">
          <FormControl
            defaultValue={filter}
            name="filter"
            onChange={onChangeCustom}
            placeholder="Buscador ID o NIT"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <br />
        </div>
        {/* {console.log(onChangeCustom)} */}
        {context.user.tipo_usuario_id == "4" ? false
          :
          <div className="col-12 col-sm-3 mb-3 pt-1 text-left border-end">
            <SelectorMultiple
              url="/Usuarios/Lista"
              name="cliente_id"
              title="Cliente"
              selectDefault="Cliente"
              data={clientes}
              inputs={inputs2}
              onChange={onChangeCustom}
              setInputs={setInputs2}
              className="form-control"
            />
          </div>}
      </div>
      <div className="row">
        <div className="col">
          <table className="table">
            <thead>
              <tr>
                <th className="col text-center">
                  Consecutivo
                </th>
                <th className="col text-center">
                  Fecha de venta
                </th>
                <th className="col">
                  Semana
                </th>
                <th className="col text-center">
                  ID o NIT
                </th>
                <th className="col">
                  Correo contacto
                </th>
                <th className="col text-center">
                  Teléfono contacto
                </th>
                <th className="col">
                  Dirección
                </th>
                <th className="col">
                  Cliente
                </th>
                <th className="col text-end">
                  Monto Total Adeudado
                </th>
                <th className='col-2 text-center'>
                  Acciones
                </th>
                {/* <th className="text-center col-3">
                  {data.length > 0 &&
                  <div className="row">
                    <div className="col-11 text-end">
                      <div className={selectAll?"btn btn-primary btn-sm me-2":"btn btn-secondary btn-sm me-2"} onClick={handleCheck}>
                        <FontAwesomeIcon icon={faCheck}/>
                      </div>
                      <div className={btn?"btn btn-primary btn-sm":"btn btn-secondary btn-sm"} onClick={handleSubmitPagar}>
                        <FontAwesomeIcon icon={faDollarSign}/> Pagar seleccionado
                      </div>
                    </div>
                  </div>}
                </th> */}
              </tr>
            </thead>
            <tbody>
            { 
                        data.length == 0 ? <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>Sin resultados...</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>:
              data.map((row, key) => {
                // console.log(data)



                // {deudaFinal.push(parseInt(row.total_deuda_actual_string))}
                // {deudaFinal.push(parseFloat(row.total_deuda_actual))}

                return <tr key={key}>
                  <td className='text-center'>
                    {row.consecutive}
                  </td>
                  <td className='col-2 text-center'>
                    {row.fecha_string}
                  </td>
                  <td className='text-center'>
                    {row.semana}
                  </td>
                  <td>
                    {row.nro_documento}
                  </td>
                  <td className='col-2'>
                    {row.email}
                  </td>
                  <td className='col-2 text-center'>
                    {row.celular}
                  </td>
                  <td className='col-2'>
                    {row.direccion}
                  </td>
                  <td className='col-2'>
                    {row.cliente_string}
                  </td>
                  <td className="text-end">
                    {row.TotalConAbonos}
                  </td>
                  <td className="text-center border-start">
                    <NavLink to={"/Ventas/Cartera/Detalle/" + row.token} className="btn btn-warning btn-sm">Cartera detallada</NavLink>
                  </td>
                  {/* <td className="text-center border-start">
                            <div  className={row.pagar===true?"btn btn-primary btn-sm me-2":"btn btn-secondary btn-sm me-2"} onClick={()=>selection(row,key)}                                        >
                              <FontAwesomeIcon icon={faCheck}/>
                            </div>
                            <NavLink to={"/Ventas/Cartera/Detalle/"+row.token} className="btn btn-warning btn-sm">Cartera detallada</NavLink>
                          </td> */}
                </tr>

              })}


            </tbody>
            <tfoot>
              <tr className='p-10 text-end bg-primary'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td ></td>
                <td style={{ color: 'white' }}><b>Totalizado:</b></td>
                <td style={{ color: 'white' }}><b>{format(sumatotal)}</b></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {/* {console.log(deudaFinal)}         */}
    </div>

  )
}
export default InventarioCartera
