import React,{useState,useEffect} from 'react';
import InputSimple from '../../components/InputSimple';
import StateContext from '../../helpers/ContextState';
import {Inputs,PostAsync} from '../../helpers/Functions';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import BotonVolverHome from '../../components/BotonVolverHome';

const AppConf = () => {
  const context             =   React.useContext(StateContext);
  const [inputs, setInputs] =   useState({semanas_alerta_proveedor: ""});

  const onSubmit=(e)=>{
    e.preventDefault()

    PostAsync("Configuracion","SetAppConfiguration",{...inputs},{...context}).then((response) => {
     
    })
  }

  useEffect(() => {
    let mounted = true
    getInit(mounted)
    return function cleanup() {
      mounted = false
    }
  },[])

  const getInit=(mounted,uid)=>{
    PostAsync("Configuracion","GetAppConfiguration",{},{...context}).then((response) => {
      console.log(response.data && mounted)
      if (response.data && mounted) {
        setInputs(response.data)
      }
    })  
  }

  const onChange=(e)=>{
    Inputs(e,inputs, setInputs)
  }
   
    return <>
            <form onSubmit={onSubmit}>
                <Row className="mb-4">
                    <Col xs="12" md="2">
                    <h1>Alertas</h1>
                    <InputSimple 
                            className="form-control"
                            type="number"
                            min="1"
                            name="semanas_alerta_proveedor"
                            defaultValue={inputs.semanas_alerta_proveedor}
                            placeholder="Cantidad de semanas para alertas de proveedor"
                            onChange={onChange}
                    />
                    </Col>
                    <Col xs="12" md="2">
                      <Button type="submit">Actualizar</Button>
                      <BotonVolverHome/>
                    </Col>
                </Row>
            </form>
        </>
}

export default AppConf
