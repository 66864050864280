import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import Constants from '../helpers/Constants'
import {LinkContainer} from 'react-router-bootstrap'
import StateContext from '../helpers/ContextState';
import CambiarContrasena from '../components/CambiarContrasena'
import logo from '../assets/images/favicon.png'
import logo_letra from '../assets/images/letra_asocolcarnes.png'


const App = (props)  =>{
  const context     =   React.useContext(StateContext);
  const cambiarContrasena=()=>{
    context.setModalShow({
                            show:true,
                            message: <CambiarContrasena context={context}/>,
                            size:""
                          }
                        )
  }
  return    <Container fluid>
              <Row>
                <Navbar bg="primary" variant="dark" expand="lg">
                  <img src={logo} alt=""/>
                  <img src={logo_letra} alt=""/>
                  <Navbar.Brand href="#home">
                    {Constants.Title}
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                      {
                        Constants.Modulos.map((row, key)=>{
                          /*AQUÍ LOS ITEMS SIMPLES DEL MEN+U*/
                          if (  row.items===undefined &&
                                row.public &&
                                (row.OutSession===undefined ||
                                  (
                                    row.OutSession!==undefined &&
                                    row.OutSession===true &&
                                    props.inSesssion===false
                                  )
                                )
                              ) {

                              if ((props.privilegios[row.url]!==undefined && parseInt(props.user.tipo_usuario_id)>2) || parseInt(props.user.tipo_usuario_id)<=2) {
                              //if ((props.privilegios[row.url]!==undefined && parseInt(props.user.tipo_usuario_id)>2)) {
                                return  <LinkContainer  to={row.url} key={key}>
                                          <Nav.Link>{row.label}</Nav.Link>
                                        </LinkContainer>
                              }else {
                                return false
                              }

                          }else if (row.items!==undefined) {
                            if (props.privilegios[row.url]!==undefined && parseInt(props.user.tipo_usuario_id)>2 || parseInt(props.user.tipo_usuario_id)<=2) {
                            //if (props.privilegios[row.url]!==undefined && parseInt(props.user.tipo_usuario_id)>2) {
                              return  <NavDropdown title={row.label} id="basic-nav-dropdown" key={key}>
                                        {row.items.map((row2,key2)=>{
                                          if (row2.public &&
                                              (row2.OutSession===undefined ||
                                                (
                                                  row2.OutSession!==undefined &&
                                                  row2.OutSession===true &&
                                                  props.inSesssion===false
                                                )
                                              )) {
                                            if (props.privilegios[row2.url]!==undefined && parseInt(props.user.tipo_usuario_id)>2 || parseInt(props.user.tipo_usuario_id)<=2) {
                                            //if (props.privilegios[row2.url]!==undefined && parseInt(props.user.tipo_usuario_id)>2) {
                                              return  <LinkContainer  to={row2.url}
                                                                      key={key2}>
                                                        <NavDropdown.Item>
                                                          {row2.label}
                                                        </NavDropdown.Item>
                                                      </LinkContainer>
                                            } else {
                                              return false
                                            }
                                          }else {
                                            return false
                                          }
                                        })}
                                      </NavDropdown>
                            }else {
                              return false
                            }
                          }else {
                            return false
                          }
                        })
                      }

                    </Nav>
                    {console.log(props.user)}
                    <Form className="me-5">
                      <Nav className="me-5">
                        <NavDropdown title={props.user.nombres} id="basic-nav-dropdown">
                          <div>
                            <NavDropdown.Item onClick={()=>cambiarContrasena()} >Cambiar Contraseña</NavDropdown.Item >
                            <NavDropdown.Divider />
                            <NavDropdown.Item > <LinkContainer to={"/"} style={{color: 'black'}}>
                            <Nav.Link  onClick={()=>props.out()}>Salir</Nav.Link>
                            </LinkContainer></NavDropdown.Item>
                          </div>
                        </NavDropdown>
                      </Nav>
                    </Form>
                  </Navbar.Collapse>
                </Navbar>
              </Row>
            </Container>
}

export default App
