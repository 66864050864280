import React,{useState} from 'react';
//import React from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{

  //const [filter, setFilter] = useState({estatus:2});

  let tds       =   [
    {
      label:"Tipo",
      value:"label",
      className:"col-12 col-sm-10 text-start",
      classNameHeader:"col-3 text-start",
    },
    {
      label:"Acciones",
      value:"events",
      className:"col-12 col-sm-2 text-end",
      classNameHeader:"col-2 text-end",
      icons:[
              {
                label:"Editar",
                NavLink:NavLink,
                icon: <FontAwesomeIcon icon={faEdit}/>,
                url:"/Usuarios/TiposDeUsuarios/Edit/"
              },
            ]
    },
  ]
  return  <div className="container" >
            <div className="row">
              <div className="col">
                <Table
                  td={tds}
                  modelo="Maestros"
                  metodo="get_maestra"
                  extra="ma_tipo_usuarios"
                  limit="5"
                  params={{estatus:2}}
                  style={{marginTop: '55px'}}
                />
              </div>
            </div>
          </div>
}
export default App
