import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import StateContext from '../../helpers/ContextState';
import Config from '../../helpers/Config';



import { faEye,faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{
  const context                           =   React.useContext(StateContext);
  const [param, setParam]                 =   useState({tipo_usuario_id:2});

  let tds       =   [
    {
      label:"Consecutivo",
      value:"consecutive",
      className:"col-sm-1 text-left",
      classNameHeader:"text-left",
    },
    {
      label:"Fecha Compra",
      value:"fecha_recibido",
      className:"col-sm-1 text-left",
      classNameHeader:"text-left",
    },
    {
      label:"Hora",
      value:"hora",
      className:"col-sm-1 text-left",
      classNameHeader:"text-left",
    },
    {
      label:"Semana",
      value:"semana",
      className:"col-sm-1 text-left",
      classNameHeader:"text-left",
    },
    // {
    //   label:"Proveedor",
    //   value:"proveedor",
    //   className:" text-left",
    //   classNameHeader:"col text-left",
    // },
    // {
    //   label:"Total Items",
    //   value:"total_items",
    //   className:"text-center col-1  text-left",
    //   classNameHeader:"text-center",
    // },
    {
      label:"Marca",
      value:"items_de_compras",
      className:"col-4  text-left",
      classNameHeader:"text-left",
    },
    {
      label:"Productos",
      value:"items_de_productos",
      className:"col-2  text-left",
      classNameHeader:"text-left",
    },

    {
      label:"Estado",
      value:"estatus",
      className:"text-center col-1  text-left",
      classNameHeader:"text-center",
    }, 
    {
      label:"Acciones",
      value:"events",
      className:"col-12 col-sm-1 text-center",
      classNameHeader:"col-1 text-center",
      icons:[
              {
                label:"Resumen",
                NavLink:NavLink,
                icon: <FontAwesomeIcon icon={faEye}/>,
                url:"/Papelera/ComprasEliminadas/Edit/"
              },
              // {
              //   label: "Delete",
              //   NavLink:NavLink,
              //   icon: <FontAwesomeIcon icon={faTrash} />,
              // }
            ]
    },
  ]
  return  <div className="container-fluid">
            <div className="row">
              <div className="col">
                <Table
                  customFilters={true}
                  toAdd="Compras/NuevaCompra"
                  add={NavLink}
                  downloadExcel={Config.ConfigApirest+"images/template_upload_compras.xlsx"}
                  uploadExcel={true}
                  td={tds}
                  modelo="Inventario"
                  metodo="ListadoCompras"
                  papelera={true}
                  limit="5"
                  params={{...param,tipo_transaccion:"compra"}}
                />
              </div>
            </div>
          </div>
}
export default App
