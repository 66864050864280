const App=({message,event1,event2})=>{
  return    <div className="contentAlert row justify-content-center">
              <div className="col-12 mb-3">
                {message}
              </div>
            <div className="col-4">
              <div className="btn btn-primary col-12" onClick={event1}>
                Si
              </div>
            </div>
            <div className="col-4">
              <div className="btn btn-secondary col-12" onClick={event2}>
                No
              </div>
            </div>
          </div>
}


export default App
