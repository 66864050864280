//import React,{useState} from 'react';
import React from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import { faEdit,faStore,faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const filter={estatus:2}

const App=(props)=>{

  // const [filter, setFilter] = useState({estatus:2});

  let tds       =   [
    {
      label:"Tipo",
      value:"label",
      className:"text-center"
    },
    {
      label:"Entrada",
      value:"entrada",
      className:"text-center ",
      classNameHeader:" text-center col-2 ",
    },
    {
      label:"Salida",
      value:"salida",
      className:"text-center ",
      classNameHeader:" text-center col-2 ",
    },
    {
      label:"Disponible",
      value:"restante",
      className:"text-center ",
      classNameHeader:" text-center col-2 ",
    },
    {
      label:"Acciones",
      value:"events",
      icons:[
              {
                label:"Editar",
                NavLink:NavLink,
                icon: <FontAwesomeIcon icon={faEdit}/>,
                url:"/Inventario/Productos/Edit/"
              },
              {
                label:"Inventario",
                NavLink:NavLink,
                icon: <FontAwesomeIcon icon={faStore}/>,
                url:"/Inventario/Productos/Gestionar/"
              },
              {
                label:"Inventario",
                icon: <FontAwesomeIcon icon={faTrash}/>,
                event:"/Inventario/Productos/Eliminar/"
              },
            ]
    },
  ]
  return  <div className="container">
            
          </div>
}
export default App
