import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import StateContext from '../../helpers/ContextState';


import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{
  const context                           =   React.useContext(StateContext);
  const [param, setParam]                 =   useState({tipo_usuario_id:2,tipo_transaccion:"venta", modulo: 'ListadoVentas'});

  let tds       =   [
    {
      label:"Consecutivo",
      value:"consecutive",
      className:"col-sm-1 text-left ",
      classNameHeader:"text-left",
    },
    {
      label:"Fecha de venta",
      value:"fecha_string",
      className:"col-sm-1 text-left",
      classNameHeader:"text-left",
    },
    // {
    //   label:"Fecha del registro",
    //   value:"fecha_registro",
    //   className:"col-sm-1 text-left",
    //   classNameHeader:"text-left",
    // },   
    {
      label:"Semana",
      value:"semana",
      className:"col-sm-1 text-left",
      classNameHeader:"text-left",
    },
    {
      label:"Hora",
      value:"hora",
      className:"col-sm-1 text-left",
      classNameHeader:"text-left",
    },
    {
      label:"Cliente",
      value:"cliente_string",
      className:" text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"NIT",
      value:"nro_documento",
      className:" text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Producto",
      value:"producto",
      className:" text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Estado",
      value:"estatus",
      className:"text-center col-2  text-left",
      classNameHeader:"text-center",
    },
    {
      label:"Acciones",
      ventas: true,
      value:"events",
      className:"col-12 col-sm-1 text-center",
      classNameHeader:"col-1 text-center",
      icons:[
              {
                label:"Resumen",
                NavLink:NavLink,
                icon: <FontAwesomeIcon icon={faEye}/>,
                url:"/Inventario/Ventas/Edit/"
              },
              {
                label: "Delete",
                NavLink:NavLink,
                icon: <FontAwesomeIcon icon={faTrash} />,
              }
            ]
    },
  ]
  return  <div className="container-fluid">
            <div className="row">
              <div className="col">
                {console.log("param", param)}
                <Table
                  customFilters2={true}
                  toAdd="NuevaVenta"
                  add={NavLink}
                  td={tds}
                  modelo="Inventario"
                  metodo="ListadoVentas"
                  limit="5"
                  params={param}  
                />
              </div>
            </div>
          </div>
}
export default App
