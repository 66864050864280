import React, { useState, useEffect } from 'react';
import InputSimple from '../../components/InputSimple';
import { Inputs, PostAsync } from '../../helpers/Functions';
import StateContext from '../../helpers/ContextState';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Comentarios from '../comentarios';
import BotonVolverHome from '../../components/BotonVolverHome';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const App = () => {
  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({});
  const [inputsVentas, setInputsVentas] = useState({});
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (reset) {
      setReset(false)
    }
  }, [reset])

  useEffect(() => {
    let mounted = true
    getInit(mounted)
    getInit2(mounted)
    return function cleanup() {
      mounted = false
    }
  }, [])

  const getInit = (mounted, uid) => {
    PostAsync("Configuracion", "GetSystem", {}, { ...context }).then((response) => {
      if (response.data !== undefined && mounted) {
        setInputs(response.data)
      }
    })
  }

  const getInit2 = (mounted, uid) => {
    PostAsync("Configuracion", "GetSystemVentas", {}, { ...context }).then((response) => {
      if (response.data !== undefined && mounted) {
        setInputsVentas(response.data)
        console.log("GetSystemVentas",response.data);
      }
    })
  }

  const onChange = (e) => {
    Inputs(e, inputs, setInputs)
  }
  const onChange2 = (e) => {
    Inputs(e, inputsVentas, setInputsVentas)
  }
  const onSubmit = (e) => {
    e.preventDefault()
    PostAsync("Configuracion", "SetSystem", { ...inputs, tipo: "compras" }, { ...context }).then((response) => {
      setReset(true)
      getInit(true)
    })
  }
  const onSubmit_venta = (e) => {
    e.preventDefault()
    PostAsync("Configuracion", "SetSystem_venta", { ...inputsVentas, tipo: "ventas"}, { ...context }).then((response) => {
      setReset(true)
      getInit2(true)
    })
  }

  return (
    <>
      <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="profile" title="Compras">
            <Row className="mb-4">
              <form onSubmit={onSubmit}>
                <Row className="mb-4">
                  <Col xs="12" md="2">
                    <InputSimple className="form-control"
                      type="text"
                      name="iva"
                      defaultValue={inputs.iva}
                      placeholder="IVA"
                      onChange={onChange}
                    />
                  </Col>
                  <Col xs="12" md="2">
                    <InputSimple className="form-control"
                      type="text"
                      name="reteiva"
                      defaultValue={inputs.reteiva}
                      placeholder="Rete IVA"
                      onChange={onChange}
                    />
                    <br />
                    <InputSimple className="form-control"
                      type="number"
                      name="base"
                      defaultValue={inputs.base}
                      placeholder="Base Rete IVA"
                      onChange={onChange}
                    />
                  </Col>
                  <Col xs="12" md="2">
                    <InputSimple className="form-control"
                      type="text"
                      name="reteica"
                      defaultValue={inputs.reteica}
                      placeholder="Rete ICA"
                      onChange={onChange}
                    />
                    <br />
                    <InputSimple className="form-control"
                      type="number"
                      name="base_reteica"
                      defaultValue={inputs.base_reteica}
                      placeholder="Base Rete ICA"
                      onChange={onChange}
                    />
                  </Col>
                  <Col xs="12" md="2">
                    <InputSimple className="form-control"
                      type="text"
                      name="retefuente"
                      defaultValue={inputs.retefuente}
                      placeholder="Rete Fuente"
                      onChange={onChange}
                    />
                    <br />
                    <InputSimple className="form-control"
                      type="number"
                      name="base_retefuente"
                      defaultValue={inputs.base_retefuente}
                      placeholder="Base Rete Fuente"
                      onChange={onChange}
                    />
                  </Col>
                  <Col xs="12" md="2">
                    <BotonVolverHome />
                  </Col>
                  <Col xs="12" md="2">
                    <Button type="submit">Actualizar</Button>
                  </Col>
                </Row>
              </form>
            </Row>
          <Row>
            <Col xs="12">
              {!reset ? <Comentarios id={"Compras"} /> : false}
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="profile2" title="Ventas">
            <Row className="mb-4">
              <form onSubmit={onSubmit_venta}>
                <Row className="mb-4">
                  <Col xs="12" md="2">
                    <InputSimple className="form-control"
                      type="text"
                      name="iva"
                      defaultValue={inputsVentas.iva_venta}
                      placeholder="IVA"
                      onChange={onChange2}
                    />
                  </Col>
                  <Col xs="12" md="2">
                    <InputSimple className="form-control"
                      type="text"
                      name="reteiva"
                      defaultValue={inputsVentas.reteiva_venta}
                      placeholder="Rete IVA"
                      onChange={onChange2}
                    />
                    <br />
                    <InputSimple className="form-control"
                      type="text"
                      name="base"
                      defaultValue={inputsVentas.base_iva_venta}
                      placeholder="Base Rete IVA"
                      onChange={onChange2}
                    />
                  </Col>
                  <Col xs="12" md="2">
                    <InputSimple className="form-control"
                      type="text"
                      name="reteica"
                      defaultValue={inputsVentas.reteica_venta}
                      placeholder="Rete ICA"
                      onChange={onChange2}
                    />
                    <br />
                    <InputSimple className="form-control"
                      type="text"
                      name="base_reteica"
                      defaultValue={inputsVentas.base_reteica_venta}
                      placeholder="Base Rete ICA"
                      onChange={onChange2}
                    />
                  </Col>
                  <Col xs="12" md="2">
                    <InputSimple className="form-control"
                      type="text"
                      name="retefuente"
                      defaultValue={inputsVentas.retefuente_venta}
                      placeholder="Rete Fuente"
                      onChange={onChange2}
                    />
                    <br />
                    <InputSimple className="form-control"
                      type="text"
                      name="base_retefuente"
                      defaultValue={inputsVentas.base_retefuente_venta}
                      placeholder="Base Rete Fuente"
                      onChange={onChange2}
                    />
                  </Col>
                  <Col xs="12" md="2">
                    <BotonVolverHome />
                  </Col>
                  <Col xs="12" md="2">
                    <Button type="submit">Actualizar</Button>
                  </Col>
                </Row>
              </form>
            </Row>
          <Row>
            <Col xs="12">
              {!reset ? <Comentarios id={"Ventas"} /> : false}
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </>
  )

}
export default App

