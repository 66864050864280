import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import StateContext from '../../helpers/ContextState';
import Config from '../../helpers/Config';


import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{
  const context                           =   React.useContext(StateContext);
  const [param, setParam]                 =   useState({tipo_usuario_id:2,tipo_transaccion:"venta"});

  let tds       =   [
    {
      label:"Consecutivo",
      value:"consecutive",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
    },
    {
      label:"Fecha Venta",
      value:"fecha_string",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
    },
    {
      label:"Semana",
      value:"semana",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
    },
    {
      label:"Día",
      value:"dia",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
    },
    {
      label:"Cliente",
      value:"cliente",
      className:" text-left",
      classNameHeader:"col text-center",
    },
    {
      label:"Producto",
      value:"producto",
      className:" text-left",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Cantidad",
      value:"cantidad_string",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Peso",
      value:"peso_string",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"P. Unitario",
      value:"precio_unit_string",
      className:" text-end",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Subtotal",
      value:"precio_string",
      className:" text-end",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"IVA",
      value:"iva",
      className:" text-end",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"ReteIVA",
      value:"reteIVA",
      className:" text-end",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"ReteICA",
      value:"reteICA_string",
      className:" text-end",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"ReteF",
      value:"rete_string",
      className:" text-end",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"P. Total",
      value:"total_string",
      className:" text-end",
      classNameHeader:"col text-center",
    }
    // {
    //   label:"Estado",
    //   value:"estatus",
    //   className:"text-center col-1  text-left",
    //   classNameHeader:"text-center",
    // },
  ]

  if(context.user.tipo_usuario_id != 4){
    tds.splice(3 , 0,
       {
      label:"Marca",
      value:"marca",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
    });
  }

  return  <div className="container-fluid">
            <div className="row">
              <div className="col">
                <Table
                  customFiltersI2={true}
                  total={true}
                  toExport={Config.ConfigApirest+'Excel/Informes/ListadoVentasExcel/token:'+context.user.token}
                  toPDF={Config.ConfigApirest+'PDF/Informes/ListadoVentas/'+context.user.token}
                  td={tds}
                  modelo="Informes"
                  metodo="ListadoVentas"
                  limit="10"
                  params={param}
                />
              </div>
            </div>
          </div>
}
export default App
