import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"

import FormControl from 'react-bootstrap/FormControl'

import SelectorGenerico from '../../components/SelectorGenerico';
import StateContext from '../../helpers/ContextState';
import { PostAsync, format, Inputs } from '../../helpers/Functions';
import { useParams } from "react-router-dom";


import { faCheck, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from '../../helpers/Config';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import InputGroup from 'react-bootstrap/InputGroup';

import Input from '../../components/InputSimple';
import Select from '../../components/Select';

import NumberFormat from 'react-number-format';

const App = (props) => {
  const context = React.useContext(StateContext);
  const params = useParams();
  const inputRef = useRef(null);
  const [data, setData] = useState([]);
  const [btn, setBtn] = useState(false);
  const [btnPagar, setBtnPagar] = useState([]);//////
  const [proveedor, setProveedor] = useState({});
  const [totalLineas, setTotalLineas] = useState([]);//////
  const [totalLineasPeso, setTotalLineasPeso] = useState([]);//////
  const [totalLineasTotal, setTotalLineasTotal] = useState([]);//////
  const [totalGeneral, setTotalGeneral] = useState({ peso: 0, cantidad: 0, total: 0 });
  const [inputs, setInputs] = useState({});
  const [semanas, setSemanas] = useState([]);
  const [tipoUsuario, setUsuarioTipo] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [cliente, setCliente] = useState("Sin cliente");
  const [abonos, setAbonos] = useState([]);//////

  const [selectAll, setSelectAll] = useState(true);
  const [totalMenosAbonos, setTotalMenosAbonos] = useState(0);//////

  const [totalGeneralFooter, setTotalGeneralFooter] = useState(0);//////
  const [observacion, setObservacion] = useState("");
  const [formValidated, setFormValidated] = useState(false);

  let filter = "";
  useEffect(() => {
    basicInputs()
  }, [])

  const basicInputs = () => {
    PostAsync("Inventario", "cartera_detalle", { token: params.id }, context).then((response) => {
      // console.log(response.tipo_usuario)
      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas)
      }

      if (response.data && response.data.marcas) {
        setMarcas(response.data.marcas)
      }

      if (response.data && response.data.productos) {
        setProductos(response.data.productos)
      }

    })
  }


  useEffect(() => {
    !showModal && getInit()
  }, [inputs])

  useEffect(() => {
    if (params.id) {
      getInit()

    }
  }, [])


  const getInit = () => {
    setData([])

    PostAsync("Inventario", "cartera_detalle", { id: params.id }, context).then((response) => {
      setUsuarioTipo(response.tipo_usuario);
      response.cliente && setCliente(response.cliente);
      response.abonos && setAbonos(response.abonos);
      response.total_menos_abonos && setTotalMenosAbonos(response.total_menos_abonos);

      if (response.data && Object.entries(response.data).length > 0) {
        //data_.push({label:row[0],items:row[1],uid:row[1].token,key:key,pagar:true})

        let data_ = []
        let totalGeneral_ = {}
        let totalLineas_ = 0
        let totalLineas__ = []

        let totalLineasPeso_ = 0
        let totalLineasPeso__ = []

        let totalLineasTotal_ = 0
        let totalLineasTotal__ = []

        let btnPagar_ = []
        let btnPagar__ = []
        let activeOnBtn__ = false

        let totalGeneralFooter = 0

        Object.entries(response.data).map((row, key) => {
          data_.push({ label: row[0], items: row[1] })

          totalLineas_ = 0
          totalLineasPeso_ = 0
          totalLineasTotal_ = 0
          btnPagar_ = []

          row[1].map((row2, key2) => {
            totalLineas_ += parseFloat(row2.cantidad)
            totalLineasPeso_ += parseFloat(row2.peso)
            totalLineasTotal_ += parseFloat(row2.total)
            btnPagar_.push({ ...row2, uid: row2.token, key: key2, pagar: true })
            activeOnBtn__ = true
            totalGeneralFooter += parseFloat(row2.total)
          })

          setTotalGeneralFooter(totalGeneralFooter)
          totalLineas__.push(totalLineas_)
          totalLineasPeso__.push(totalLineasPeso_)
          totalLineasTotal__.push(totalLineasTotal_)
          btnPagar__.push(btnPagar_)

        })

        setBtn(activeOnBtn__)
        setBtnPagar(btnPagar__);
        setTotalLineas(totalLineas__);
        setTotalLineasPeso(totalLineasPeso__);
        setTotalLineasTotal(totalLineasTotal__);
        setData(data_)

      }
    })
  }

  const selection = (row, key2, key) => {
    if (btnPagar[key] && btnPagar[key][key2]) {
      let btnPagar_ = [...btnPagar]

      btnPagar_[key][key2].pagar = (btnPagar_[key][key2].pagar) ? false : true

      setBtnPagar(btnPagar_)

      let totalGeneralFooter = 0
      let allCheck = true
      btnPagar_.map((row, key) => {
        row.map((row2, key2) => {
          if (row2.pagar === true) {
            totalGeneralFooter += parseFloat(row2.total_restante)
          } else {
            allCheck = false
          }
          return row2
        })
        return row
      })
      setSelectAll(allCheck)
      setTotalGeneralFooter(totalGeneralFooter)

      let data_ = [...data]
      if (data_[key] && data_[key].items && data_[key].items[key2]) {
        //console.log(data_[key].items[key2].pagar);
        data_[key].items[key2].pagar = (data_[key].items[key2].pagar === undefined || data_[key].items[key2].pagar === false) ? true : false
        //console.log(data_[key].items[key2].pagar);
        setData(data_)
      }
      verifyStatus(btnPagar_)
    }
  }


  const [showModal, setShowModal] = useState(false);

  const handleCloseCross = () => {
    setShowModal(false)
  };

  const handleClose = () => {
    if (inputs.installment_date && inputs.installment_value) {
      getInit()
      setShowModal(false)
    }
  };

  const handleShow = () => {
    checkActive() && setShowModal(true);
  }

  const checkActive = () => {
    const boolean = data.find(search => search.items.find(search2 => search2.pagar === true))
    return (boolean !== undefined) ? true : false
  }

  const verifyStatus = (data) => {
    const result = data.find(search => search.find(search2 => search2.pagar === true))
    if (result) {
      setBtn(true)
    } else {
      setBtn(false)
    }
  }

  const onChangeCustom = (e) => {
    Inputs(e, inputs, setInputs)
  }


  const handleSubmitPago = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    const { id } = params;
    PostAsync("Pagos", "set_abono_cartera", { id: id, ...inputs, hechoPor: context.user.nombres+" "+context.user.apellidos }, context)
      .then(
        (response) => {
          console.log(response);
        }
      );

    // setFormValidated(true);
    // form.checkValidity() && handleClose();
  };


  const handleCheck = () => {
    let data_ = []
    let seleccion = selectAll
    let btnPagar_ = [...btnPagar]
    let totalGeneralFooter = 0
    btnPagar.map((row, key) => {
      row.map((row2, key2) => {
        if (data[key].items && data[key].items[key2] && data[key].items[key2].pagar) {
          data[key].items[key2].pagar = seleccion ? false : true
        } else {
          data[key].items[key2].pagar = seleccion ? false : true
        }
        totalGeneralFooter += !seleccion ? parseFloat(data[key].items[key2].total_restante) : 0
        row[key2].pagar = seleccion ? false : true
        return btnPagar_[key][key2].pagar = seleccion ? false : true
      })
      data_.push(row);
      return row;
    })

    setTotalGeneralFooter(totalGeneralFooter)
    setBtnPagar(btnPagar_)
    setSelectAll((seleccion) ? false : true)
  }
  console.log(inputs);

  return <>
    <Modal show={showModal} onHide={handleCloseCross}>
      <Modal.Header closeButton>
        <Modal.Title>Abono a cartera</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form method="post" noValidate validated={formValidated} onSubmit={handleSubmitPago}>
          {
            totalMenosAbonos < 0 ?
              <h5 className='text-center' style={{ color: 'red' }}><b>Total a pagar {format(totalMenosAbonos)}</b></h5>
              :
              <h5 className='text-center' ><b>Total a pagar {format(totalMenosAbonos)}</b></h5>

          }
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
            hasValidation
          >
            <Form.Label>Fecha del abono</Form.Label>
            <Form.Control name="installment_date" type="date" onChange={onChangeCustom} required />
            <Form.Control.Feedback type="invalid">
              Ingrese un valor válido.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
            hasValidation
          >
            <Form.Label>Valor del abono</Form.Label>
            <NumberFormat
              name="installment_value"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              placeholder="Valor del abono"
              onChange={onChangeCustom}
              min={1}
              required
              customInput={Form.Control}
              allowLeadingZeros={false}
              allowNegative={false}
              isNumericString={true}
              prefix=""
            />
            <Form.Control.Feedback type="invalid">
              Ingrese un valor válido.
            </Form.Control.Feedback>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Observacion</Form.Label>
              <Form.Control as="textarea" rows={1} onChange={onChangeCustom} required name="installment_obser"/>
            </Form.Group>
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCross}>
              Cerrar
            </Button>
            <Button variant="primary" type="submit" onClick={handleClose}>
              Abonar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
    <div className="container-fluid" style={{ marginTop: '65px' }}>
      <div className="row justify-content-center mb-3">
        <div className="row mb-3 pt-1">
          <a className="btn btn-primary col-1 m-1 " href={Config.ConfigApirest +
            "PDF/Inventario/cartera_detalle/any?pdfBool=true&id=" +
            params.id} target="_blank">PDF</a>
        </div>
        <div className="col-12 h3" >
          <b>Cartera detallada - {cliente}</b>
        </div>
      </div>
      {/* <div className="row bg-gray">
          <div className="col-12 ">
            <b>Filtros de búsqueda</b>
          </div>
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <Input
                title="Fecha desde"
                placeholder="Fecha desde"
                type="date"
                name="fecha_inicio"
                className="form-control"
                onChange={onChangeCustom}
                required={true}
            />
          </div>
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <Input
                title="Fecha hasta"
                placeholder="Fecha hasta"
                type="date"
                name="fecha_fin"
                className="form-control"
                onChange={onChangeCustom}
                required={true}
            />
          </div>
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
            <Select
                title="Semana"
                selectDefault="Semana"
                name="semana"
                data={semanas?semanas:[]}
                className="form-control"
                onChange={onChangeCustom}
                required={true}
            />
          </div>
          <div className="col-12 col-sm-3 mb-3 pt-1 text-left">
            <Select
                title="Marca"
                selectDefault="marca"
                name="marca_id"
                data={marcas}
                className="form-control"
                onChange={onChangeCustom}
                required={true}
            />
          </div>             
          a
          <div className="col-12 col-sm-3 mb-3 pt-1 text-left">
            <Select
                title="Producto"
                selectDefault="Producto"
                name="producto_id"
                data={productos}
                className="form-control"
                onChange={onChangeCustom}
                required={true}
            />
          </div>
        </div> */}
      <div className="row">
        <div className="col">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="col">
                  Producto
                </th>
                <th className="col">
                  Fecha de compra
                </th>
                <th className="col">
                  Semana
                </th>
                <th className="col">
                  Cantidad
                </th>
                <th className="col ">
                  Peso
                </th>
                <th className="col text-center">
                  Precio Medida
                </th>
                <th className="col text-end">
                  Precio Total&nbsp; &nbsp;
                  {tipoUsuario == 2 || tipoUsuario == 6 ? <div className={btn ? "btn btn-primary btn-sm" : "btn btn-secondary btn-sm"} onClick={handleShow}>
                    <FontAwesomeIcon icon={faDollarSign} /> Abonar
                  </div> : ""}
                </th>
                {/* <th className="text-center col-2">
                    <div className="row">
                      <div className="col-11 text-center">
                        <div className={selectAll?"btn btn-primary btn-sm me-2":"btn btn-secondary btn-sm me-2"} onClick={handleCheck}>
                          <FontAwesomeIcon icon={faCheck}/>
                        </div>
                        <div className={btn?"btn btn-primary btn-sm":"btn btn-secondary btn-sm"} onClick={handleShow}>
                          <FontAwesomeIcon icon={faDollarSign}/> Abonar
                        </div>
                      </div>
                    </div>
                  </th> */}
              </tr>
            </thead>
            <tbody>
              {data.map((row, key) => {
                return <tr key={key}>
                  <td>
                    {row.label}
                  </td>
                  <td className="">
                    {row.items.map((row2, key2) => {
                      return <div className="mb-4" key={key2}>
                        {row2.fecha}
                      </div>
                    })}
                  </td>
                  <td className="">
                    {row.items.map((row2, key2) => {
                      return <div className="mb-4" key={key2}>
                        {row2.semana}
                      </div>
                    })}
                  </td>
                  <td className="text-end">
                    <div className="bg-secondary">
                      {row.items.map((row2, key2) => {
                        return <div className="mb-4 text-white pe-2" key={key2}>
                          {row2.cantidad}
                        </div>
                      })}
                      <div className="bg-secondary text-white  p-2"><b>
                        {totalLineas[key]}
                      </b></div>
                    </div>
                  </td>
                  <td className="text-end m-0">
                    <div className="bg-secondary">
                      {row.items.map((row2, key2) => {
                        return <div className="mb-4 text-white pe-2" key={key2}>
                          {row2.peso}
                        </div>
                      })}
                      <div className="bg-secondary text-white  p-2"><b>
                        {format(totalLineasPeso[key])}
                      </b></div>
                    </div>
                  </td>
                  <td>
                    <div className="text-center">
                      {row.items.map((row2, key2) => {
                        return <div className="mb-4 pe-2" key={key2}>
                          <div>
                            {format(row2.precio_individual)}
                          </div>
                        </div>
                      })}
                    </div>
                  </td>
                  <td className="text-end  text-white">
                    <div className="bg-secondary">
                      {row.items.map((row2, key2) => {
                        return <div className="mb-4 text-white pe-10 " key={key2}>
                          <div className={btnPagar[key] &&
                            btnPagar[key][key2] !== undefined &&
                            (btnPagar[key][key2].pagar !== undefined && btnPagar[key][key2].pagar === true) ? "btn-primary" : ""}>
                            {row2.total_string}
                          </div>
                        </div>
                      })}
                      <div className="bg-secondary text-white  p-2"><b>
                        {format(totalLineasTotal[key])}
                      </b></div>
                    </div>
                  </td>
                  {/* <td className="text-center border-start">
                              {btnPagar[key].map((row2,key2)=>{
                                return  <div key={key2} className="mb-3">
                                          <div
                                              className={row2.pagar===true?"btn btn-primary btn-sm me-2":"btn btn-secondary btn-sm me-2"}
                                              onClick={()=>selection(row2,key2,key)}>
                                              <FontAwesomeIcon icon={faCheck}/>
                                          </div>
                                        </div>
                              })}


                            </td> */}
                </tr>
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="bg-primary text-white text-end h5">{format(totalGeneralFooter)}</td>
              </tr>
            </tbody>
          </table>

          {abonos.length > 0 &&
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Fecha Abono</th>
                  <th>Auxuliar</th>
                  <th>Observación abono</th>
                  <th className='text-end'>Valor Abono</th>
                </tr>
              </thead>
              <tbody>

                {
                  abonos.map((row, key) => {
                    return (
                      <tr>
                        <td>{row.fecha_abono}</td>
                        <td>{row.auxiliar}</td>
                        <td>{row.observacion}</td>
                        <td className='text-end'>{format(row.valor_abono)}</td>
                      </tr>
                    )
                  })

                }
                <tr>
                  <td><strong>Total cuenta por cobrar:</strong></td>
                  <td><strong></strong></td>
                  <td><strong></strong></td>
                  <td className="bg-primary text-white text-end h5">{format(totalMenosAbonos)}</td>
                  {/* <td  className="bg-primary text-white text-end h5">{format(totalGeneralFooter)}</td> */}
                </tr>
              </tbody>
            </table>}
        </div>
      </div>
    </div>
  </>
}
export default App
