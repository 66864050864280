import React, { useState, useEffect } from "react";
import { PostAsync, Segment, Inputs } from "../../helpers/Functions";
import { useParams, useHistory } from "react-router-dom";
import Input from "../../components/Inputs";
import Select from "../../components/Select";
import StateContext from "../../helpers/ContextState";
import { NavLink } from "react-router-dom";
import Table from "react-bootstrap/Table";
import SelectorGenerico from "../../components/SelectorGenerico";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SelectorMultiple from "../../components/SelectorMultiple";

let proveedores = [];

const App = () => {
  const params = useParams();
  let history = useHistory();
  let url =
    params.id !== undefined
      ? { modulo: Segment(4), submodulo: Segment(3) }
      : { modulo: Segment(3), submodulo: Segment(2) };
  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({
    ma_unidad_medida_id: 1,
    fecha_cambio: "",
    marca: "",
    proveedor_id: "",
    precio_asociado: "",
    precio_no_asociado: "",
    iva: "",
    observacion: "",
  });
  const [inputs2, setInputs2] = useState({});
  const [historial, setHistorial] = useState([]);
  const [param, setParam] = useState({ ma_unidad_medida_id: 1 });
  const [productos, setProductos] = useState([]);
  const [data, setData] = useState([]);
  const [cantidadTotalRest, setCantidadTotalRest] = useState(0);
  const [pesoTotalRest, setPesoTotalRest] = useState(0);
  const [unidadMedida, setUnidadMedida] = useState(0);
  const [marcas, setMarcas] = useState([]);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  useEffect(() => {
    setInputs({ ...inputs, ...param });
  }, [param]);

  const onSubmit = (e) => {
    e.preventDefault();
    let data = { ...inputs };
    data.id = data.token;
    data.fotos = JSON.stringify(data.fotos);
    data.files = JSON.stringify(data.files);
    delete data.token;
    PostAsync("Inventario", "Set_Productos", data, { ...context }).then(
      (response) => {
        getInit();
        e.target.reset();
        if (params.subId !== undefined) {
          return history.push("/Inventario/Productos/" + params.subId);
        }
      }
    );
  };
  console.log(params);
  const callbackOnSubmit = (data) => {
    if (
      data !== undefined &&
      data.message !== undefined &&
      data.message.label !== undefined
    ) {
      context.setModalShow({
        show: true,
        size: "sm",
        message: (
          <div className="text-center">
            <div className="mb-3">{data.message.label}</div>
            <div
              className="btn btn-primary mt-3"
              onClick={() => {
                context.setModalShow({ show: false, message: "", size: "sm" });
              }}
            >
              Cerrar
            </div>
          </div>
        ),
      });
      setTimeout(function () {
        context.setModalShow({ show: false });
        history.goBack();
      }, 3000);
    }
  };

  const onChange = (e) => {
    if (e.target.name === "proveedor_id") {
      PostAsync(
        "Inventario",
        "GetMarcasAsociadas",
        { proveedor_id: e.target.value },
        { ...context }
      ).then((response) => {
        setData(response.data);
      });
    }

    console.log(inputs);
    Inputs(e, inputs, setInputs);
  };

  const getInit = () => {
    PostAsync(
      "Users",
      "GetUsuarios",
      { tipo_usuario_id: 3, limit: 1000000, start: 0 },
      { ...context }
    ).then((response) => {
      proveedores = [];

      if (response.data !== undefined && response.data.rows.length > 0) {
        response.data.rows.map((row, key) => {
          return proveedores.push({
            label: row.label,
            value: row.usuario_id,
          });
        });
      }

      let data_ = { id: params.id, fecha_inicio: inputs2.fecha_inicio === undefined ? null : inputs2.fecha_inicio, fecha_fin: inputs2.fecha_fin === undefined ? null : inputs2.fecha_fin, filtroMultiple: JSON.stringify(inputs2) };
      PostAsync("Inventario", "GetItem", data_, { ...context }).then(
        (response) => {
          if (response.data) {
            console.log(response.data);
            setInputs(response.data);
          }
          setMarcas(response.marcas.marcas)
          if (response.data && response.data.ma_unidad_medida_id) {
            if (response.data.ma_unidad_medida_id === "1") {
              //Kgr
              let unidadMedida = response.data.ma_unidad_medida_id;
              let cantidad = response.data.total_cantidad_restante;
              let peso = response.data.total_peso_restante;
              setProductos(response.vender);
              setCantidadTotalRest(cantidad);
              setPesoTotalRest(peso);
              setUnidadMedida(unidadMedida);
            } else if (response.data.ma_unidad_medida_id === "2") {
              //Unidades
              let cantidad = response.data.total_cantidad_restante
                ? response.data.total_cantidad_restante
                : 0;
              setCantidadTotalRest(cantidad);
              setProductos(response.vender);
              console.log(cantidad);
              console.log(new Intl.NumberFormat("de-DE").format(cantidad));
              new Intl.NumberFormat("de-DE").format(cantidad);
              console.log(typeof cantidad);
            }

            setParam({
              ma_unidad_medida_id: parseInt(response.data.ma_unidad_medida_id),
            });
          }
          setHistorial(response.historial);
        }
      );
    });
  };

  useEffect(() => {
    getInit();
  }, [inputs2]);

  useEffect(() => {
    if (params.id !== "0") {
      context.setLoadingStatus(true);
    }
    getInit();
  }, []);

  const onChangeCustom = (e) => {
    Inputs(e, inputs2, setInputs2);
    console.log(inputs2);
  };

  return (
    <div className="container" style={{ marginTop: "55px" }}>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">

              {/* <div className="h3"><b>Gestión de productos </b> | {inputs.producto} | Cant. disponible: {cantidadTotalRest}</div> */}
              <div className="h3">
                <b>Gestión de productos </b>{" "} | {inputs.producto}
                <div className="display-6">
                  {" "}
                  <p>
                    <small>
                      Cantidad disponible:{" "}
                      {new Intl.NumberFormat("de-DE").format(
                        cantidadTotalRest
                      )}
                      {unidadMedida === "1"
                        ? " | Peso disponible: " +
                        new Intl.NumberFormat("de-DE").format(pesoTotalRest)
                        : false}
                    </small>
                  </p>
                </div>
              </div>
              <div className="row pb-2">
                <div className="col-12 col-sm-6 mb-2">
                  <div className="h4">Unidad de medida</div>
                  <SelectorGenerico
                    modelo="Maestros"
                    metodo="get_tablas_maestras"
                    tabla="ma_unidad_medida"
                    param={param}
                    name="ma_unidad_medida_id"
                    setParam={setParam}
                  />
                </div>
              </div>
              <Tabs
                defaultActiveKey="precio"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="precio" title="Cambio de precio">
                  <form onSubmit={onSubmit}>
                    <div className="row pb-2">
                      <div className="col-12 col-sm-2 mb-2">
                        {params.producto ===
                          "Inventario/ListadoProductos/Edit/0" ? (
                          <Input
                            defaultValue={inputs.producto}
                            title="Producto"
                            placeholder="Producto"
                            type="text"
                            name="producto"
                            className="form-control"
                            onChange={onChange}
                            required={true}
                          />
                        ) : (
                          <Input
                            defaultValue={inputs.producto}
                            readonly
                            title="Producto"
                            placeholder="Producto"
                            type="text"
                            name="producto"
                            className="form-control"
                            onChange={onChange}
                            required={true}
                          />
                        )}
                      </div>
                      <div className="col-13 col-sm-2 mb-2">
                        <Input
                          defaultValue={inputs.precio_asociado}
                          title="Precio asociado"
                          placeholder="Precio asociado"
                          type="number"
                          name="precio_asociado"
                          className="form-control"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-12 col-sm-3 mb-2">
                        <Input
                          defaultValue={inputs.precio_no_asociado}
                          title="Precio no asociado"
                          placeholder="Precio no asociado"
                          type="number"
                          name="precio_no_asociado"
                          className="form-control"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-12 col-sm-3 mb-2">
                        <Input
                          defaultValue={inputs.iva}
                          title="IVA"
                          placeholder="IVA"
                          type="text"
                          name="iva"
                          className="form-control"
                          onChange={onChange}
                          required={true}
                        />
                      </div>
                      <div className="col-12 col-sm-2 mb-2">
                        <Input
                         defaultValue={inputs.fecha_cambio}
                          title="Fecha aplicación"
                          placeholder="Fecha aplicación"
                          type="date"
                          name="fecha_cambio"
                          className="form-control"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="row pb-2">
                      <div className="col-12 mb-2">
                        <Input
                          defaultValue={inputs.observacion}
                          title="Observación"
                          placeholder="Observación"
                          type="text"
                          name="observacion"
                          className="form-control"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center pb-2">
                      <div className="col-12 col-sm-2 mb-2">
                        <button
                          className="btn btn-primary col-12"
                        >
                          Guardar
                        </button>
                      </div>
                      <div className="col-12 col-sm-2 mb-2">
                        <NavLink
                          className="btn btn-secondary col-12"
                          to={
                            params.subId !== undefined
                              ? "/Inventario/Productos/" + params.subId
                              : "/Inventario/ListadoProductos"
                          }
                        >
                          Cancelar
                        </NavLink>
                      </div>
                    </div>
                    {historial.length > 0 ? (
                      <>
                        <div className="row">
                          <div className="col h5 mt-3">
                            Historial cambios de precios
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th className="text-center">#</th>
                                  <th className="text-center">
                                    Fecha de cambio
                                  </th>
                                  <th className="text-center">
                                    Asociado Anterior
                                  </th>
                                  <th className="text-center">
                                    Asociado Actual
                                  </th>
                                  <th className="text-center">
                                    No Asociado Anterior
                                  </th>
                                  <th className="text-center">
                                    No Asociado Actual
                                  </th>
                                  <th className="text-center">
                                    Fecha aplicación
                                  </th>
                                  <th className="text-center">Observación</th>
                                  <th className="text-center">Funcionario</th>
                                </tr>
                              </thead>
                              <tbody>
                                {historial.map((row, key) => {
                                  return (
                                    <tr key={key}>
                                      <td className="text-center">{key + 1}</td>
                                      <td className="text-center">
                                        {row.fecha}
                                      </td>
                                      <td className="text-center">
                                        {row.precio_asociado_anterior}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{
                                          backgroundColor: "#5C636A",
                                          color: "white",
                                        }}
                                      >
                                        {row.precio_asociado_nuevo}
                                      </td>
                                      <td className="text-center">
                                        {row.precio_no_asociado_anterior}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{
                                          backgroundColor: "#5C636A",
                                          color: "white",
                                        }}
                                      >
                                        {row.precio_no_asociado_nuevo}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{
                                          backgroundColor: "#5C636A",
                                          color: "white",
                                        }}
                                      >
                                        {row.fecha_cambio}
                                      </td>
                                      <td
                                        className="text-center"
                                        title={
                                          row.observacion.length >= 20
                                            ? row.observacion
                                            : ""
                                        }
                                      >
                                        {row.observacion.length > 20
                                          ? row.observacion.substr(0, 20) +
                                          "..."
                                          : row.observacion}
                                      </td>
                                      <td className='className="text-center"'>
                                        {row.nombre_funcionario}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </>
                    ) : (
                      false
                    )}
                  </form>
                </Tab>
                <Tab eventKey="vender" title="Por vender">
                  <div className="container">
                    <div className="row mb-5" style={{ marginTop: "80px" }}>
                      {/* <div className="col"></div> */}
                      <div className="row">
                        <>
                          <div className="row bg-gray">
                            <div className="col-12 ">
                              <b>Filtros de búsqueda gestión productos</b>
                            </div>
                            <div className="col-4 mb-3 pt-1 text-left">
                              <Input
                                defaultValue={inputs2.fecha_inicio}
                                title="Fecha desde"
                                placeholder="Fecha desde"
                                type="date"
                                name="fecha_inicio"
                                className="form-control"
                                onChange={onChangeCustom}
                                required={true}
                              />
                            </div>
                            <div className="col-4 mb-3 pt-1 text-left">
                              <Input
                                defaultValue={inputs2.fecha_fin}
                                title="Fecha hasta"
                                placeholder="Fecha hasta"
                                type="date"
                                name="fecha_fin"
                                className="form-control"
                                onChange={onChangeCustom}
                                required={true}
                              />
                            </div>
                            <div className="col-4 mb-3 pt-1 text-left">
                              <SelectorMultiple
                                url="/Inventario/ListadoMarcas"
                                name="marca_id"
                                title="Marca"
                                selectDefault="Marca"
                                data={marcas}
                                inputs={inputs2}
                                setInputs={setInputs2}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                  <div className="table">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Fecha de compra</th>
                          <th>Producto</th>
                          <th>Marca</th>
                          <th>Peso</th>
                          <th>Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productos.map((row, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{row.fecha_compra}</td>
                              <td>{inputs.producto}</td>
                              {row.marca == null ? (
                                <td>N/A</td>
                              ) : (
                                <td>{row.marca}</td>
                              )}
                              <td>{row.peso}</td>
                              <td>{row.cantidad}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr className="bg-primary text-white">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-white" >
                            {new Intl.NumberFormat("de-DE").format(
                              pesoTotalRest
                            )}
                          </td>
                          <td className="text-white" >
                            {new Intl.NumberFormat("de-DE").format(
                              cantidadTotalRest
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                </Tab>
              </Tabs>

            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default App;
