import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import StateContext from '../../helpers/ContextState';
import Config from '../../helpers/Config';


import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{
  const context                           =   React.useContext(StateContext);
  const [param, setParam]                 =   useState({tipo_usuario_id:2,tipo_transaccion:"venta"});
  
  

  let tds       =   [
    {
      label:"Consecutivo",
      value:"consecutive",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Fecha Compra",
      value:"fecha_recibido_string",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Semana",
      value:"semana_recibido",
      className:"col text-center",
      classNameHeader:"text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Fecha Sistema",
      value:"fecha_string",
      className:"col text-center",
      classNameHeader:"text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Día",
      value:"dia_recibido",
      className:"col text-center",
      classNameHeader:"text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Proveedor",
      value:"proveedor",
      className:" text-left",
      classNameHeader:"col text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Tipo",
      value:"es_asociado_string",
      className:"text-left",
      classNameHeader:"col text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Producto",
      value:"producto",
      className:" text-left",
      classNameHeader:"col text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Marca",
      value:"marca",
      className:"text-left font-size: 1px",
      classNameHeader:"col text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Sexo",
      value:"sexo",
      className:"text-center ",
      classNameHeader:"col text-center",
      estatus: "estatus_pago"
    },
    {
      label:"Planta",
      value:"planta",
      className:" text-center ",
      classNameHeader:"col text-center",
      estatus: "estatus_pago"
    },
    {
      total:true,
      label:"Cantidad",
      value:"cantidad_format",
      className:" text-center",
      classNameHeader:"col text-center",
      estatus: "estatus_pago"
    },
    {
      total:true,
      label:"Peso",
      value:"peso_format",
      className:" text-center",
      classNameHeader:"col text-center",
      estatus: "estatus_pago"
    },
    {
      total:true,
      label:"P. Unitario",
      value:"precio_unit_string",
      className:" text-center",
      classNameHeader:"col-1 text-center",
      estatus: "estatus_pago"
    },
    {
      total:true,
      label:"P. Total",
      value:"precio_string",
      className:"text-center",
      classNameHeader:"col text-center",
      estatus: "estatus_pago"
    },

    // {
    //   label:"Estado",
    //   value:"estatus",
    //   className:"text-center col-1  text-left",
    //   classNameHeader:"text-center",
    // },
  ]

  return  <div className="container-fluid">
            <div className="row">
              <div className="col"> 
                <Table
                  customFiltersI={true}
                  total={true}
                  toExport={Config.ConfigApirest+'Excel/Informes/ListadoComprasExcel/token:'+context.user.token}
                  toPDF={Config.ConfigApirest+'PDF/Informes/ListadoComprasFiltro/'+context.user.token}
                  td={tds}
                  modelo="Informes"
                  metodo="ListadoComprasFiltro"                   
                  limit="10"
                  params={param}
                  totalizacion={true}
                />
              </div>
            </div>
          </div>
}
export default App
