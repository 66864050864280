import Home from '../app/Home'
import TiposDeUsuarios      from '../app/Usuarios/TiposDeUsuarios'
import TiposDeUsuariosEdit  from '../app/Usuarios/TiposDeUsuariosEdit'

import ListaUsuarios from '../app/Usuarios/Lista'
import EditarUsuarios from '../app/Usuarios/Edit'
import NuevoUsuario from '../app/Usuarios/New'
import DatosAccesoUsuarios from '../app/Usuarios/DatosAccesoUsuarios'



import InventarioComprasReopen from '../app/Inventario/InventarioComprasReopen'

import FormUploadInventory from '../app/Inventario/FormUploadInventory'


import InventarioProductos from '../app/Inventario/InventarioProductos'
import InventarioCompras from '../app/Inventario/InventarioCompras'
import InventarioComprasEliminadas from '../app/Inventario/InventarioComprasEliminadas'

import InventarioComprasAdd from '../app/Inventario/InventarioComprasAdd'
import InventarioComprasView from '../app/Inventario/InventarioComprasView'
import ListadoProductos from '../app/Inventario/ListadoProductos'
import ListadoPlantas from '../app/Inventario/ListadoPlantas'

import GestionEditPlanta from '../app/Inventario/GestionEditPlanta'

import ListadoMarcas from '../app/Inventario/ListadoMarcas'
import Gestion from '../app/Inventario/ListadoProductos'


import InventarioVentas from '../app/Ventas/Ventas'
import InventarioCartera from '../app/Ventas/Cartera'
import InventarioVentasEliminadas from '../app/Ventas/VentasEliminadas'

import InventarioVentasAdd from '../app/Ventas/VentasAdd'
import InventarioVentasView from '../app/Inventario/InventarioVentasView'

import InformesVentas from '../app/Informes/InformesVentas'
import InformesCompras from '../app/Informes/InformesCompras'
import InformesPagos from '../app/Informes/InformesPagos'
import InformesCompraVenta from '../app/Informes/InformesCompraVenta'
import InformesCompraVentaDetalle from '../app/Informes/InformesCompraVentaDetalle'


import CarteraDetalle from '../app/Ventas/CarteraDetalle'



import ListaPagos from '../app/Pagos/ListaPagos'
import ListaPagosDetalle from '../app/Pagos/ListaPagosDetalle'

import ListaPagosEfectuados from '../app/Pagos/ListaPagosEfectuados'
import ListaEfectadosPagosDetalle from '../app/Pagos/ListaEfectadosPagosDetalle'
import PagosListaEfectadosDetalleEliminado from '../app/Pagos/PagosListaEfectadosDetalleEliminado'


import Graficos from '../app/Home/Graficos'


import AgregarProducto from '../app/Inventario/AgregarProducto'
import GestionEdit from '../app/Inventario/GestionEdit'
import GestionEditProductos from '../app/Inventario/GestionEditProductos'

import Configuracion from '../app/Configuracion'
import AppConfiguracion from '../app/Configuracion/AppConf'

import { faBox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VentaProductosDetalle from '../app/Ventas/VentaProductoDetalle'


const exportar  = {
  Development : 'Fenixpuntonet',
  // Title:'Asocolcarnes',
  LogoSm:'./images/favicon.png',
  View:'Title',
  Modulos:[
    {
      public:true,
      label:'Resumen',
      subLabel:'Home',
      modulo:'Inventario',
      submodulo:'Compras',
      component:Home,
      ico:<FontAwesomeIcon icon={faBox}/>,
      url:'/',
      shortCode:"/",
    },
    {
      public:false,
      label:'Graficos',
      subLabel:'Resumen',
      modulo:'Resumen',
      submodulo:'Add',
      component: Graficos,
      ico:<FontAwesomeIcon icon={faBox}/>,
      url:'/Resumen/Graficos/:nameProduct/:idProduct',
      shortCode:'/',
    },
    {
      public:true,
      label:'Compras',
      subLabel:'Compras',
      modulo:'Inventario',
      submodulo:'Compras',
      component:InventarioCompras,
      ico:<FontAwesomeIcon icon={faBox}/>,
      url:'/Inventario/Compras',
      // shortCode:"/Inventario/Compras",
      items:[
        {
          public:true,
          label:'Listado de Compras',
          subLabel:'Compras',
          modulo:'Inventario',
          submodulo:'Compras',
          component:InventarioCompras,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/ListadoCompras',
          shortCode:"/Inventario/ListadoCompras",
        },
        {
          public:true,
          label:'Nueva Compra',
          subLabel:'Compras',
          subLabel2:'Listado de Compras',
          modulo:'Inventario',
          submodulo:'Add',
          component:InventarioComprasAdd,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Compras/NuevaCompra',
          shortCode:'/Inventario/Compras/NuevaCompra',
          goBack:'/Inventario/ListadoCompras',
        },
        {
          public:false,
          label:'Reabrir Compra',
          subLabel:'Compras',
          modulo:'Inventario',
          submodulo:'Edit',
          component:InventarioComprasReopen,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Compras/ReOpen',
          shortCode:'/Inventario/Compras/ReOpen',
        },
        {
          public:false,
          label:'Ver Compra',
          subLabel:'Compras',
          subLabel2:'Listado de Compras',
          modulo:'Inventario',
          submodulo:'Add',
          component:InventarioComprasAdd,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Compras/Edit/:id',
          shortCode:'/Inventario/Compras/Edit',
          goBack:'/Inventario/ListadoCompras',
        },
        {
          public:false,
          label:'Detalle Compra',
          subLabel:'Compras',
          modulo:'Inventario',
          submodulo:'Add',
          component:InventarioComprasView,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Compras/View/:id',
          shortCode:'/Inventario/Compras/View',
        },
        {
          public:false,
          label:'Imprimir Ticket',
          subLabel:'Compras',
          modulo:'Inventario',
          submodulo:'Imprimir',
          print:true,
          url:'PDF/Inventario/Compras/',
          shortCode:'PDF/Inventario/Compras/',
        },
      ]
    },    
    
    {
      public:true,
      label:'Ventas',
      subLabel:'Ventas',
      modulo:'Inventario',
      submodulo:'Ventas',
      component:InventarioProductos,
      ico:<FontAwesomeIcon icon={faBox}/>,
      url:'/Inventario/Ventas',
      shortCode:'/Inventario/Ventas',
      items:[
        {
          public:false,
          label:'Detalle',
          subLabel:'Ventas',
          subLabel2:'Cartera',
          modulo:'Ventas',
          submodulo:'Cartera',
          component:CarteraDetalle,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Ventas/Cartera/Detalle/:id',
          shortCode:'/Ventas/Cartera/Detalle',
          goBack: '/Ventas/Cartera'
        },
        {
          public:true,
          label:'Listado de Ventas',
          subLabel:'Ventas',
          subLabel2:'Listado de Ventas',
          modulo:'Ventas',
          submodulo:'Ventas',
          component:InventarioVentas,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Ventas/ListadoVentas',
          shortCode:'/Ventas/ListadoVentas',
          
        },
        {
          public:true,
          label:'Nueva Venta',
          subLabel:'Ventas',
          subLabel2:'Listado de Ventas',
          modulo:'Inventario',
          submodulo:'Add',
          component:InventarioVentasAdd,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Ventas/NuevaVenta',
          shortCode:'/Ventas/NuevaVenta',
          goBack:'/Ventas/ListadoVentas',
        },
        {
          public:true,
          label:'Cartera',
          subLabel:'Ventas',
          subLabel2:'Cartera',
          modulo:'Ventas',
          submodulo:'Cartera',
          component:InventarioCartera,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Ventas/Cartera',
          shortCode:'/Ventas/Cartera',
          
        },
        {
          public:false,
          label:'Ver Venta',
          subLabel:'Ventas',
          subLabel2:'Listado de Ventas',
          modulo:'Inventario',
          submodulo:'Add',
          component:InventarioVentasAdd,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Ventas/Edit/:id',
          shortCode:'/Inventario/Ventas/Edit',
          goBack:'/Ventas/ListadoVentas',
        },
        {
          public:false,
          label:'Detalle',
          subLabel:'Ventas',
          modulo:'Inventario',
          submodulo:'Add',
          component:VentaProductosDetalle,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Ventas/Edit/:idVenta/Detalle/:id/:consecutive/:pag',
          shortCode:'/Inventario/Ventas/Edit/:idVenta/Detalle/:id',
        },
        {
          public:false,
          label:'Detalle Compra',
          subLabel:'Ver Venta',
          modulo:'Inventario',
          submodulo:'Add',
          component:InventarioVentasView,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Ventas/View/:id',
          shortCode:'/Inventario/Ventas/View',
        },
        {
          public:false,
          label:'Imprimir Ticket',
          subLabel:'Ventas',
          modulo:'Inventario',
          submodulo:'Imprimir',
          print:true,
          url:'PDF/Inventario/Ventas/',
          shortCode:'PDF/Inventario/Ventas',
        },
      ]
    },
    {
      public:true,
      label:'Inventario',
      subLabel:'Inventario',
      url:'/Inventario/Productos',
      shortCode:'/Inventario/Productos',
      modulo:'Módulos',
      submodulo:'main',
      component:ListadoProductos,
      ico:<FontAwesomeIcon icon={faBox}/>,
      items:[
        {
          public:true,
          label:'Marcas',
          subLabel:'Inventario',
          modulo:'Inventario',
          submodulo:'Listado',
          component:ListadoMarcas,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/ListadoMarcas',
          shortCode:'/Inventario/ListadoMarcas',
        },
        {
          public:true,
          label:'Productos',
          subLabel:'Inventario',
          modulo:'Inventario',
          submodulo:'Listado',
          component:ListadoProductos,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/ListadoProductos',
          shortCode:'/Inventario/ListadoProductos',
        },
        {
          public:false,
          label:'Agregar Producto',
          subLabel:'Inventario',
          modulo:'Inventario',
          submodulo:'Listado',
          component:AgregarProducto,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/ListadoProductos/AgregarProducto',
          shortCode:'/Inventario/ListadoProductos/AgregarProducto',
          goBack:'/Inventario/ListadoProductos',
        },
        {
          public:true,
          label:'Plantas',
          subLabel:'Inventario',
          modulo:'Inventario',
          submodulo:'Listado',
          component:ListadoPlantas,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Listado/ListadoPlantas',
          shortCode:'/Inventario/Listado/ListadoPlantas',
        },
        {
          public:false,
          label:'Editar Planta',
          subLabel:'Inventario',
          subLabel2:'Plantas',
          modulo:'Inventario',
          submodulo:'EditPlanta',
          component:GestionEditPlanta,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Plantas/Edit/:id',
          shortCode:'/Inventario/Plantas/Edit',
          goBack:'/Inventario/Listado/ListadoPlantas',
        },
        {
          public:false,
          label:'Listado',
          subLabel:'Inventario',
          modulo:'Inventario',
          submodulo:'Upload',
          component:FormUploadInventory,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/ListadoProductos/Upload',
          shortCode:'/Inventario/ListadoProductos/Upload',
        },
        {
          public:false,
          label:'Listado',
          subLabel:'Inventario',
          modulo:'Inventario',
          submodulo:'Listado',
          component:ListadoProductos,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Productos/:uid',
          shortCode:'/Inventario/Productos',
        },
        {
          public:false,
          label:'Editar Productos',
          subLabel:'Inventario',
          subLabel2:'Productos',
          modulo:'Inventario',
          submodulo:'EditProductos',
          component:GestionEditProductos,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/ListadoProductos/Edit/:id',
          shortCode:'/Inventario/ListadoProductos/Edit',
          goBack:'/Inventario/ListadoProductos',
        },
        {
          public:false,
          label:'Agregar Marcas',
          subLabel:'Inventario',
          modulo:'Inventario',
          submodulo:'Configurar',
          component:Gestion,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Gestion',
          shortCode:'/Inventario/Gestion',
        },
        {
          public:false,
          label:'Editar Marca Inventario',
          subLabel:'Inventario',
          subLabel2:'Marcas',
          modulo:'Inventario',
          submodulo:'Edit',
          component:GestionEdit,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Item/Edit/:id',
          shortCode:'/Inventario/Item/Edit',
          goBack:'/Inventario/ListadoMarcas',
        },
        {
          public:false,
          label:'Editar Item Inventario',
          subLabel:'Inventario',
          modulo:'Inventario',
          submodulo:'Edit',
          component:GestionEdit,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Inventario/Item/Edit2/:subId/:id',
          shortCode:'/Inventario/Item/Edit2',
        },
      ]
    },
    {
      public:true,
      label:'Informe',
      modulo:'Informe',
      subLabel:'Compras',
      submodulo:'Informe',
      component:InformesVentas,
      ico:<FontAwesomeIcon icon={faBox}/>,
      url:'/Informes/Lista',
      items:[
        {
          public:true,
          label:'Compras',
          subLabel:'Informes',
          modulo:'Informe',
          submodulo:'Informes',
          component:InformesCompras,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Informes/ListaCompras',
          shortCode:'/Informes/ListaCompras',
        },
        {
          public:true,
          label:'Ventas',
          subLabel:'Informes',
          modulo:'Informe',
          submodulo:'Informes',
          component:InformesVentas,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Informes/ListaVentas',
          shortCode:'/Informes/ListaVentas',
        },
        {
          public:true,
          label:'Pagos',
          subLabel:'Informes',
          modulo:'Pagos',
          submodulo:'Informes',
          component:InformesPagos,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Informes/ListaInformesPagos',
          shortCode:'/Informes/ListaInformesPagos',
        },
        {
          public:true,
          label:'Compras y Ventas',
          subLabel:'Informes',
          modulo:'Pagos',
          submodulo:'InformesCompraVenta',
          component:InformesCompraVenta,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Informes/InformesCompraVenta',
          shortCode:'/Informes/InformesCompraVenta',
        },
        {
          public:false,
          label:'Detalles',
          subLabel2: 'Compras y ventas',
          subLabel:'Informes',
          modulo:'Pagos',
          submodulo:'InformesCompraVentaDetalle',
          component:InformesCompraVentaDetalle,
          ico:<FontAwesomeIcon icon={faBox}/>,
          // url:'/Informes/InformesCompraVenta/Ver/:id/:fecha_inicio/:fecha_fin',
          url:'/Informes/InformesCompraVenta/Ver/:id',
          shortCode:'/Informes/InformesCompraVenta/Ver',
          goBack:'/Informes/InformesCompraVenta',
        },
      ]
    },{
      public:true,
      label:'Pagos',
      subLabel:'Pagos',
      modulo:'Pagos',
      submodulo:'ListaPagos',
      component:ListaPagos,
      ico:<FontAwesomeIcon icon={faBox}/>,
      url:'/Pagos/Lista',
      shortCode:'/Pagos/Lista',
      items:[
        {
          public:true,
          label:'Pagos a realizar',
          subLabel:'Pagos',
          modulo:'Pagos',
          submodulo:'ListaPagos',
          component:ListaPagos,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Pagos/Lista',
          shortCode:'/Pagos/Lista',
        },
        {
          public:true,
          label:'Pagos efectuados',
          subLabel:'Pagos',
          modulo:'Pagos',
          submodulo:'ListaPagos',
          component:ListaPagosEfectuados,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Pagos/ListaEfectados',
          shortCode:'/Pagos/ListaEfectados',
        },
        {
          public:false,
          label:'Pagos eliminados',
          subLabel:'Módulo',
          submodulo:'ListaPagos',
          component:PagosListaEfectadosDetalleEliminado,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Pagos/ListaEfectados/DetalleEliminado/:id',
          shortCode:'/Pagos/ListaEfectados/DetalleEliminado',
          goBack:'/Pagos/ListaEfectados',
        },
        {
          public:false,
          modulo:'Pagos',
          submodulo:'ListaPagos',
          component:ListaPagosDetalle,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Pagos/Lista/Detalle/:id/:fecha_inicio?/:fecha_fin?',
          shortCode:'/Pagos/Lista/Detalle',
          goBack:'/Pagos/Lista',
        },
        {
          public:false,
          label:'Detalle pago',
          modulo:'Pagos',
          submodulo:'ListaPagos',
          component:ListaEfectadosPagosDetalle,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Pagos/ListaEfectados/Detalle/:id/:nComprobante',
          shortCode:'/Pagos/ListaEfectados/Detalle',
          goBack:'/Pagos/ListaEfectados/Detalle',
        },
      ]
    },
    {
      public:true,
      label:'Usuarios',
      modulo:'Usuario',
      submodulo:'Gestion',
      component:InventarioProductos,
      ico:<FontAwesomeIcon icon={faBox}/>,
      url:'/Usuarios',
      shortCode:'/Usuarios',
      items:[
        {
          public:true,
          label:'Lista de Usuarios',
          modulo:'Usuario',
          subLabel:'Usuarios',
          submodulo:'Tipos',
          component:ListaUsuarios,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Usuarios/Lista',
          shortCode:'/Usuarios/Lista',
        },
        {
          public: true,
          label: 'Nuevo Usuario',
          modulo: 'Usuario',
          subLabel: 'Usuarios',
          submodulo: 'Editar',
          component: NuevoUsuario,
          ico: <FontAwesomeIcon icon={faBox} />,
          url: '/Usuarios/Usuario/New/:id',
          shortCode: '/Usuarios/Usuario/New',
          goBack: '/Usuarios/Lista',
        },
        {
          public:true,
          label:'Tipo de Usuarios',
          subLabel:'Usuarios',
          modulo:'Usuario',
          submodulo:'Tipos',
          component:TiposDeUsuarios,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Usuarios/TiposDeUsuarios',
          shortCode:'/Usuarios/TiposDeUsuarios',
        },
        {
          public:false,
          label:'Editar Usuario',
          subLabel:'Usuarios',
          subLabel2:'Lista Usuarios',
          modulo:'Usuario',
          submodulo:'Editar',
          component:EditarUsuarios,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Usuarios/Usuario/Edit/:id',
          shortCode:'/Usuarios/Usuario/Edit',
          goBack:'/Usuarios/Lista',
        },
        {
          public:false,
          label:'Editar Usuario',
          modulo:'Usuario',
          submodulo:'Editar',
          subLabel2:'Lista Usuarios',
          component:EditarUsuarios,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Usuarios/Usuario/Edit/:id/:subId/:label',
          shortCode:'/Usuarios/Usuario/Edit',
          goBack:'/Usuarios/Lista',
        },
        {
          public:false,
          label:'Editar Usuario',
          modulo:'Usuario',
          submodulo:'Editar',
          component:DatosAccesoUsuarios,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Usuarios/DatosAcceso/:id',
          shortCode:'/Usuarios/DatosAcceso',
        },
        {
          public:false,
          label:'Tipo de Usuarios Edit',
          modulo:'Usuario',
          submodulo:'Tipos',
          subLabel:'Usuarios',
          subLabel2:'Tipo de Usuarios',
          component:TiposDeUsuariosEdit,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Usuarios/TiposDeUsuarios/Edit/:id/:usuario',
          shortCode:'/Usuarios/TiposDeUsuarios/Edit',
          goBack:'/Usuarios/TiposDeUsuarios',
        },
        {
          public:false,
          label:'Enviar Datos de acceso',
          modulo:'Usuario',
          submodulo:'Accesos',
          component:TiposDeUsuariosEdit,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Usuarios/Accesos/Enviar/:id',
          shortCode:'/Usuarios/Accesos/Enviar',
        }
      ]
    },
    {
      public:true,
      label:'Papelera',
      subLabel:'Papelera',
      modulo:'Papelera',
      // submodulo:'Ventas',
      component:Configuracion,
      ico:<FontAwesomeIcon icon={faBox}/>,
      url:'/Papelera',
      shortCode:"/Papelera",
      items:[
        {
          public:true,
          label:'Ventas eliminadas',
          subLabel:'Papelera',
          
          // modulo:'Usuario',
          // subLabel:'Usuarios',
          submodulo:'Tipos',
          component:InventarioVentasEliminadas,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Papelera/VentasEliminadas',
          shortCode:'/Papelera/VentasEliminadas',
        },
        {
          public:false,
          label:'Detalle',
          subLabel:'Papelera',
          subLabel2:'Ventas eliminadas',
          modulo:'Inventario',
          submodulo:'Add',
          component:InventarioVentasAdd,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Papelera/VentasEliminadas/Edit/:id',
          shortCode:'/Papelera/VentasEliminadas/Edit',
          goBack:'/Papelera/VentasEliminadas',
        },
        {
          public:true,
          label:'Compras eliminadas',
          subLabel:'Papelera',
          // modulo:'Usuario',
          // subLabel:'Usuarios',
          submodulo:'Tipos',
          component:InventarioComprasEliminadas,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Papelera/ComprasEliminadas',
          shortCode:'/Papelera/ComprasEliminadas',
        },
        {
          public:false,
          label:'Detalle',
          subLabel:'Papelera',
          subLabel2:'Compras eliminadas',
          modulo:'Inventario',
          submodulo:'Add',
          component:InventarioComprasAdd,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/Papelera/ComprasEliminadas/Edit/:id',
          shortCode:'/Papelera/ComprasEliminadas/Edit',
          goBack:'/Papelera/ComprasEliminadas',
        },
      ]
    },
    {
      public:true,
      label:'Configuración',
      subLabel:'Configuración',
      subLabel2:'Configuración de Aplicación',
      modulo:'Inventario',
      submodulo:'Compras',
      component:Configuracion,
      ico:<FontAwesomeIcon icon={faBox}/>,
      url:'/configuracion',
      shortCode:"/configuracion",
      items:[
        {
          public:true,
          label:'Tributaria',
          // modulo:'Usuario',
          // subLabel:'Usuarios',
          submodulo:'Tipos',
          component:Configuracion,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/configuracion/tributaria',
          shortCode:'/configuracion/tributaria',
        },
        {
          public:true,
          label:'Parámetros Aplicación',
          // modulo:'Usuario',
          // subLabel:'Usuarios',
          submodulo:'Tipos',
          component:AppConfiguracion,
          ico:<FontAwesomeIcon icon={faBox}/>,
          url:'/configuracion/app-parameters',
          shortCode:'/configuracion/app-parameters',
        },
      ]
    },
  ]
}


export default  exportar
