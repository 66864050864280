import React, { useState, useEffect } from 'react';
import { Inputs, PostAsync } from '../helpers/Functions';
import StateContext from '../helpers/ContextState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ContentConfirmModal from '../components/ContentConfirmModal';
import ContentContinueModal from '../components/ContentContinueModal';
import AddContactos from '../components/AddContactos';
import EditContactos from '../components/EditContactos';
import BotonVolverHome from './BotonVolverHome';
import Config from '../helpers/Config';


const App = ({ id, uid }) => {

  const context = React.useContext(StateContext);
  const [data, setData] = useState([]);
  const [inputs, setInputs] = useState({ nombres: "" });
  const [inputs2, setInputs2] = useState({});


  useEffect(() => {
    let mounted = true
    getInit(mounted)
    return function cleanup() {
      mounted = false
    }
  }, [])

  useEffect(() => {
    getInit()
  }, [inputs, inputs2])

  const openDownload = (e) => {
    e.preventDefault()
    let uri = e.target.href + "?";

    if (inputs.fecha_inicio) {
      uri += "fecha_inicio=" + inputs.fecha_inicio + "&"
    }
    if (inputs.fecha_fin) {
      uri += "fecha_fin=" + inputs.fecha_fin + "&"
    }
    if (Object.entries(inputs2).length > 0) {
      Object.entries(inputs2).map((row, key) => {
        if (row[1] && row[1].length > 0) {
          row[1].map((row2, key2) => {
            return uri += row2.name + "[]=" + row2.value + "&"
          })
        } else {
          return false
        }
      })
    }
    //console.log(uri);
    window.open(uri)
  }

  const getInit = (mounted) => {
    PostAsync("Inventario", "get_plantas", {}, { ...context }).then((response) => {
      setData(response.data.rows)
    })
  }

  const onChange = (e) => {
    Inputs(e, inputs, setInputs)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    let inputs_ = { ...inputs }
    inputs_.uid = uid
    PostAsync("Inventario", "SetPlanta", inputs_, { ...context }).then((response) => {
      getInit(true)
      document.getElementById("myForm").reset();
      context.setModalShow(
        {
          show: true,
          message: <ContentContinueModal message="PLANTA AGREGADA CON ÉXITO"
            event1={() => context.setModalShow({ show: false })}
          />,
          size: "xs"
        }
      )
    })
  }

  const onClick = (row) => {
    context.setModalShow(
      {
        show: true,
        message: <ContentConfirmModal message="¿Desea eliminar este registro?"
          event1={() => del(row)}
          event2={() => context.setModalShow({ show: false })}
        />,
        size: "xs"
      }
    )
  }

  const del = (row) => {
    PostAsync("Users", "delUsuarioPlantas", { id: row.token }, { ...context }).then((response) => {
      getInit(true)
    })
  }

  const openAdd = (row) => {

    context.setModalShow(
      {
        show: true,
        message: <AddContactos {...row} getInit={getInit} context={context} />,
        size: "xs"
      }
    )
  }

  const editContact = (row) => {

    context.setModalShow(
      {
        show: true,
        message: <EditContactos {...row} getInit={getInit} context={context} />,
        size: "xs"
      }
    )
  }

  return <form onSubmit={onSubmit} id="myForm">
    <div className="h4 mb-2">Plantas</div>
    <BotonVolverHome />

    <a onClick={openDownload} className="btn btn-primary me-2" href={Config.ConfigApirest + 'Excel/Informes/ListadoPlantas/token:' + context.user.token} target="_blank">
      Exportar
    </a>
    <a onClick={openDownload} className="btn btn-secondary" href={Config.ConfigApirest + 'PDF/Informes/ListadoPlantas/' + context.user.token} target="_blank">
      PDF
    </a>
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <Row>
              <Col className="border-end text-end">
                <input className="form-control"
                  required={true}
                  type="text"
                  name="label"
                  placeholder="Planta"
                  onChange={onChange}
                />
              </Col>
              <Col className="border-end text-end">
                <input className="form-control"
                  required={true}
                  type="text"
                  name="direccion"
                  placeholder="Dirección"
                  onChange={onChange}
                />
              </Col>
              <Col className="border-end text-end">
                <input className="form-control"
                  required={true}
                  type="text"
                  name="telefono"
                  placeholder="Teléfono"
                  onChange={onChange}
                />
              </Col>
              <Col className="border-end text-end">
                <input className="form-control"
                  required={true}
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={onChange}
                />
              </Col>
              <Col xs="2" className="border-end">
                <Button variant="primary" className="col-12" type="submit">
                  Agregar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col>
        <div>
          <Table responsive="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Planta</th>
                <th>Dirección / Tel / Email</th>
                <th>Contactos</th>
                <th width="10">Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? <>
                <tr>
                  <td colSpan="4" className="text-center">
                    No hay registros
                  </td>
                </tr>
              </> : <>
                {data.map((row, key) => {
                  return <tr key={key}>
                    <td width="20">
                      {key + 1}
                    </td>
                    <td width="10%">
                      {row.label}
                    </td>
                    <td >
                      {row.direccion}
                      <br />
                      {row.telefono}
                      <br />
                      {row.email}
                    </td>
                    <td width="40%">
                      <div className="max-height-100">
                        {(row.contactos.length > 0) ? <>
                          {row.contactos.map((row2, key2) => {
                            return <div className="mb-1 border-bottom pb-2 bg-gray p-2">
                              <div className="h5">{row2.contacto}</div>
                              <div className="text-h7">{row2.cargo}</div>
                              <div className="text-h7">{row2.email}</div>
                              <div className="text-h7">{row2.telefono}</div>
                              <pre className="h6">{row2.comentario}</pre>
                              <span style={{ margin: '0 5px' }}></span>
                              <div className="btn btn-warning btn-sm" onClick={() => editContact(row2)}>Editar</div>
                            </div>

                          })}
                        </> : false}
                      </div>
                      <div className="mt-3">
                        <div className="btn btn-warning btn-sm" onClick={() => openAdd(row)}>+</div>
                      </div>
                    </td>
                    <td width="20" className="text-center">
                      <div className="btn btn-warning" onClick={() => onClick(row)}>
                        X
                      </div>
                    </td>
                  </tr>
                })}
              </>}

            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  </form>
}

export default App
