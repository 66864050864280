import React,{useEffect,useState} from 'react';
import StateContext from '../helpers/ContextState';
import Register from './Auth/Register';
import Recover from './Auth/Recover';
import RecoverPass from './Auth/RecoverPass';
import Login from './Auth/Login';

import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

const App=()=>{
  const context             = React.useContext(StateContext);
  const [send, setSend]     = useState(false);

  useEffect(() => {
    context.loading(false)
    if (!send) {
      if (process.env.NODE_ENV==='development') {
        return init()
      }
      setSend(true)
    }
  },[context,send])

  const init=()=>{
    let object={}
    context.constants.Modulos.map((row,key)=>{
      if (row.items) {
        row.items.map((row2,key2)=>{
          if (row2.items!==undefined) {
            row2.items.map((row3,key3)=>{
              return object[row3.label] = JSON.stringify(row3)
            })
            return false
          }else {
            return object[row2.label] = JSON.stringify(row2)
          }
        })
        return object[row.label] = JSON.stringify(row)
      }else {
        return object[row.label] = JSON.stringify(row)
      }

    })
  }

  return    <div style={{background:"#fff"}}>
              {!context.loadingStatus?<>
                <Router>
                  <Route exact path="/">
                    <Login/>
                  </Route>
                  <Route exact path="/login">
                    <Login/>
                  </Route>
                  <Route exact path="/register">
                    <Register/>
                  </Route>
                  <Route exact path="/recover">
                    <Recover/>
                  </Route>
                  <Route exact path="/recoverPass/:id">
                    <RecoverPass/>
                  </Route>
                </Router></>:false}
            </div>



}
export default App
