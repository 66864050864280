import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import SelectorGenerico from '../../components/SelectorGenerico';
import StateContext from '../../helpers/ContextState';
import {PostAsync,format} from '../../helpers/Functions';
import { useParams  } from "react-router-dom";

import Comentarios from '../comentarios';


import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{
  const { nComprobante } = useParams();
  const context                           =   React.useContext(StateContext);
  const params                            =   useParams();
  const [data, setData]                   =   useState([]);
  const [btn, setBtn]                     =   useState(false);
  const [btnPagar, setBtnPagar]           =   useState([]);
  const [proveedor, setProveedor]         =   useState({});
  const [totalLineas, setTotalLineas]           =   useState([]);
  const [totalLineasPeso, setTotalLineasPeso]   =   useState([]);
  const [totalLineasTotal, setTotalLineasTotal] =   useState([]);
  const [totalGeneral, setTotalGeneral]       =   useState({peso:0,cantidad:0,total:0});

  useEffect(() => {
    if (params.id) {
      getInit()
    }
  },[params])

  const getInit=()=>{
    setData([])
    PostAsync("Pagos","ListaPagosEfectuadosDetalleUno",{id:params.id},context).then((response)=>{
      if (response.data) {
        setData(response.data)
      }
    })
  }
  // console.log(nComprobante);  
  return  <div className="container-fluid" style={{marginTop: '60px'}}>
            <div className="row justify-content-center mb-3">
              <div className="col-12 h3">
                <b>Proveedor {data.proveedor}</b>
              </div>
            </div>
            <div className="row">
              <div className="col">
                Marca: <b>{data.marca}</b>
              </div>
              <div className="col">
                Fecha: <b>{data.fecha}</b>
              </div>
              <div className="col">
                Hora: <b>{data.hora}</b>
                {console.log(data)}
              </div>
            </div>
            <div className="row">
              <div className="col">
                Peso: <b>{format(data.peso)}</b>
              </div>
              <div className="col">
                Cantidad: <b>{data.cantidad}</b>
              </div>
              <div className="col">
                Precio: <b>{format(data.precio)}</b>
              </div>
            </div>
            <div className="row pb-4 border-bottom">
              <div className="col">

              </div>
              <div className="col">

              </div>
              <div className="col">
                Total: <b>{data.peso==='0.00'?format(data.precio*data.cantidad):format(data.precio*data.peso)}</b>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Comentarios id={data.token}/>
              </div>
            </div>
          </div>
}
export default App
