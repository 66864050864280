import React,{useState,useEffect} from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types';
import {PostAsync} from '../helpers/Functions';
import StateContext from '../helpers/ContextState';
import Store from '../helpers/Store';

const App=({param,modelo,metodo,name,setParam,tabla,lg})=>{
  const context             =   React.useContext(StateContext);
  const [tipos, setTipos]   =   useState([]);

  useEffect(() => {
    setParam({"estatus": 1, "ma_naturaleza_id": 1, "tipo_usuario_id" :2});
    if (tipos.length===0) {
      PostAsync(modelo,metodo,{tabla:tabla},{...context}).then((response)=>{
        if (response.data!==undefined) {
          setTipos(response.data)
        }
      })
    }
  },[tipos])

  // const handleChange=(row)=>{
  //   // console.log(row);
  //   row.label.includes('Persona') && Store.set('person_type_new_user', row.value);
    
  //   if (setParam) {
  //     // console.log("param",param)
  //     let param_      = {...param}
  //     if (param && param_[name] === parseInt(row.value)) {
  //       // console.log("param_[name]", param_[name], "parseInt(row.value)", parseInt(row.value) )
  //       param_        = {}
  //     }else {
  //       param_[name]  = typeof parseInt(row.value) === "number"?parseInt(row.value):row.value
  //       console.log("param_[name]", param_[name])
  //     }
  //     // param_.ma_naturaleza_id = parseInt(Store.get('person_type_new_user'));
  //     console.log("param_.ma_naturaleza_id", param_.ma_naturaleza_id)
  //     setParam(param_)
      
  //   }
  // }

  const handleChange=(row)=>{
    row.label.includes('Persona') && Store.set('person_type_new_user', row.value);
    if (setParam) {
      let param_      = {...param}
      if (param && param_[name] === parseInt(row.value)) {
        param_        = {}
      }else {
        param_[name]  = typeof parseInt(row.value) === "number"?parseInt(row.value):row.value
      }
      setParam(param_)
      // console.log("param_",param_)
    }
  }
  return  <Row>
            {tipos?<>
              {tipos.map((row,key)=>{
                return  <Col  key={key}
                xs="12"
                lg={lg===undefined?"6":lg}
                              size="sm"
                              className="mb-3"
                        >
                          <div className="d-grid gap-2">
                            <Button variant={parseInt(param[name])===parseInt(row.value)?"primary":"secondary" }
                              onClick={()=>handleChange(row)}
                              size="sm">
                              {row.label}
                            </Button>
                          </div>
                        </Col>
              })}
            </>:false}
          </Row>
}

App.propTypes = {
  name: PropTypes.string.isRequired,
  modelo: PropTypes.string.isRequired,
  metodo: PropTypes.string.isRequired,
  param: PropTypes.object.isRequired,
  setParam: PropTypes.func.isRequired,
}

App.defaultProps = {
  name: "estatus",
  modelo: "",
  metodo: "",
  param: {estatus:0},
  setParam: ()=>{return false},
}

export default App
