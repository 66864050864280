import Alert from 'react-bootstrap/Alert'
const App=()=>{
  return  <div className="container">
            <div className="row">
              <div className="col">
                <Alert variant="warning" className="text-center">
                  Módulo inexistente
                </Alert>
              </div>
            </div>
          </div>
}
export default App
