import React,{useState,useEffect} from 'react';
import './App.css';
import Div100vh from 'react-div-100vh'
import Store from './helpers/Store'
import Spinner from 'react-bootstrap/Spinner'
import Out from './app/Out'
import In from './app/In'
import StateContext from './helpers/ContextState';
import Modal from './components/Modales';
import Constants from './helpers/Constants';

let user_         =   Store.get("user")
let privilegios_  =   Store.get("privilegios")
const timeLoading =   2000

const App=()=>{

  const [loadingStatus, setLoadingStatus]   = useState(true);
  const [user, setUser]                     = useState(user_);
  const [privilegios, setPrivilegios]       = useState(privilegios_);
  const [modalShow, setModalShow]           = useState(false);
  const [extraPosition, setExtraPosition]   = useState("");
  const [goBack, setGoBack]                 = useState(false);

  useEffect(() => {
    loading()
  },[user])

  const loading = (status) =>{
    if (status!==undefined) {
      setTimeout(function(){ setLoadingStatus(false) }, timeLoading);
    }else {
      setLoadingStatus(loadingStatus?false:true)
    }
  }

  return  <Div100vh>
            <Modal modalShow={modalShow} setModalShow={setModalShow}/>
            <StateContext.Provider value= {{
                                              constants:Constants,
                                              goBack:goBack,
                                              setGoBack:setGoBack,
                                              extraPosition:extraPosition,
                                              setExtraPosition:setExtraPosition,
                                              loading:loading,
                                              user:user,
                                              setUser:setUser,
                                              privilegios:privilegios,
                                              setPrivilegios:setPrivilegios,
                                              modalShow:modalShow,
                                              setModalShow:setModalShow,
                                              loadingStatus:loadingStatus,
                                              setLoadingStatus:setLoadingStatus,
                                          }}>
              {loadingStatus?<>
                <div className="App-header">
                  <Spinner animation="grow" />
                </div>
              </>:false}
              <div className={loadingStatus?"transition":"transition transition-active"}>
                {user.token===undefined?<>
                  <Out/>
                </>:user.token!==undefined?<>
                  <In/>
                </>:false}
              </div>
            </StateContext.Provider>
          </Div100vh>
}

export default App;
