import React, { useState, useEffect, PureComponent } from 'react';
import StateContext from '../../helpers/ContextState';
import Alert from 'react-bootstrap/Alert';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { NavLink, useHistory } from "react-router-dom";

import { PostAsync } from '../../helpers/Functions';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer
// } from "recharts";

const App = () => {

  const [data, setData] = useState({});
  const [vendors, setVendors] = useState([]);
  const [usuarioTipo, setUsuarioTipo] = useState([]);
  const [anuncio, setAnuncio] = useState(0);
  const context = React.useContext(StateContext);
  const [productos, setProductos] = useState([]);
  const [compras, setCompras] = useState([]);

  useEffect(() => {
    let mounted = true
    getInit(mounted)
    return function cleanup() {
      mounted = false
    }
  }, [])

  const getInit = () => {

    // context.setModalShow({  show:true,
    //   message:  <div className="">
    //               <div className="mb-3 text-start"> <strong>Fenixpuntonet informa: 13/04/2023</strong> <br></br>
    //                                     Últimas actualizaciones de Asocolcarnes: <br></br>
    //                                     <br></br>
    //                                     <strong>1.</strong>Las ventas en tipo TOTAL o PARCIALES ya se pueden hacer correctamente<br></br>
    //                                     <br></br>
    //                                     <strong>2.</strong> Las ventas que se hagan por Kgr ya sean venta TOTAL o venta PARCIAL ya tendran marcas relacionadas y podran ver que marcas se vendieron y de que fechas se vendieron en su respectivo detalle <br></br>
    //                                     <br></br>
    //                                     <strong>3.</strong>Eliminar una venta ya devuelve el inventario<br></br>
    //                                     <br></br>
    //                                     <strong>4.</strong>En la venta se podra ver el estado del prodcuto si ya se vendio o no se ha vendido<br></br>
    //                                     </div>
    //               <div className="text-certer btn btn-primary mt-3" onClick={()=> { context.setModalShow({  show:false,message:""}); } }>Aceptar</div>
    //             </div>
    // })
    PostAsync("Inventario", "AlertasComprasProveedores", {}, { ...context }).then((response) => {
      // console.log(response.data);
      setVendors(response.data)
    })

    PostAsync("Inventario", "ResumenInicio", {}, { ...context }).then((response) => {
      console.log(response);
      setProductos(response.productos)
    })

    PostAsync("Inventario", "Resumen", {}, { ...context }).then((response) => {
      setUsuarioTipo(response.tipoUsuario)
      if (response.data !== undefined && response.data !== null) {
        let responseData = response.data.graph_products;

        responseData.map((val) => {
          console.log(val);
          if (val.name.length > 14) {
            val.name = val.name.slice(0, 6);
            return val
          }

          return val

        })

        setData(response.data)
      }
    })
  }


  return (
    usuarioTipo == "6" || usuarioTipo == "2"  ? <div className="min-height400 row">
    <div className="col-12">
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th>#</th>
            <th>PRODUCTO</th>
            <th className='col-3 text-center'>GRAFICOS</th>
          </tr>
        </thead>
        <tbody>
          {
            productos.map((row, key) => {
              console.log(row);
              return <tr key={key}>
                <td>{key + 1}</td>
                <td>{row.producto}</td>
                <td className='text-center'> <NavLink to={"/Resumen/Graficos/" + row.producto + "/" + row.op_inventario_items_id} className="cursor-pointer bg-warning text-center rounded"><span style={{ color: 'black' }} className="iconify" data-icon="mdi-chart-line"></span></NavLink></td>
              </tr>
            })
          }

        </tbody>
      </table>
    </div>
    {vendors && vendors.map((val) => {
      return <Alert key="warning" variant="warning">
        No se le realizan compras a {val.proveedor} desde hace {val.semanas_a_hoy} semanas
      </Alert>
    })}
  </div> : "" 
  );
}
export default App
