import React,{useState,useEffect} from 'react';
import { useParams  } from "react-router-dom";
import {PostAsync} from '../../helpers/Functions';
import StateContext from '../../helpers/ContextState';
import Comentarios from '../comentarios';

const App=()=>{
  const params              =   useParams();
  const context             =   React.useContext(StateContext);
  const [reset, setReset]   =   useState(false);

  useEffect(() => {
    if (reset) {
      setReset(false)
    }
  },[reset])

  const handleSendInvitation=()=>{
    PostAsync("Users","SetSendInvitation",{...params},{...context}).then((response) => {
        
    })
  }
  return  <div className="p-3 row">
            <div className="btn btn-primary btn-sm" style={{marginTop:'30px'}} onClick={handleSendInvitation}>Enviar accesos</div>
            <div><b>Historial de accesos enviados</b> </div>
            {!reset?<Comentarios id={"Invitacion"+params.id}/>:false}
          </div>
}
export default App
