import React,{useState,useEffect} from 'react';
import Select from "./Select"
import { NavLink } from "react-router-dom";
const App=({name,title,inputs,setInputs,data,selectDefault,url})=>{
  const [selects, setSelects] = useState([]);

  const onChangeCustom=(e)=>{
    if (e.target.value==='') {
      return false
    }
    let languages = document.getElementById(e.target.name);
    let string    = ""
    if (languages) {
      string=languages.selectedOptions[0].text
    }
    let selection = {...inputs}
        if (!selection[name]) {
          selection[name] = []
        }
        selection[name].push({
          name:name,
          value:e.target.value,
          label:string,
        })
        setInputs(selection)
    e.target.value=''    
  }

  const handleDelete=(row_)=>{
    let inputs_ = []
    if (inputs[name] && inputs[name].length>0) {
      inputs[name].map((row,key)=>{
        if (row.value!==row_.value) {
          return inputs_.push(row)
        }
      })
    }
    setInputs({...inputs,[name]:inputs_})
  }

  return  <div className="row">
            <div className="col-12">
              <Select
                  selectDefault={selectDefault}
                  name={name}
                  data={data}
                  id={name}
                  className="form-control"
                  onChange={onChangeCustom}
              />
            </div>
            <div className="col">
              <div className="form-control-label">
                {inputs[name] && inputs[name].length>0?<>
                  <span className="me-2">{title}</span>
                  {inputs[name].map((row,key)=>{
                    return  <span key={key} className="badge badge-secondary me-1 mb-2">
                              {row.label} <span className="p-2 cursor-pointer" onClick={()=>handleDelete(row)}>X</span>
                            </span>
                  })}
                </>:url!==undefined?<NavLink className="form-control-label" to={url}>{title}</NavLink>:title}
              </div>
            </div>
          </div>
}
export default App
