const  App = (props) =>{
  return  <>
            <div className="d-flex flex-row-reverse">
                <nav aria-label="Programandoweb, siempre lo hacemos bien">
                  <ul className="pagination">
                    {
                      props.paginas.map((row,key)=>{
                        let thisPage  = key+1
                        return  <li key={key} className={thisPage===props.paginaActual.label?"page-item active":"page-item "} onClick={()=>{props.setPaginaActual(row) }}>
                                  <a className={thisPage===props.paginaActual.label?"page-link   mr-1":"page-link mr-1"} href={"#"+row.label}>{row.label}</a>
                                </li>
                      })
                    }
                  </ul>
                </nav>
            </div>
          </>
}

export default App
