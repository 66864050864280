import React, { useState, useEffect } from "react";
import StateContext from "../../helpers/ContextState";
import { Inputs, PostAsync } from "../../helpers/Functions";
import Store from "../../helpers/Store";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { NavLink, useHistory } from "react-router-dom";
import Marcas from "../../components/Marcas";
import ProductosVendidos from "../../components/ProductosVendidos";
import Comentarios from "../comentarios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SelectorGenerico from "../../components/SelectorGenerico";
import InputSimple from "../../components/InputSimple";
import BotonVolver from "../../components/BotonVolver";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

const App = (props) => {
  let history = useHistory();
  const params = useParams();
  const context = React.useContext(StateContext);
  const [param, setParam] = useState({});
  const [inputs, setInputs] = useState({});
  const [tab, setTab] = useState(0);
  const [tipoUsuario, setTipoUsuario] = useState("");

  useEffect(() => {
    setTipoUsuario(params.id);
    setInputs({
      usuario_id: "",
      tipo_usuario_id: "",
      parent_id: "",
      foto: "",
      nombres: "",
      apellidos: "",
      telefono: "",
      login: "",
      password: "",
      email: "",
      email2: "",
      nombre_contacto: "",
      cedula: "",
      token: "",
      confirm: "",
      codigo_sms: "",
      estatus: "",
      tipo_identificacion: "",
      nro_documento: "",
      fecha_expedicion: "",
      celular: "",
      celular2: "",
      estado_civil: "",
      direccion: "",
      sector: "",
      barrio: "",
      departamento: "",
      municipio: "",
      session_time: "",
      ultimo_login: "",
      es_asociado: "",
      es_iva: "",
      es_pago: "",
      es_tipopago: "",
      tipo_cuenta: "",
      nro_cuenta: "",
      titular_cuenta: "",
      doc_cuenta: "",
      banco: "",
      comentario: "",
      ma_naturaleza_id: "",
      ma_periodicidad_id: "",
      reteiva: "",
      reteICA: "",
      retefuente: "",
    });
    // console.log("Tipo de usuario ID:", tipo_usuario_id);
  }, []);
  // useEffect(() => {

  //   // console.log("Tipo de usuario ID:", tipo_usuario_id);
  // }, [params.id]);

  useEffect(() => {
    setInputs({ ...inputs, ...param });
    console.log(inputs);
  }, [param]);

  // const getInit = () => {

  //   PostAsync("Users", "GetUsuario", { id: params.id }, { ...context }).then((response) => {
  //     setInputs(response.data)
  //     setParam(response.data)
  //     console.log("param",response.data);
  //   })
  // }

  // useEffect(() => {
  //   let mounted = true
  //   if (params.id !== 0) {
  //     getInit(mounted)
  //   }
  //   getInit2(mounted)
  //   return function cleanup() {
  //     mounted = false
  //   }
  // }, [])

  // const getInit2 = (mounted, uid) => {

  //   if (params.subId === undefined) {
  //     return false
  //   }

  //   const tipo_usuario_id = localStorage.getItem('userType');
  //   PostAsync("Users", "TypeUser", { tipo_usuario_id: tipo_usuario_id }, { ...context }).then((response) => {
  //     if (response.data !== undefined && mounted) {
  //       context.setExtraPosition("/ " + response.data)
  //       setTipoUsuario(response.data)
  //     }
  //   })
  // }

  // const getInit = (mounted, uid) => {
  //   PostAsync("Users", "GetUsuario", { uid: (uid === undefined) ? params.id : uid, tipo_usuario_id: params.subId }, { ...context })
  //     .then((response) => {
  //       if (response.data !== undefined && mounted) {
  //         setInputs(response.data)
  //         if (response.data) {
  //           setParam(response.data)
  //         }

  //         if (response.data.tipo_usuario_id === undefined) {
  //           return false
  //         }

  //         PostAsync("Users", "TypeUser", { tipo_usuario_id: response.data.tipo_usuario_id }, { ...context }).then((response) => {
  //           if (response.data !== undefined && mounted) {
  //             context.setExtraPosition("/ " + response.data)
  //             setTipoUsuario(response.data)
  //           }
  //         })
  //       }
  //     })
  // }

  const onChange = (e) => {
    Inputs(e, inputs, setInputs);
    // console.log(inputs);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let inputs_ = { ...inputs };
    inputs_.tipo_usuario_id = tipoUsuario;
    // console.log("inputs_", inputs_);

    PostAsync("Users", "SetUsuario", inputs_, { ...context }).then(
      (response) => {
        if (response) {
          history.push(
            "/Usuarios/Usuario/Edit/" +
              tipoUsuario +
              "/" +
              response.uid +
              "/" +
              response.subId
          );
          // window.location.reload();
        }
      }
    );
  };

  return (
    <div className="p-3" style={{ marginTop: "45px" }}>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="profile" title="Datos Personales"> 
          <Row className="mb-4">
            <form onSubmit={onSubmit}>
              <Row className="mb-4">
                <Col xs="12" md="6" className="h4">
                  Usuario tipo:{" "}
                  <b>
                    {tipoUsuario == "3"
                      ? "Proveedor"
                      : tipoUsuario == "4"
                      ? "Cliente"
                      : tipoUsuario == "2"
                      ? "Administrador"
                      : "Subadministrador"}
                  </b>
                </Col>
              </Row>
              <div className="h4">Datos Contacto</div>
              <Row className="mb-4">
                <Col xs="12" md="2">
                  <InputSimple
                    className="form-control"
                    type="text"
                    name="nombre_contacto"
                    defaultValue={inputs.nombre_contacto}
                    placeholder="Nombre completo"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple
                    className="form-control"
                    type="text"
                    name="celular"
                    defaultValue={inputs.celular}
                    placeholder="Celular"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="3">
                  <InputSimple
                    className="form-control"
                    type="text"
                    name="telefono"
                    defaultValue={inputs.telefono}
                    placeholder="Teléfono"
                    onChange={onChange}
                  />
                </Col>
                <Col
                  xs="12"
                  md={
                    parseInt(inputs.tipo_usuario_id) === 3 ||
                    params.subId === "3"
                      ? 3
                      : 3
                  }
                >
                  <InputSimple
                    className="form-control"
                    type="email"
                    name="email"
                    defaultValue={inputs.email}
                    placeholder="Email de contacto"
                    onChange={onChange}
                  />
                </Col>

                <>
                  <Col xs="12" md="4">
                    <InputSimple
                      className="form-control"
                      required={true}
                      type="email"
                      name="email2"
                      defaultValue={inputs.email2}
                      placeholder="Email Factura electrónica"
                      onChange={onChange}
                    />
                  </Col>
                </>
              </Row>
              <Row className="mb-4">
                <Col xs="12" md="12">
                  <h4>Tipo de persona</h4>
                  <SelectorGenerico
                    modelo="Maestros"
                    metodo="get_tablas_maestras"
                    tabla="ma_naturaleza"
                    param={param}
                    name="ma_naturaleza_id"
                    setParam={setParam}
                  />
                </Col>
                <Col xs="12" md="4">
                  <InputSimple
                    className="form-control"
                    required={true}
                    defaultValue={inputs.nombres}
                    type="text"
                    name="nombres"
                    placeholder={
                      parseInt(inputs.ma_naturaleza_id) === 2
                        ? "Nombres ó Razón Social"
                        : "Nombres "
                    }
                    onChange={onChange}
                  />
                </Col>
                {
                  <Col xs="12" md="4">
                    <InputSimple
                      className="form-control"
                      required={true}
                      defaultValue={inputs.apellidos}
                      type="text"
                      name="apellidos"
                      placeholder={
                        parseInt(inputs.ma_naturaleza_id) === 2
                          ? "Persona Contacto"
                          : "Apellidos"
                      }
                      onChange={onChange}
                    />
                  </Col>
                }
                <Col xs="12" md="4">
                  <InputSimple
                    className="form-control"
                    required={true}
                    type="text"
                    name="nro_documento"
                    defaultValue={inputs.nro_documento}
                    placeholder={
                      parseInt(inputs.ma_naturaleza_id) === 2
                        ? "NIT"
                        : "Documento"
                    }
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="12">
                  <h4>Estado del usuario</h4>
                  <SelectorGenerico
                    modelo="Maestros"
                    required={true}
                    metodo="get_tablas_maestras"
                    tabla="ma_estatus_usuarios"
                    param={param}
                    name="estatus"
                    setParam={setParam}
                  />
                </Col>
              </Row>

              <Row className="mb-4">
                <Col xs="12" md="3">
                  <InputSimple
                    className="form-control"
                    required={true}
                    type="text"
                    name="direccion"
                    defaultValue={inputs.direccion}
                    placeholder="Dirección"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple
                    className="form-control"
                    required={true}
                    type="text"
                    name="sector"
                    defaultValue={inputs.sector}
                    placeholder="Sector"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple
                    className="form-control"
                    required={true}
                    type="text"
                    name="barrio"
                    defaultValue={inputs.barrio}
                    placeholder="Barrio"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="2">
                  <InputSimple
                    className="form-control"
                    required={true}
                    type="text"
                    name="departamento"
                    defaultValue={inputs.departamento}
                    placeholder="Departamento"
                    onChange={onChange}
                  />
                </Col>
                <Col xs="12" md="3">
                  <InputSimple
                    className="form-control"
                    required={true}
                    type="text"
                    name="municipio"
                    defaultValue={inputs.municipio}
                    placeholder="Ciudad"
                    onChange={onChange}
                  />
                </Col>
              </Row>
              {tipoUsuario === "3" || tipoUsuario === "4" ? (
                <>
                  <Row className="mb-4">
                    <Col xs="12" md="3">
                      <h4>ReteIva</h4>
                      <SelectorGenerico
                        modelo="Maestros"
                        required={true}
                        metodo="get_tablas_maestras"
                        tabla="ma_sino"
                        param={param}
                        name="reteiva"
                        setParam={setParam}
                      />
                    </Col>
                    <Col xs="12" md="3">
                      <h4>ReteICA</h4>
                      <SelectorGenerico
                        modelo="Maestros"
                        required={true}
                        metodo="get_tablas_maestras"
                        tabla="ma_sino"
                        param={param}
                        name="reteICA"
                        setParam={setParam}
                      />
                    </Col>
                    <Col xs="12" md="3">
                      <h4>ReteFuente</h4>
                      <SelectorGenerico
                        modelo="Maestros"
                        required={true}
                        metodo="get_tablas_maestras"
                        tabla="ma_sino"
                        param={param}
                        name="retefuente"
                        setParam={setParam}
                      />
                    </Col>
                    <Col xs="12" md="3">
                      <h4>IVA</h4>
                      <SelectorGenerico
                        modelo="Maestros"
                        required={true}
                        metodo="get_tablas_maestras"
                        tabla="ma_sino"
                        param={param}
                        name="es_iva"
                        setParam={setParam}
                      />
                    </Col>
                  </Row>

                  <div className="h4">Datos Pagos</div>
                  <Row className="mb-4 mb-2 border">
                    <Col xs="12" md="12">
                      Periodicidad de pagos
                      <SelectorGenerico
                        modelo="Maestros"
                        required={true}
                        metodo="get_tablas_maestras"
                        tabla="ma_periodicidad"
                        param={param}
                        lg={3}
                        name="ma_periodicidad_id"
                        setParam={setParam}
                      />
                    </Col>
                    <Col xs="12" md="12">
                      Tipo de pago
                      <SelectorGenerico
                        modelo="Maestros"
                        required={true}
                        metodo="get_tablas_maestras"
                        tabla="ma_tipopago"
                        param={param}
                        lg={3}
                        name="es_tipopago"
                        setParam={setParam}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xs="12" md="6" className="border">
                      Asociado
                      <SelectorGenerico
                        modelo="Maestros"
                        required={true}
                        metodo="get_tablas_maestras"
                        tabla="ma_sino"
                        param={param}
                        name="es_asociado"
                        setParam={setParam}
                      />
                    </Col>
                    <Col xs="12" md="6" className="border">
                      Pago
                      <SelectorGenerico
                        modelo="Maestros"
                        required={true}
                        metodo="get_tablas_maestras"
                        tabla="ma_pago"
                        param={param}
                        name="es_pago"
                        setParam={setParam}
                      />
                    </Col>
                  </Row>

                  {param.es_tipopago != 3 && param.es_tipopago != 4 ? (
                    <Row className="mb-4 border">
                      <Col xs="12">Datos de cuenta bancaria</Col>
                      <Col xs="12">
                        <SelectorGenerico
                          modelo="Maestros"
                          required={true}
                          metodo="get_tablas_maestras"
                          tabla="ma_tipo_cuentas_bancarias"
                          param={param}
                          name="tipo_cuenta"
                          setParam={setParam}
                        />
                      </Col>
                      <Col xs="12" md="3">
                        <InputSimple
                          className="form-control"
                          required={true}
                          type="text"
                          name="nro_cuenta"
                          defaultValue={inputs.nro_cuenta}
                          placeholder="Numero de cuenta"
                          onChange={onChange}
                        />
                      </Col>
                      <Col xs="12" md="3">
                        <InputSimple
                          className="form-control"
                          required={true}
                          type="text"
                          name="banco"
                          defaultValue={inputs.banco}
                          placeholder="Banco"
                          onChange={onChange}
                        />
                      </Col>
                      <Col xs="12" md="3">
                        <InputSimple
                          className="form-control"
                          required={true}
                          type="text"
                          name="titular_cuenta"
                          defaultValue={inputs.titular_cuenta}
                          placeholder="Titular cuenta"
                          onChange={onChange}
                        />
                      </Col>
                      <Col xs="12" md="3">
                        <InputSimple
                          className="form-control"
                          required={true}
                          type="text"
                          name="doc_cuenta"
                          defaultValue={inputs.doc_cuenta}
                          placeholder="documento cuenta"
                          onChange={onChange}
                        />
                      </Col>
                      <Col xs="12" className="mt-3">
                        <SelectorGenerico
                          modelo="Maestros"
                          required={true}
                          metodo="get_tablas_maestras"
                          tabla="ma_tipo_identificacion"
                          param={param}
                          lg={3}
                          name="tipo_identificacion"
                          setParam={setParam}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                console.log("no")
              )}
              {
                <Row className="mb-4">
                  <Col xs="12" md="12">
                    <InputSimple
                      className="form-control"
                      type="text"
                      name="comentario"
                      defaultValue={inputs.comentario}
                      placeholder="Comentario"
                      onChange={onChange}
                    />
                  </Col>
                </Row>
              }

              <Row className="justify-content-center">
                <Col xs="2">
                  <div className="d-grid gap-2">
                    <Button variant="primary" type="submit">
                      Guardar
                    </Button>
                  </div>
                </Col>
                <Col xs="2">
                  <div className="d-grid gap-2">
                    <NavLink
                      className="btn btn-secondary"
                      to={"/Usuarios/Lista"}
                    >
                      Cancelar
                    </NavLink>
                  </div>
                </Col>
              </Row>
            </form>
          </Row>
        </Tab>
        {parseInt(inputs.tipo_usuario_id) === 3 && params.id !== 0 && (
          <Tab eventKey="Marcas" title="Marcas">
            <Row className="mb-4">
              {parseInt(inputs.tipo_usuario_id) === 3 ? (
                <>
                  <Row className="mt-3 mb-3">
                    <Col>
                      <Marcas id={params.id} uid={inputs.token} />
                    </Col>
                  </Row>
                </>
              ) : (
                false
              )}
            </Row>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default App;
