import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import StateContext from '../../helpers/ContextState';
import { useParams  } from "react-router-dom";
import Config from '../../helpers/Config';
import BotonVolverHome from '../../components/BotonVolverHome';


import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{
  const params              =   useParams();
  const context             =   React.useContext(StateContext);
  const [param, setParam]   =   useState({tipo_usuario_id:2,uid:params.uid,modulo:'Productos'});

  let tds       =   [
    {
      label:"#",
      value:"numColum",
      className:"col text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Productos",
      value:"producto",
      className:"col-3 text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Precio asociado",
      value:"precio_asociado",
      className:"col-2 text-left",
      classNameHeader:"col text-left",
    },   
    {
      label:"Precio no asociado",
      value:"precio_no_asociado",
      className:"col-3 text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Iva",
      value:"iva",
      className:"col-2 text-left",
      classNameHeader:"col text-left",
    },
    // {
    //   label:"Inventario",
    //   value:"peso_total",
    //   className:"col-12 col-sm text-end",
    //   classNameHeader:"col-3 text-end",
    // },
    {
      label:"Medida",
      value:"unidad_medida",
      className:"col-12 col-sm text-center",
      classNameHeader:"col-1 text-center",
    },
    {
      label:"Acciones",
      value:"events",
      className:"col-12 col-sm-1 text-center",
      classNameHeader:"col-1 text-center",
      icons:[
              {
                label:"Resumen",
                NavLink:NavLink,
                icon: <FontAwesomeIcon icon={faEdit}/>,
                url:params.uid===undefined?"/Inventario/ListadoProductos/Edit/":"/Inventario/ListadoProductos/Edit2/"+params.uid+"/"
              },
            ]
    },
    {
      label:"Estado",
      value:"estatus_pruduct",
      id: "op_inventario_items_id",
      className:"col-12 col-sm text-center",
      classNameHeader:"col-1 text-center",
    },
    {
      label:"Eliminar",
      value:"delete_pruduct",
      id: "op_inventario_items_id",
      className:"col-12 col-sm text-center",
      classNameHeader:"col-1 text-center",
    },
  ]

  return  <div className="container-fluid">
            <div className="row">
              <div className="col">
                <Table
                  filterCustom={true}
                  downloadExcel={Config.ConfigApirest+"images/inscribir_productos_vacio.xlsx"}
                  // uploadExcel="ListadoProductos/Upload"
                  uploadExcel={false}
                  toPDF={Config.ConfigApirest+'PDF/Informes/ListadoProductos/'+context.user.token}
                  toAdd="ListadoProductos/AgregarProducto"
                  add={NavLink}
                  td={tds}
                  modelo="Inventario"
                  metodo="get_items"
                  limit="5"
                  params={param}
                />
              </div>
            </div>
          </div>
}
export default App
