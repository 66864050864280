import React,{useState,useEffect} from 'react';
import {PostAsync,Segment,Inputs} from '../../helpers/Functions';
import { useParams, useHistory  } from "react-router-dom";
import Input from '../../components/Inputs';
import Select from '../../components/Select';
import StateContext from '../../helpers/ContextState'
import { NavLink } from "react-router-dom";
import Table from 'react-bootstrap/Table'
import Comentarios from '../comentarios';


let proveedores = []

const App=()=>{
  let history               =   useHistory();
  const params              =   useParams();
  let   url                 =  (params.id!==undefined)?{modulo:Segment(4),submodulo:Segment(3)}:{modulo:Segment(3),submodulo:Segment(2)}
  const context                             =   React.useContext(StateContext);
  const [inputs, setInputs]                 =   useState({
                                                            marca:"",
                                                            proveedor_id:"",
                                                            precio_asociado:"",
                                                            precio_no_asociado:"",
                                                            iva:"",
                                                            observacion:"",
                                                          });
  const [historial, setHistorial]           =   useState([]);
  const [data, setData]     =   useState([]);
  const [data4, setData4]   =   useState([]);
  const [proveedores100, setProveedores100]   =   useState([]);
  const [plantas, setPlantas]   =   useState([]);
  const [reset, setReset]   =   useState(false);

  useEffect(() => {
    if (reset) {
      setReset(false)
    }
  },[reset])

  const onSubmit=(e)=>{
    e.preventDefault()
    let data        =   {...inputs}
        data.id     =   data.token
        data.fotos  =   JSON.stringify(data.fotos)
        data.files  =   JSON.stringify(data.files)
        delete(data.token)
    PostAsync("Inventario","Set_Marca",data,{...context}).then((response)=>{
      setReset(true)
      if (!response) {
        return false
      }
      if (params.subId!==undefined) {
        return history.push("/Inventario/Productos/"+params.subId);
      }else {
        return history.push("/Inventario/ListadoMarcas");
      }
    })
  }

  const callbackOnSubmit=(data)=>{

    if (data!==undefined && data.message!==undefined && data.message.label!==undefined) {
      context.setModalShow({  show:true,
                              size:"sm",
                              message:  <div className="text-center">
                                          <div className="mb-3">{data.message.label}</div>
                                          <div className="btn btn-primary mt-3" onClick={()=> { context.setModalShow({  show:false,message:"",size:"sm", }); } }>Cerrar</div>
                                        </div>
                            })
      setTimeout(function(){ context.setModalShow({  show:false, }); history.goBack(); }, 3000);
    }
  }

  const onChange=(e)=>{
    Inputs(e,inputs, setInputs);
  }

  const getInit=()=>{
    PostAsync("Inventario","GetMarca",{...params},{...context}).then((response)=>{
      if (response.data!==undefined && response.data!==null) {
        setInputs(response.data)
      }
      if (response.data!==undefined) {
        setProveedores100(response.proveedores)
        setPlantas(response.plantas)
      }
    })
  }

  useEffect(() => {
    if (params.id!=="0") {
      context.setLoadingStatus(true)
    }
    getInit()
  },[])

  return <div className="container">
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={onSubmit}>
                      <div className="row pb-2">
                        <div className="col-12 col-sm-2 mb-2">
                          <Select
                              defaultValue={inputs.estatus}
                              title="Estado"
                              selectDefault="Estado"
                              name="estatus"
                              data={[{label:"activo",value:1},{label:"inactivo",value:0}]}
                              className="form-control"
                              onChange={onChange}
                              required={true}
                          />
                        </div>
                        <div className="col-12 col-sm-2 mb-2">
                          <Input
                              defaultValue={inputs.marca}
                              title="Marca"
                              placeholder="Marca"
                              type="text"
                              name="marca"
                              className="form-control"
                              onChange={onChange}
                              required={true}
                              url={"/Inventario/ListadoMarcas"}
                          />
                        </div>
                        <div className="col-12 col-sm-3 mb-2">
                          <Select
                              defaultValue={inputs.proveedor_id}
                              title="Proveedor"
                              selectDefault="Proveedor"
                              name="proveedor_id"
                              data={proveedores100}
                              className="form-control"
                              onChange={onChange}
                              required={true}
                              url={"/Usuarios/Lista"}
                          />
                        </div>
                        <div className="col-12 col-sm-3 mb-2">
                          <Select
                              defaultValue={inputs.planta_id}
                              title="Planta"
                              selectDefault="Planta"
                              name="planta_id"
                              data={plantas}
                              className="form-control"
                              onChange={onChange}
                              required={true}
                              url={"/Inventario/Listado/ListadoPlantas"}
                          />
                        </div>
                        <div className="col-12 col-sm-2 mb-2">
                          <Input
                              defaultValue={inputs.fecha_actualizacion}
                              title="Fecha inicio"
                              placeholder="Fecha inicio"
                              type="date"
                              name="fecha_actualizacion"
                              className="form-control"
                              onChange={onChange}
                              required={true}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center pb-2">
                        <div className="col-12 col-sm-2 mb-2">
                          <button type="submit" className="btn btn-primary col-12">Guardar</button>
                        </div>
                        <div className="col-12 col-sm-2 mb-2">
                          <NavLink className="btn btn-secondary col-12" to={params.subId!==undefined?"/Inventario/Productos/"+params.subId:"/Inventario/ListadoMarcas"}>Cancelar</NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {!reset?<Comentarios id={params.id}/>:false}
            </div>
          </div>

        </div>

}

export default App
