import React,{useState,useEffect} from 'react';
import StateContext from '../../helpers/ContextState';
import {Inputs,PostAsync} from '../../helpers/Functions';
import { useParams  } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { NavLink,useHistory} from "react-router-dom";
import Plantas from '../../components/Plantas';
import Comentarios from '../comentarios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from '../../components/Select';
import Input from '../../components/Inputs';
import Config      from '../../helpers/Config'


//Config.ConfigApirest

const App=(props)=>{
  let history               =   useHistory();
  const params              =   useParams();
  const context             =   React.useContext(StateContext);

  const [data, setData]     =   useState([]);
  const [btn, setBtn]       =   useState(false);


  useEffect(() => {
    let mounted = true
    if (params.id!==0) {
      getInit(mounted)
    }
    return function cleanup() {
      mounted = false
    }
  },[])

  const getInit=(mounted,uid)=>{
    PostAsync("Inventario","get_compra",{...params},{...context}).then((response) => {
      if (response.data!==undefined && mounted) {
        setData(response.data)
        setBtn(response.data.token)
      }
    })
  }

  return      <div className="p-3">
                <form >
                  <Row className="mb-4">
                    <Col xs="12" className="text-bold mb-3 h1">
                      <b>{data.proveedor}</b>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>TIQUETE</b>
                      <div>{data.op_inventario_items_inventario_id}</div>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>FECHA</b>
                      <div>{data.fecha}</div>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>HORA</b>
                      <div>{data.hora}</div>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>MARCA</b>
                      <div>{data.marca}</div>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>CONTRAM</b>
                      <div>{data.contram}</div>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>SEXO</b>
                      <div>{data.sexo}</div>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>CANAL</b>
                      <div>-</div>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>CANAL2</b>
                      <div>-</div>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>PRECIO</b>
                      <div>{data.precio_string}</div>
                    </Col>
                    <Col xs="12" md="3" className="mb-2">
                      <b>PESO PIEL</b>
                      <div>{data.peso}</div>
                    </Col>
                    <Col xs="12" className="mt-3">
                      <div className="btn btn-secondary me-2" onClick={history.goBack}>Volver</div>
                      <a target="_blank" className="btn btn-warning" href={Config.ConfigApirest+"PDF/Inventario/Compra/"+btn+"/"+context.user.token}>PDF</a>
                    </Col>
                  </Row>
                </form>
              </div>

}

export default App
