import React, { useState, useEffect, useCallback } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import StateContext from "../helpers/ContextState";
import { PostAsync } from "../helpers/Functions";

const App = ({
  placeholder,
  className,
  modelo,
  metodo,
  extra,
  autoLoad,
  inputs,
  setInputs,
  name,
}) => {
  const context = React.useContext(StateContext);
  const [data, setData] = useState([]);
  const [reset, setReset] = useState(false);
  const [searchMarcas, setSearchMarcas] = useState([]);

  const handleOnSearch = useCallback(
    (string, results) => {
      // Filter data with string
      const filteredData = data.filter((item) =>
        item.name.toLowerCase().includes(string.toLowerCase())
      );
      // Update the suggestions list
      setSearchMarcas(filteredData);
      console.log(searchMarcas);
    },
    [data]
  );

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset, data]);

  useEffect(() => {
    if (autoLoad) {
      PostAsync(
        modelo,
        metodo,
        { string: "", extra: extra ? extra : "" },
        { ...context }
      ).then((response) => {
        if (response.marcas !== undefined) {
          setData(response.marcas);
        }
        if (response.data !== undefined) {
          setData(response.data);
        }
      });
    }
  }, []);

  useEffect(() => {
    setSearchMarcas(data);
  }, [data]);

  const handleOnHover = (result) => {};

  const handleOnSelect = (item) => {
    if (item.estatus === "0") {
      return handleDisabled();
    }

    let input = { ...inputs };
    input[name] = item.name; //item.id
    input[name + "_string"] = item.name;
    input[name + "_id"] = item.id;
    input[name + "_id2"] = item.id2;
    input[name + "_name2"] = item.name2;
    setInputs(input);
  };

  const handleOnFocus = () => {};

  const handleDisabled = () => {
    setReset(true);
    return context.setModalShow({
      show: true,
      size: "sm",
      message: (
        <div className="text-center">
          <div className="mb-3">
            La marca está desactivada, debe cambiar el estatus en el módulo
            marcas para poder continuar.
          </div>
          <div
            className="btn btn-primary mt-3"
            onClick={() => {
              context.setModalShow({ show: false, message: "", size: "sm" });
            }}
          >
            Cerrar
          </div>
        </div>
      ),
    });
  };

  const formatResult = (item) => {
    let result = item.includes("(Inactiva)");

    return (
      <>
        {!result ? (
          <>
            <div style={{ display: "block", textAlign: "left" }}>{item}</div>
          </>
        ) : (
          <>
            <div className="bg-warning" onClick={handleDisabled}>
              {item}
            </div>
          </>
        )}
      </>
    );
    return item;
  };
  return (
    <>
      {!reset ? (
        <div style={{ position: "relative" }}>
          <ReactSearchAutocomplete
            className={className ? className : "form-control"}
            placeholder={placeholder}
            items={searchMarcas}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            // formatResult={formatResult}
          />
        </div>
      ) : (
        false
      )}
    </>
  );
};
export default App;
