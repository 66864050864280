import React,{useEffect,useState} from 'react';
import StateContext from '../helpers/ContextState';
import Store from '../helpers/Store';
import Nav from '../components/NavBar';
import NoComponent from '../components/NoComponent';
import Position from '../components/Position';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BotonVolver from '../components/BotonVolver';

import {
  BrowserRouter as Router,
  Route,
  NavLink
} from "react-router-dom";

const App=()=>{
  const context        = React.useContext(StateContext);
  const [mod, setMod]  = useState(false);
 
  /*quita el loading*/
  useEffect(() => {
    context.loading(false)
  },[context])

  useEffect(() => {
    if (Store.get("link").label===undefined) {
      Store.set("link",mod)
    }else if (Store.get("link").label!==undefined && !mod) {
      setMod(Store.get("link"))
    }else {
      Store.set("link",mod)
    }
  },[mod])

  const out=()=>{
    Store.clear()
    context.setUser({})
  }

  return  <StateContext.Provider value={{...context}}>
              <Router>
                <Nav user={context.user} privilegios={context.privilegios} data={context.constants} NavLink={NavLink} setMod={setMod} out={out}/>
                <Container fluid className="pe-4 ps-4">
                  <Row>
                    <Col className="card mt-3 shadow-sm  ">
                      <div className="p-1">
                        <Position />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="card mt-1 mb-3 pt-3 pb-3 shadow-sm">
                    {/* {console.log("context.constants.Modulos",context.constants.Modulos)} */}
                      {context.constants.Modulos.map((row,key)=>{
                        if (row.items===undefined) {
                          return  <Route exact path={row.url} key={key}>
                                    {row.component!==undefined?<>
                                      {<row.component me={row}/>}
                                    </>:<NoComponent/>}
                                  </Route>
                        }else {
                          return row.items.map((row2,key2)=>{
                            if (row2.items!==undefined) {
                              return row2.items.map((row3,key3)=>{
                                return  <Route exact path={row3.url} key={key3}>
                                          {row3.component!==undefined?<>
                                            {<row3.component me={row3}/>}
                                          </>:<NoComponent/>}
                                        </Route>
                              })
                            }else if(row2.items===undefined){                              
                              return <Route exact path={row2.url} key={key2}>
                                        {row2.component!==undefined?<>
                                          <BotonVolver/>
                                          {<row2.component me={row2}/>}
                                        </>:<NoComponent/>}
                                      </Route>
                            }else {
                              return  <Route exact path={row2.url} key={key2}>
                                        {row2.component!==undefined?<>
                                          {<row2.component me={row2}/>}
                                        </>:<NoComponent/>}
                                      </Route>
                            }
                          })
                        }
                      })}
                    </Col>
                  </Row>
                </Container>
              </Router>
          </StateContext.Provider>
}
export default App
