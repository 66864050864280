import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import Plantas from '../../components/Plantas';

import StateContext from '../../helpers/ContextState';
import { useParams  } from "react-router-dom";
import Config from '../../helpers/Config';


import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{
  const params              =   useParams();
  const context             =   React.useContext(StateContext);
  const [param, setParam]   =   useState({tipo_usuario_id:2,uid:params.uid});

  let tds       =   [
    {
      label:"Planta",
      value:"label",
      className:"col text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Dirección",
      value:"direccion",
      className:"col text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Teléfono",
      value:"telefono",
      className:"col text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Email",
      value:"email",
      className:"col text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"Acciones",
      value:"events",
      className:"col-12 col-sm-1 text-center",
      classNameHeader:"col-1 text-center",
      icons:[
              {
                label:"Resumen",
                NavLink:NavLink,
                icon: <FontAwesomeIcon icon={faEdit}/>,
                url:"/Inventario/Plantas/Edit/"
              },
            ]
    },
  ]

  return  <div className="container-fluid">
            <div className="row">
              <div className="col">
                <Plantas />                
                {/* 
                <Table
                  filterCustom={true}
                  toExport={Config.ConfigApirest+'Excel/Informes/ListadoPlantas/token:'+context.user.token}
                  toPDF={Config.ConfigApirest+'PDF/Informes/ListadoPlantas/'+context.user.token}
                  toAdd="/Inventario/Plantas/Edit/0"
                  add={NavLink}
                  td={tds}
                  modelo="Inventario"
                  metodo="get_plantas"
                  limit="5"
                  params={param}
                /> */}
              </div>
            </div>
          </div>
}
export default App
