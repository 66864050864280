import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import SelectorGenerico from '../../components/SelectorGenerico';
import StateContext from '../../helpers/ContextState';
import { PostAsync, format, Inputs } from '../../helpers/Functions';
import { useParams } from "react-router-dom";
import Config from '../../helpers/Config';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Input from '../../components/InputSimple';
import SelectorMultiple from "../../components/SelectorMultiple"


import { faEye, faTrash, faPrint, faFileDownload, faCheck, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pointer } from 'd3';

let basicInputs = false;

const App = (props) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { nComprobante } = useParams();
  const [inputs, setInputs] = useState({});
  const [semanas, setSemanas] = useState([]);
  const [inputs2, setInputs2] = useState({});
  const [marcas, setMarcas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [taxes, setTaxes] = useState([]);
  const [reteiva, setReteiva] = useState(0);
  const [reteica, setReteica] = useState(0);
  const [retefuente, setRetefuente] = useState(0);
  const [baseRetefuente, setBaseRetefuente] = useState(0);
  const [impuestos, setImpuestos] = useState([]);



  // console.log(nComprobante);
  const handleCheck = () => {
    let data_ = []
    let seleccion = selectAll
    let btnPagar_ = [...btnPagar]

    btnPagar.map((row, key) => {
      return row.map((row2, key2) => {
        return btnPagar_[key][key2].pagar = seleccion ? false : true
        //https://apirest.fenixteayuda.com/Inventario/ListadoCompras
        //return  console.log(btnPagar_[key][key2],row2);
      })
      //return console.log(row);
    })

    setBtnPagar(btnPagar_)
    setSelectAll((seleccion) ? false : true)
  }

  useEffect(() => {
    getInit()
  }, [inputs2])

  const onChangeCustom = (e) => {
    Inputs(e, inputs, setInputs)
  }

  const context = React.useContext(StateContext);
  const params = useParams();
  const [data, setData] = useState([]);
  const [historial, setHistorial] = useState([]);

  const [btn, setBtn] = useState(false);
  const [btnPagar, setBtnPagar] = useState([]);
  // const [proveedor, setProveedor]         =   useState({});
  const [proveedor, setProveedor] = useState("");

  const [totalLineas, setTotalLineas] = useState([]);
  const [totalLineasPeso, setTotalLineasPeso] = useState([]);
  const [totalLineasTotal, setTotalLineasTotal] = useState([]);
  const [totalGeneral, setTotalGeneral] = useState({ peso: 0, cantidad: 0, total: 0 });

  useEffect(() => {
    if (params.id) {
      getInit()
    }
  }, [])



  basicInputs = () => {
    PostAsync("Inventario", "ListadoPagoEfectudosDetalle", {
      inTrash: 1,
      fecha: "0000-00-00",
      limit: 0,
      uid: params.id,
      start: 0
    }, context).then(
      (response) => {
        if (response.data && Object.entries(response.data).length > 0) {
          // console.log(response.system);
          setTaxes(response.system);
        }
        if (response.data && response.data.semanas) {
          setSemanas(response.data.semanas)
        }

        if (response.data && response.data.marcas) {
          setMarcas(response.data.marcas)
        }

        if (response.data && response.data.productos) {
          setProductos(response.data.productos)
        }
      }
    )
  }

  useEffect(() => {
    basicInputs()
  }, [])

  const getInit = () => {
    setData([])

    params.id &&
      PostAsync("Pagos", "get_cambios_pago", { id: params.id }, context).then(
        (response) => {
          setHistorial(response.data)
        }
      )

    PostAsync("Pagos", "ListaPagosEfectuadosDetalle", { id: params.id, nComprobante: nComprobante, filtroMultiple: JSON.stringify(inputs2) }, context).then((response) => {
      setImpuestos(response.impuestos);
      // console.log("response.impuestos",response.impuestos)
      if (response.proveedor) {
        setProveedor(response.proveedor);
      }

      if (response.data && Object.entries(response.data).length > 0) {
        //data_.push({label:row[0],items:row[1],uid:row[1].token,key:key,pagar:true})

        let data_ = []
        let totalGeneral_ = {}
        let totalLineas_ = 0
        let totalLineas__ = []

        let totalLineasPeso_ = 0
        let totalLineasPeso__ = []

        let totalLineasTotal_ = 0
        let totalLineasTotal__ = []

        let btnPagar_ = []
        let btnPagar__ = []
        let activeOnBtn__ = false

        Object.entries(response.data).map((row, key) => {
          data_.push({ label: row[0], items: row[1] })

          totalLineas_ = 0
          totalLineasPeso_ = 0
          totalLineasTotal_ = 0
          btnPagar_ = []

          row[1].map((row2, key2) => {
            totalLineas_ += parseFloat(row2.cantidad)
            totalLineasPeso_ += parseFloat(row2.peso)
            totalLineasTotal_ += parseFloat(row2.monto_total_compra)
            btnPagar_.push({ ...row2, uid: row2.token, key: key2, pagar: true })
            activeOnBtn__ = true
          })

          totalLineas__.push(totalLineas_)
          totalLineasPeso__.push(totalLineasPeso_)
          totalLineasTotal__.push(totalLineasTotal_)
          btnPagar__.push(btnPagar_)

        })

        setBtn(activeOnBtn__)
        // console.log(btnPagar__);
        setBtnPagar(btnPagar__);

        setTotalLineas(totalLineas__);
        setTotalLineasPeso(totalLineasPeso__);
        setTotalLineasTotal(totalLineasTotal__);
        setData(data_)
      }
      // if (response.data && response.data.rows) {
      //   let pagar_    =   []
      //   /*voy a colocar los inputs activos por defecto para hacer los pagos*/
      //   response.data.rows.map((row,key)=>{
      //     pagar_.push({...row,uid:row.token,key:key,pagar:true})
      //   })
      //   setData(pagar_)
      //   setBtn(true)
      // }
    })
  }
  // console.log(btnPagar[0]);
  // console.log(response.proveedor);
  const selection = (row, key2, key) => {
    if (btnPagar[key] && btnPagar[key][key2]) {
      let btnPagar_ = [...btnPagar]
      btnPagar_[key][key2].pagar = (btnPagar_[key][key2].pagar) ? false : true
      setBtnPagar(btnPagar_)
      let data_ = [...data]
      if (data_[key] && data_[key].items && data_[key].items[key2]) {
        data_[key].items[key2].pagar = (data_[key].items[key2].pagar === undefined || data_[key].items[key2].pagar === false) ? true : false
        setData(data_)
      }
      verifyStatus(btnPagar_)
    }
    // PostAsync("Pagos","SetAnularPagoEfectuado",{id:row.op_inventario_items_inventario_id},context).then((response)=>{
      //   getInit()
      // })
    }
    console.log("selection",selection);

  const verifyStatus = (data) => {
    const result = data.find(search => search.find(search2 => search2.pagar === true))
    if (result) {
      setBtn(true)
    } else {
      setBtn(false)
    }
  }

  const handleSubmitPagar = () => {
    //setAnularPagoEfectuado()
    context.setModalShow({
      show: true,
      message: <div className="text-center">
        <div className="mb-2">¿Estás seguro que deseas eliminar estos pagos?</div>
        <div className="col ml-auto mr-auto" >
          <div className="col-2 btn btn-warning btn-block me-2" onClick={() => setAnularPagoEfectuado()}>Si</div>
          <div className="col-2 btn btn-secondary btn-block" onClick={() => context.setModalShow({ show: false })}>No</div>
        </div>
      </div>,
      size: ""
    })

  }
  const openDownload = (e) => {
    e.preventDefault()
    let uri = e.target.href + "?";

    if (inputs.fecha_inicio) {
      uri += "fecha_inicio=" + inputs.fecha_inicio + "&"
    }
    if (inputs.fecha_fin) {
      uri += "fecha_fin=" + inputs.fecha_fin + "&"
    }
    if (Object.entries(inputs2).length > 0) {
      Object.entries(inputs2).map((row, key) => {
        if (row[1] && row[1].length > 0) {
          row[1].map((row2, key2) => {
            return uri += row2.name + "[]=" + row2.value + "&"
          })
        } else {
          return false
        }
      })
    }
    //console.log(uri);
    window.open(uri)
  }

  const setAnularPagoEfectuado = () => {

    PostAsync("Pagos", "SetAnularPagoEfectuado", { items: JSON.stringify(btnPagar), token: context.user.token }, context).then((response) => {
      getInit()
    })
  }

  const exportarExcelEfectuado = () => {

    PostAsync("Pagos", "exportarExcelPagoEfectuado", { id: params.id, filtroMultiple: JSON.stringify(inputs2) }, context).then((response) => {
      getInit()
    })
  }



  const checkActive = () => {
    const boolean = data.find(search => search.pagar === true)
    return (boolean !== undefined) ? true : false
  }

  // console.log(proveedor)
  const objetoSerializado = encodeURIComponent(JSON.stringify(inputs2));

  return <div className="container-fluid" style={{ marginTop: '60px' }}>
    <div className="row justify-content-center mb-3">
      <b className='h4'><strong>N.Comprobante:</strong> {params.nComprobante}</b>
      <b className='h4 mb-3'><strong>Proveedor:</strong> {proveedor} </b>
      <div className="col mb-3">
        <a onClick={openDownload} className="btn btn-primary btn-sm me-1 col-1" href={Config.ConfigApirest + 'Excel/Pagos/exportarExcelPagoEfectuado/token:' + context.user.token + '/id:' + params.id + '/nComprobante:' + params.nComprobante} target="_blank">
          <FontAwesomeIcon icon={faFileDownload} /> Excel
        </a>
        <a className="btn btn-primary btn-sm me-10 col-1" target="_blank" href={Config.ConfigApirest + "PDF/Pagos/ListaPagosEfectuadosDetallePDF/" + params.id + "?nComprobante=" + params.nComprobante + "&pdfBool=true&fecha_inicio=" + urlParams.get('fecha_inicio') + "&fecha_fin=" + urlParams.get('fecha_fin') + "&semana=" + urlParams.get('semana') + "&sub_total=" + impuestos.calculo_total + "&iva=" + impuestos.calculo_iva + "&rete_iva=" + impuestos.calculo_reteiva + "&rete_ica=" + impuestos.calculo_reteica + "&rete_fuente=" + impuestos.calculo_retefuente + "&total_todo=" + impuestos.total_pago}>
          <FontAwesomeIcon icon={faPrint} /> PDF
        </a>
        <a target="_blank" className="btn btn-success btn-sm m-1 col-1" href={Config.ConfigApirest + 'Excel/Pagos/wordOfficePagos/token:' + context.user.token + '/id:' + params.id + '/nComprobante:' + params.nComprobante + "/sub_total:" + impuestos.calculo_total + "/iva:" + impuestos.calculo_iva + "/rete_iva:" + impuestos.calculo_reteiva + "/rete_ica:" + impuestos.calculo_reteica + "/rete_fuente:" + impuestos.calculo_retefuente + "/total_todo:" + impuestos.total_pago}>WordOffice</a>
      </div>
    </div>
    <Tabs
      defaultActiveKey="payments"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="payments" title="Pagos">
        <div className="row bg-gray small">
          <div className="col-12 small">
            <b>Filtros de búsqueda</b>
          </div>
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <Input
              title="Fecha desde"
              placeholder="Fecha desde"
              type="date"
              name="fecha_inicio"
              className="form-control"
              onChange={onChangeCustom}
              required={true}
            />
          </div>
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <Input
              title="Fecha hasta"
              placeholder="Fecha hasta"
              type="date"
              name="fecha_fin"
              className="form-control"
              onChange={onChangeCustom}
              required={true}
            />
          </div>
          <div className="col-12 col-sm-3 mb-3 pt-1 text-left border-end">
            <SelectorMultiple
              url="/Inventario/ListadoMarcas"
              name="marca_id"
              title="Marca"
              selectDefault="Marca"
              data={marcas}
              inputs={inputs2}
              setInputs={setInputs2}
              className="form-control"
            />
          </div>
          <div className="col-12 col-sm-4 mb-3 pt-1 text-left border-end">
            <SelectorMultiple
              url="/Usuarios/Lista"
              name="op_inventario_items_id"
              title="Producto"
              selectDefault="Producto"
              data={productos}
              inputs={inputs2}
              setInputs={setInputs2}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="col text-center small">
                    Producto
                  </th>
                  <th className="col text-center small">
                    Fecha compra
                  </th>
                  <th className="col text-center small">
                    Fecha de pago
                  </th>
                  <th className="col text-center small">
                    Marca
                  </th>
                  <th className="col small">
                    Observacion
                  </th>
                  <th className="col small">
                    Cantidad
                  </th>
                  <th className="col small">
                    Peso
                  </th>
                  <th className="col small">
                    Precio Medida
                  </th>
                  <th className="col small">
                    Precio Total
                  </th>
                  <th className="text-center col small">
                    <div className="row">
                      <div className="col-18   text-end small">
                        <div className={selectAll ? "btn btn-primary btn-sm me-2 small" : "btn btn-secondary btn-sm me-2 small"} onClick={handleCheck}>
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                        <div className={btn ? "btn btn-primary btn-sm me-2 small" : "btn btn-secondary btn-sm me-2 small"} onClick={handleSubmitPagar}>
                          <FontAwesomeIcon icon={faDollarSign} /> Eliminar Pago
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, key) => {
                  return <>
                    <tr key={key}>
                      <td className='small'>
                        {row.label}
                      </td>
                      <td className="text-center small">
                        {row.items.map((row2, key2) => {
                          return <div className="mb-4" key={key2}>
                            {row2.fecha_compra_string}
                          </div>
                        })}
                      </td>
                      <td className="text-center small">
                        {row.items.map((row2, key2) => {
                          return <div className="mb-4" key={key2}>
                            {row2.fecha_pago_efectiva_string}
                          </div>
                        })}
                      </td>
                      <td className="text-center small">
                        {row.items.map((row2, key2) => {
                          return <div className="mb-4" key={key2} title={row2.marca}>
                            {row2.marca.length > 15 ? row2.marca.substr(0, 15) + "..." : row2.marca}
                          </div>
                        })}
                      </td>
                      <td className="text-center small">
                        {row.items.map((row2, key2) => {
                          return <div className="mb-4" key={key2} title={row2.observacion}>
                            {row2.observacion.length > 15 ? row2.observacion.substr(0, 15) + "..." : row2.observacion}
                          </div>
                        })}
                      </td>
                      <td className="text-end small">
                        <div className="bg-secondary">
                          {row.items.map((row2, key2) => {
                            return <div className="mb-4 text-white pe-2" key={key2}>
                              {row2.cantidad}
                            </div>
                          })}
                          <div className="bg-secondary text-white  p-2"><b>
                            {totalLineas[key]}
                          </b></div>
                        </div>
                      </td>
                      <td className=" text-end small">
                        <div className="bg-secondary">
                          {row.items.map((row2, key2) => {
                            return <div className="mb-4 text-white pe-2" key={key2}>
                              {row2.peso}
                            </div>
                          })}
                          <div className="bg-secondary text-white  p-2"><b>
                            {format(totalLineasPeso[key])}
                          </b></div>
                        </div>
                      </td>
                      <td>
                        <div className="text-end small">
                          {row.items.map((row2, key2) => {
                            return <div className="mb-4 pe-2" key={key2}>
                              <div>
                                {format(row2.precio)}
                              </div>
                            </div>
                          })}
                        </div>
                      </td>
                      <td className="text-end  text-white small">
                        <div className="bg-secondary">
                          {row.items.map((row2, key2) => {
                            return <div className="mb-4 text-white pe-2" key={key2}>
                              <div>
                                {format(row2.monto_total_compra)}
                              </div>
                            </div>
                          })}
                          <div className="bg-secondary text-white  p-2"><b>
                            {format(totalLineasTotal[key])}
                          </b></div>
                        </div>
                      </td>
                      <td className="text-center border-start small">
                        {btnPagar[key].map((row2, key2) => {
                          // console.log(btnPagar[key]);
                          return <div key={key2} className="mb-3">
                            <div
                              className={row2.pagar === true ? "btn btn-primary btn-sm me-2" : "btn btn-secondary btn-sm me-2"}
                              onClick={() => selection(row2, key2, key)}>
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                            {/* <NavLink to={"/Pagos/ListaEfectados/DetalleEliminado/"+row2.pagos_id} className="btn btn-warning btn-sm">Detalle</NavLink> */}
                            <NavLink to={"/Pagos/ListaEfectados/DetalleEliminado/" + row2.token} className="btn btn-warning btn-sm">Detalle</NavLink>
                          </div>
                        })}


                      </td>
                    </tr>
                  </>
                })}
              </tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="bg-primary text-white  p-1 small" >SUBTOTAL:</td>
                <td className="bg-secondary text-white  p-1 font-bold" >{format(impuestos.calculo_total)}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="bg-primary text-white  p-1 small" >IVA:</td>
                <td className="bg-secondary text-white  p-1 font-bold" >{format(impuestos.calculo_iva)}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="bg-primary text-white  p-1 small" >TOTAL:</td>
                <td className="bg-secondary text-white  p-1 font-bold" >{format(impuestos.calc_mas_iva)}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="bg-primary text-white  p-1 small" >RETEIVA:</td>
                <td className="bg-secondary text-white  p-1 font-bold" >{format(impuestos.calculo_reteiva)}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="bg-primary text-white  p-1 small" >RETEICA:</td>
                <td className="bg-secondary text-white  p-1 font-bold" >{format(impuestos.calculo_reteica)}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="bg-primary text-white  p-1 small" >RETEFUENTE:</td>
                <td className="bg-secondary text-white  p-1 font-bold" >{format(impuestos.calculo_retefuente)}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="bg-primary text-white  p-1 small" >TOTAL PAGADO:</td>
                <td className="bg-primary text-white p-1" ><strong style={{color: 'white'}}>{format(impuestos.total_pago)}</strong></td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </Tab>
      <Tab eventKey="historical" title="Historial de cambios" disabled={historial.length <= 0}>
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Fecha</th>
              <th className="text-center">Hora</th>
              <th className="text-center">Acción</th>
              <th className="text-center">Usuario</th>
            </tr>
          </thead>
          <tbody>
            {historial.map((row, key) => {
              return <tr key={key}>
                <td className="text-center" >{row.fecha_accion}</td>
                <td className="text-center">{row.hora_accion}</td>
                <td className="text-center">{row.accion}</td>
                <td className="text-center">{row.funcionario}</td>
              </tr>
            })}

          </tbody>
        </table>
      </Tab>
    </Tabs>
  </div>
}
export default App
