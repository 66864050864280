import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import SelectorMultiple from "../../components/SelectorMultiple";

import Table from "react-bootstrap/Table";
import Selector from "../../components/Selector";
import StateContext from "../../helpers/ContextState";
import Config from "../../helpers/Config";

import { PostAsync, format, Inputs } from "../../helpers/Functions";
import FormControl from "react-bootstrap/FormControl";

import InputGroup from "react-bootstrap/InputGroup";
import Input from "../../components/InputSimple";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App = (props) => {
  const context = React.useContext(StateContext);
  const params = useParams();

  const [param, setParam] = useState({
    tipo_usuario_id: 2,
    tipo_transaccion: "venta",
  });

  const [compras, setCompras] = useState([]);
  const [ventas, setVentas] = useState([]);
  const [totales, setTotales] = useState([]);

  const [nameProducto, setNameProducto] = useState([]);

  const [restanteCantidad, setRestanteCantidad] = useState([]);
  const [restantePeso, setRestantePeso] = useState([]);

  const [inputs, setInputs] = useState({});
  const [inputs2, setInputs2] = useState({});

  const [semanas, setSemanas] = useState([]);
  const [totalizacion, setTotalizacion] = useState({});

  const [reset, setReset] = useState(false);

  let filter = "";
  {
    console.log(inputs);
  }
  useEffect(() => {
    basicInputs();
  }, []);

  useEffect(() => {
    getInit();
  }, [inputs, inputs2]);

  React.useEffect(() => {
    getInit();
  }, [props.params]);

  const onChangeCustom = (e) => {
    Inputs(e, inputs, setInputs);
  };

  const basicInputs = () => {
    PostAsync(
      "Informes",
      "ListaCompraVentaDetalle",
      { inTrash: 1, fecha: "0000-00-00", limit: 0, id: params.id, start: 0 },
      context
    ).then((response) => {
      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas);
      }
    });
  };

  const getInit = () => {
    setCompras([]);
    setVentas([]);
    setTotales([]);
    PostAsync(
      "Informes",
      "ListaCompraVentaDetalle",
      { ...inputs, filtroMultiple: JSON.stringify(inputs2), id: params.id },
      context
    ).then((response) => {

      console.log(response);
      setRestanteCantidad(response.diferenciales.RestanteCantidad)
      setRestantePeso(response.diferenciales.RestantePeso)
      setNameProducto(response.producto)
      setCompras(response.compra);
      setVentas(response.venta);
      setTotales(response.totales);
      setSemanas(response.semanas);
    });
  };

  let tds = [
    // {
    //   label:"Fecha",
    //   value:"fecha_venta_srting",
    //   className:"col-sm-1 text-center",
    //   classNameHeader:"text-center",
    // },
    // {
    //   label:"Día",
    //   value:"dia",
    //   className:"col-sm-1 text-center",
    //   classNameHeader:"text-center",
    // },
    {
      label: "Producto",
      value: "producto",
      className: " text-left",
      classNameHeader: "col text-left",
    },
    {
      total: true,
      label: "Cantidad vendida",
      value: "cantidad_string",
      className: " text-end",
      classNameHeader: "col text-end",
    },
    {
      total: true,
      label: "Peso vendido",
      value: "peso_vendido_string",
      className: " text-end",
      classNameHeader: "col text-end",
    },
    {
      total: true,
      label: "Monto venta",
      value: "subTotal_string",
      className: " text-end ",
      classNameHeader: "col text-end",
    },
    // {
    //   label:"Acciones",
    //   value:"events",
    //   className:"col-12 col-sm-1 text-center",
    //   classNameHeader:"col-1 text-center",
    //   icons:[
    //           {
    //             label:"Resumen",
    //             NavLink:NavLink,
    //             keys:true,
    //             icon: <FontAwesomeIcon icon={faEye}/>,
    //             url:"/Informes/InformesCompraVenta/Ver/"
    //           },
    //         ]
    // },
  ];

  // return  <div className="container-fluid">
  //           <div className="row">
  //             <div className="col">
  //               <Table
  //                 search={false}
  //                 customFiltersI0={true}
  //                 total={true}
  //                 td={tds}
  //                 modelo="Informes"
  //                 metodo="ListadoCompraVentaDetalle"
  //                 limit="10"
  //                 params={param}
  //               />
  //             </div>
  //           </div>
  //         </div>
  const objetoSerializado = encodeURIComponent(JSON.stringify(inputs2));
  return (
    <>
      <div className="container">
        <div className="row mb-5" style={{ marginTop: "80px" }}>
          {/* <div className="col"></div> */}
          <div className="row">
            {!reset && (
              <>
                <div className="row bg-gray">
                  <div className="col-12 ">
                    <b>Filtros de búsqueda en compras y ventas</b>
                  </div>
                  <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
                    <Input
                      defaultValue={inputs.fecha_inicio}
                      title="Fecha desde"
                      placeholder="Fecha desde"
                      type="date"
                      name="fecha_inicio"
                      className="form-control"
                      onChange={onChangeCustom}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
                    <Input
                      defaultValue={inputs.fecha_fin}
                      title="Fecha hasta"
                      placeholder="Fecha hasta"
                      type="date"
                      name="fecha_fin"
                      className="form-control"
                      onChange={onChangeCustom}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-start">
                    <SelectorMultiple
                      name="semana"
                      title="Semana"
                      selectDefault="Semana"
                      data={semanas}
                      inputs={inputs2}
                      setInputs={setInputs2}
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-start">
                    <a className="btn btn-primary m-2" href={Config.ConfigApirest +
                      "PDF/Informes/ListaCompraVentaDetalle/?pdfBool=true&id=" + params.id + "&fecha_fin=" + inputs.fecha_fin + "&fecha_inicio=" + inputs.fecha_inicio + "&filtroMultiple=" + objetoSerializado} target="_blank">PDF</a>
                    <a className="btn btn-primary" href={Config.ConfigApirest + 'Excel/Informes/ListaCompraVentaDetalle/id:' + params.id + "/fecha_fin:" + inputs.fecha_fin + "/fecha_inicio:" + inputs.fecha_inicio + "/filtroMultiple:" + objetoSerializado} target="_blank">Exportar</a>
                  </div>
                  {/* <div className="col-12 col-sm-4 mb-3 pt-1 text-left">
                            <SelectorMultiple
                            
                                url="/Inventario/ListadoProductos"
                                name="op_inventario_items_id"
                                title="Producto"
                                selectDefault="Producto"
                                data={productos}
                                inputs={inputs2}
                                setInputs={setInputs2}
                                className="form-control"
                            />
                          </div> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <h3 className="text-center">Compra {nameProducto}</h3>
          <Table responsive>
            <tbody>
              <tr>
                <td className="bg-primary text-white h6">Fecha</td>
                <td className="bg-primary text-white h6">SEMANA</td>
                <td className="bg-primary text-white h6">V. Unidad </td>
                <td className="bg-primary text-white h6">No Cantidad</td>
                <td className="bg-primary text-white h6">No Kilos </td>
                <td className="bg-primary text-white h6">COSTO TOTAL COMPRA</td>
              </tr>
              {compras.length > 0 ? (
                compras.map((compra, key) => {
                  return (
                    <tr key={key}>
                      <td>{compra.fecha_recibido_string}</td>
                      <td>{compra.semana_recibido_old}</td>
                      <td>{format(compra.precio)}</td>
                      <td>{format(compra.cantidad)}</td>
                      <td>{format(compra.peso)}</td>
                      <td>{format((compra.peso > 0)?compra.precio * compra.peso : compra.precio * compra.cantidad)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-end">
                    <strong>Sin compras...</strong>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
              {totales && (
                <tr style={{ background: "#ced6e0" }}>
                  <td>
                    <strong>TOTAL</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>{format(totales.totalCantidadCompra)}</strong>
                  </td>
                  <td>
                    <strong>{format(totales.totalPesoCompra)}</strong>
                  </td>
                  <td>
                    <strong>{format(totales.totalPrecioCompra)}</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col>
          <h3 className="text-center">Venta {nameProducto}</h3>
          <Table responsive>
            <tbody>
              <tr>
                <td className="bg-primary text-white h6">Fecha</td>
                <td className="bg-primary text-white h6">SEMANA</td>
                <td className="bg-primary text-white h6">V. Unidad</td>
                <td className="bg-primary text-white h6">No Cantidad</td>
                <td className="bg-primary text-white h6">No Kilos</td>
                <td className="bg-primary text-white h6">COSTO TOTAL VENTA</td>
              </tr>
              {ventas.length > 0 ? (
                ventas.map((venta, key) => {
                  // {console.log("venta",venta)}
                  return (
                    <tr key={key}>
                      <td>{venta.fecha_string}</td>
                      <td>{venta.semana}</td>
                      <td>{format(venta.precio_individual)}</td>
                      <td>{format(venta.cantidad)}</td>
                      <td>{format(venta.peso)}</td>
                      <td>{format(venta.total)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-end">
                    <strong>Sin ventas...</strong>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
              {totales && (
                <tr style={{ background: "#ced6e0" }}>
                  <td>
                    <strong>TOTAL</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>{format(totales.totalCantidadVenta)}</strong>
                  </td>
                  <td>
                    <strong>{format(totales.totalPesoVenta)}</strong>
                  </td>
                  <td>
                    <strong>{format(totales.totalPrecioVenta)}</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <h3 className="text-center mt-4">Tabla diferencial</h3>
        <Table responsive>
          <tbody>
            <tr>
              <td className="bg-primary text-white h6">Cantidad comprada</td>
              <td className="bg-primary text-white h6">Cantidad vendida</td>
              <td className="bg-primary text-white h6">Restante Cantidad</td>
              <td className="bg-primary text-white h6">Peso comprado</td>
              <td className="bg-primary text-white h6">Peso vendido</td>
              <td className="bg-primary text-white h6">Restante Peso</td>
            </tr>
            <tr>
              <td>{totales === null ? 0 : format(totales.totalCantidadCompra)}</td>
              <td>{totales === null ? 0 : format(totales.totalCantidadVenta)}</td>
              <td><strong>{totales === null ? 0 : format(restanteCantidad)}</strong></td>
              <td>{totales === null ? 0 : format(totales.totalPesoCompra)}</td>
              <td>{totales === null ? 0 : format(totales.totalPesoVenta)}</td>
              <td><strong>{format(restantePeso)}</strong></td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </>
  );
};
export default App;
