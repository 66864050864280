import React from 'react';
import StateContext from '../helpers/ContextState';
import { NavLink } from "react-router-dom";
import { bottom } from '@popperjs/core';
import { Link ,useParams } from 'react-router-dom';


const App=()=>{
const params = useParams();
const context     =   React.useContext(StateContext);
// console.log(context)
  return  <div className="p-2 col-6 position-absolute" >
            {context.goBack?<>
              <div className="btn btn-primary btn-sm">
                <Link style={{color: "white", textDecoration:"none"}} to={context.goBack}>Volver</Link>
              </div>
            </>:false}
          </div>
}
export default App