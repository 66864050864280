import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import StateContext from '../../helpers/ContextState';
import Config from '../../helpers/Config';


const App=(props)=>{
  const context                           =   React.useContext(StateContext);
  const [param, setParam]                 =   useState({tipo_usuario_id:2,tipo_transaccion:"venta"});

  let tds       =   [
    {
      label:"Comprobante",
      value:"nComprobante",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
    },
    {
      label:"Fecha Pago",
      value:"fecha_pago_string",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
    },
    {
      label:"Semana",
      value:"semana_recibido",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
    },
    {
      label:"Semestre",
      value:"semestre",
      className:"col-sm-1 text-center",
      classNameHeader:"text-center",
    },
    // {
    //   label:"Día",
    //   value:"dia_recibido",
    //   className:"col-sm-1 text-center",
    //   classNameHeader:"text-center",
    // },
    {
      label:"Proveedor",
      value:"proveedor",
      className:" text-left",
      classNameHeader:"col text-center",
    },
    {
      label:"Producto",
      value:"producto",
      className:" text-left",
      classNameHeader:"col text-center",
    },
    {
      label:"Marca",
      value:"marca",
      className:" text-left",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Cantidad",
      value:"cantidad_format",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Peso",
      value:"peso_format",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Sub total",
      value:"monto_total_pagado_string",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Iva",
      value:"calculo_iva",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Reteiva",
      value:"calculo_reteiva",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Reteica",
      value:"calculo_reteica",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"Retefuente",
      value:"calculo_retefuente",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    {
      total:true,
      label:"P. Total", 
      value:"calculo_total",
      className:" text-center",
      classNameHeader:"col text-center",
    },
    // {
    //   total:true,
    //   label:"Unitario",
    //   value:"precio_unit_string",
    //   className:" text-center",
    //   classNameHeader:"col text-center",
    // },
    // {
    //   total:true,
    //   label:"Precio",
    //   value:"precio_string",
    //   className:" text-center",
    //   classNameHeader:"col text-center",
    // },
    // {
    //   label:"Estado",
    //   value:"estatus",
    //   className:"text-center col-1  text-left",
    //   classNameHeader:"text-center",
    // },
  ]

  return  <div className="container-fluid">
            <div className="row">
              <div className="col">
                <Table
                  customFiltersI={true}
                  total={true}
                  toExport={Config.ConfigApirest+'Excel/Informes/ListadoPagosEfectuadosExcel/token:'+context.user.token}
                  toPDF={Config.ConfigApirest+'PDF/Informes/ListadoPagosEfectuadosFiltro/'+context.user.token}
                  td={tds}
                  modelo="Informes"
                  metodo="ListadoPagosEfectuadosFiltro"
                  limit="10"
                  params={param}
                />
              </div>
            </div>
          </div>
}
export default App
