import React, { useState, useEffect } from "react";
import StateContext from "../../helpers/ContextState";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { PostAsync, format } from "../../helpers/Functions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Config from "../../helpers/Config";
import Pagination from "react-bootstrap/Pagination";

const VentaProductosDetalle = (props) => {
  const context = React.useContext(StateContext);
  const variablesUrl = useParams();
  const [productos, setProductos] = useState([]);
  const [totales, setTotales] = useState([]);
  const [numPaginate, setNumPaginate] = useState([]);

  //Const para el modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    PostAsync("Inventario", "detailProduct", variablesUrl).then((response) => {
      // console.log(response);
      for (let index = 1; index < response.paginator + 1; index++) {
        numPaginate.push(index);
      }
      setProductos(response.productos);
      // console.log(response.totales);
      setTotales(response.totales);
      response.productos.length === 0 ? handleShow() : handleClose();
    });
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center mb-3 mt-5">
      {/* className="row justify-content-center mb-3 mt-5 align-items-center" */}
        <div style={{display:"flex", justifyContent:"space-between", marginBottom:"20px"}} >
          <h2 className="">Relación productos venta</h2> 
          <div>
            <a className="btn btn-secondary me-2" href={`${Config.ConfigApirest}PDF/Inventario/detailProductPDF/${variablesUrl.idVenta}/Detalle/${variablesUrl.id.split(',').join('_')}/${variablesUrl.consecutive}/${variablesUrl.pag}`} target="_blank">PDF</a>
            <a className="btn btn-primary" href={`${Config.ConfigApirest}Excel/Inventario/DetailProductExcel/${variablesUrl.idVenta}/Detalle/${variablesUrl.id.split(',').join('_')}/${variablesUrl.consecutive}/${variablesUrl.pag}`} target="">Exportar</a>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Fecha compra</th>
                <th scope="col">Producto</th>
                <th scope="col">Marca</th>
                <th scope="col">Peso</th>
                <th scope="col">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {productos.map((row, key) => {
                // console.log(row);
                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{row.fecha_compra}</td>
                    <td>{row.producto}</td>
                    {row.marca == "" ? <td>N/A</td> : <td>{row.marca}</td>}
                    {row.peso == "0.00" ? (
                      <td>N/A</td>
                    ) : (
                      <td>{format(row.peso)}</td>
                    )}
                    <td>{row.cantidad}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              {totales.map((total, key) => {
                return (
                  <React.Fragment key={key}>
                    <tr className="bg-primary text-white">
                      <td className="text-white"></td>
                      <td className="text-white"></td>
                      <td className="text-white"></td>
                      <td className="text-end text-white"><strong>Total:</strong></td>
                      <td className="text-white">
                        <strong>{format(total.peso)}</strong>
                      </td>
                      <td className="text-white">
                        <strong>{total.cantidad}</strong>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tfoot>
          </table>
          {/* {
            console.log("Pag url: "+variablesUrl.pag + " Pag button: "+pag)
          }
          {
            log
          } */}
          <div className="d-flex flex-row">
            {numPaginate.map((pag, key) => {
              console.log(
                "Pag url: " + variablesUrl.pag + " Pag button: " + pag
              );
              return (
                <a
                  className={
                    pag == variablesUrl.pag
                      ? "btn btn-primary m-1"
                      : "btn border m-1"
                  }
                  key={key}
                  href={
                    "/Inventario/Ventas/Edit/" +
                    variablesUrl.idVenta +
                    "/Detalle/" +
                    variablesUrl.id +
                    "/" +
                    variablesUrl.consecutive +
                    "/" +
                    pag
                  }
                >
                  {pag}
                </a>
              );
            })}
          </div>
          {
            // console.log(numPaginate)
            // + params.id + "/Detalle/" + row.op_inventario_items_inventario_venta_id + "/" + row.consecutive+"/"+1}
          }

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            animation={true}
          >
            <Modal.Header>
              <Modal.Title>Aviso</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Debes cerrar la venta antes de ver la relación de productos
            </Modal.Body>
            <Modal.Footer>
              <NavLink to={"/Inventario/Ventas/Edit/" + variablesUrl.idVenta}>
                <Button className="bg-primary">Ir a cerrar venta</Button>
              </NavLink>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default VentaProductosDetalle;
