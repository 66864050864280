import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import StateContext from '../../helpers/ContextState';
import { useParams  } from "react-router-dom";
import Config from '../../helpers/Config';



import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{
  const params              =   useParams();
  const context             =   React.useContext(StateContext);
  const [param, setParam]   =   useState({tipo_usuario_id:2,uid:params.uid, modelo: 'ListadoMarcas'});

  let tds       =   [
    {
      label:"#",
      value:"numColum",
      className:"col text-left",
      classNameHeader:"col text-left",
    },
    {
      label:"ID o NIT",
      value:"nro_documento",
      className:"col-12 col-sm-2 text-left",
      classNameHeader:"col-3 text-left",
    },
    {
      label:"Marcas",
      value:"marca",
      className:"col-12 col-sm-2 text-left",
      classNameHeader:"col-3 text-left",
    },
    {
      label:"Proveedor",
      value:"proveedor",
      className:"col-12 col-sm text-left",
      classNameHeader:"col-3 text-left",
    },
    {
      label:"Planta",
      value:"planta",
      className:"col-12 col-sm text-left",
      classNameHeader:"col-3 text-left",
    },
    {
      label:"Estado",
      value:"estatus_string",
      className:"col-12 col-sm text-center",
      classNameHeader:"col-3 text-center",
    },
    {
      label:"Acciones",
      value:"events",
      className:"col-12 col-sm-1 text-center",
      classNameHeader:"col-1 text-center",
      icons:[
              {
                label:"Resumen",
                NavLink:NavLink,
                icon: <FontAwesomeIcon icon={faEdit}/>,
                url:params.uid===undefined?"/Inventario/Item/Edit/":"/Inventario/Item/Edit2/"+params.uid+"/"
              },
            ]
    },
  ]

  return  <div className="container-fluid">
            <div className="row">
              <div className="col">
                <Table
                  filterCustom={true}
                  toAdd="Item/Edit/0"
                  add={NavLink}
                  toExport={Config.ConfigApirest+'Excel/Informes/ListadoMarcas/token:'+context.user.token}
                  toPDF={Config.ConfigApirest+'PDF/Informes/ListadoMarcas/'+context.user.token}
                  td={tds}
                  modelo="Inventario"
                  metodo="get_items_marcas"
                  limit="5"
                  params={param}
                />
              </div>
            </div>
          </div>
}
export default App
