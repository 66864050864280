import React,{useState} from 'react';
import {Inputs,PostAsync} from '../helpers/Functions';
import StateContext from '../helpers/ContextState';
import Button from 'react-bootstrap/Button';

const App=(props)=>{
  const context             =   React.useContext(StateContext);
  const [inputs, setInputs] =   useState({});
  const onChange=(e)=>{
    Inputs(e,inputs, setInputs)
  }
  const onSubmit=(e)=>{
    e.preventDefault()
    let inputs_               = {...inputs}
        inputs_.op_plantas_id = props.op_plantas_id
    PostAsync("Users","SetUsuarioPlantasContacto",inputs_,{...context}).then((response) => {
      document.getElementById("myForm").reset();
      if (props.getInit!==undefined) {
        props.getInit(true)
        props.context.setModalShow(
                                {
                                  show:false,
                                  message:  <></>,
                                  size:"xs"
                                }
                              )
      }
    })
  }
  return  <form onSubmit={onSubmit} id="myForm">
            <div className="row">
              <div className="col text-start">
                <input  className="form-control"
                          required={true}
                          type="text"
                          name="contacto"
                          placeholder="Contacto"
                          onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <input  className="form-control"
                          required={true}
                          type="text"
                          name="cargo"
                          placeholder="Cargo"
                          onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <input  className="form-control"
                          required={true}
                          type="text"
                          name="telefono"
                          placeholder="Teléfono"
                          onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <input  className="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <input  className="form-control"
                          type="text"
                          name="comentario"
                          placeholder="Comentario"
                          onChange={onChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col text-start">
                <div className="d-grid gap-2">
                  <Button variant="primary" type="submit">Agregar</Button>
                </div>
              </div>
            </div>
          </form>
}

export default App
