import React,{useEffect,useState} from 'react';
import StateContext from '../../helpers/ContextState';
import {Inputs,PostAsync} from '../../helpers/Functions';
import Store from '../../helpers/Store';
import banner from '../../assets/images/banner.jpg';
import logo from '../../assets/images/logo.jpg';

const defaultValue={
                      // nombres:"Alejandro E",
                      // apellidos:"Tobón",
                      // cedula:"123456789",
                      // celular:"3115000926",
                      // login:"3115000926",
                      // password:"3115000926",
                      // direccion:"Carrera 6ta",
                    }

const App=()=>{
  const context             = React.useContext(StateContext);
  const [inputs, setInputs] = useState(defaultValue);
  const [send, setSend]     = useState(false);

  useEffect(() => {
    context.loading(false)
    if (!send) {
      if (process.env.NODE_ENV==='development') {
        return init()
      }
      setSend(true)
    }
  },[context,send])

  const init=()=>{
    let object={}
    context.constants.Modulos.map((row,key)=>{
      if (row.items) {
        row.items.map((row2,key2)=>{
          if (row2.items!==undefined) {
            row2.items.map((row3,key3)=>{
              return object[row3.label] = JSON.stringify(row3)
            })
            return false
          }else {
            return object[row2.label] = JSON.stringify(row2)
          }
        })
        return object[row.label] = JSON.stringify(row)
      }else {
        return object[row.label] = JSON.stringify(row)
      }
    })
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    PostAsync("Users","loginPWA",inputs,{...context}).then((response)=>{
      if (response.data!==undefined) {
        Store.set("user",response.data);
        Store.set("privilegios",response.privilegios);
        context.setUser(response.data)
        context.setPrivilegios(response.privilegios)
      }
    })
  }

  const onChange=(e)=>{
    Inputs(e,inputs, setInputs)
  }

  return    <>
              {!context.loadingStatus?<>
                    <div className={"container-fluid h-100"}>
                      <div className="row h-100">
                        <div className="col-12 col-sm-6  h-100 d-none d-sm-block">
                          <div className="App-header">
                            <div className="h1 text-white" >
                              <img src={banner} alt="programandoweb"  className="img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 right h-100">
                          <div className="App-header">
                            <img src={logo} alt="logo-asocolcarnes"  />
                            <form onSubmit={onSubmit} className="row justify-content-center">
                              <div className="col-9 mb-3 h3 text-center border-bottom  border-danger border-5 pb-2">
                                Inicio de sesión
                              </div>
                              <div className="col-10">
                                <div className="row justify-content-center">
                                  <div className="col-12 col-sm-6 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="text"
                                            name="login"
                                            placeholder="Nombre de Usuario"
                                            onChange={onChange}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="password"
                                            name="password"

                                            placeholder="Clave personal"
                                            onChange={onChange}
                                    />
                                  </div>
                                  <div className="col-12 mb-2 ">
                                    <div className="d-grid gap-2">
                                      <button type="submit" className="btn btn-danger">Entrar</button>
                                    </div>
                                    <div className="d-grid gap-2">
                                      <a href="./recover">
                                        Recuperar Contraseña
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>:false}
            </>



}
export default App
