import React,{useState,useEffect} from 'react';
import {PostAsync,Segment,Inputs} from '../../helpers/Functions';
import { useParams, useHistory  } from "react-router-dom";
import Input from '../../components/Inputs';
import Select from '../../components/Select';
import StateContext from '../../helpers/ContextState'
import { NavLink } from "react-router-dom";
import Table from 'react-bootstrap/Table'
import SelectorGenerico from '../../components/SelectorGenerico';

let proveedores = []

const App=()=>{
  const params                              =   useParams();
  let   history                             =   useHistory();
  const context                             =   React.useContext(StateContext);
  const [inputs, setInputs]                 =   useState({});
  const [historial, setHistorial]           =   useState([]);
  const [data, setData]                     =   useState([]);


  const onSubmit=(e)=>{
    e.preventDefault()
    let send  = {...inputs}
    PostAsync("Inventario","SetPlanta",{...send},{...context}).then((response)=>{
      if (response.error===undefined) {
        history.push("/Inventario/Plantas/Edit/"+response.uid);
      }
    })
  }

  const onChange=(e)=>{
    Inputs(e,inputs, setInputs);
  }


  const getInit=()=>{
    PostAsync("Inventario","GetPlanta",{...params},{...context}).then((response)=>{
      if (response.data!==undefined && response.data!==null) {
        setInputs(response.data)
      }
    })
  }

  useEffect(() => {
    if (params.id!=="0") {
      context.setLoadingStatus(true)
    }
    getInit()
  },[])

  
  return <div className="container">
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={onSubmit}>
                      <div className="h3"><b>Gestión de plantas </b></div>
                      <div className="row pb-2">
                        <div className="col-12 col-sm-3 mb-2">
                          <Input
                              defaultValue={inputs.label}
                              title="Nombre de la planta"
                              placeholder="Nombre de la planta"
                              type="text"
                              name="label"
                              className="form-control"
                              onChange={onChange}
                              required={true}
                          />
                        </div>
                        <div className="col-12 col-sm-3 mb-2">
                          <Input
                              defaultValue={inputs.direccion}
                              title="Dirección"
                              placeholder="Dirección"
                              type="text"
                              name="direccion"
                              className="form-control"
                              onChange={onChange}
                          />
                        </div>
                        <div className="col-12 col-sm-3 mb-2">
                          <Input
                              defaultValue={inputs.telefono}
                              title="Teléfono"
                              placeholder="Teléfono"
                              type="number"
                              name="telefono"
                              className="form-control"
                              onChange={onChange}
                          />
                        </div>
                        <div className="col-12 col-sm-3 mb-2">
                          <Input
                              defaultValue={inputs.email}
                              title="email"
                              placeholder="email"
                              type="email"
                              name="email"
                              className="form-control"
                              onChange={onChange}
                          />
                        </div>

                      </div>
                      <div className="row justify-content-center pb-2">
                        <div className="col-12 col-sm-2 mb-2">
                          <button type="submit" className="btn btn-primary col-12">Guardar</button>
                        </div>
                        <div className="col-12 col-sm-2 mb-2">
                          <NavLink className="btn btn-secondary col-12" to={"/Inventario/Listado/ListadoPlantas"}>Cancelar</NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
          </div>
          {historial.length>0?<>
            <div className="row">
              <div className="col h5 mt-3">
                Historial cambios de precios
              </div>
            </div>
            <div className="row">
              <div className="col">
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th className="text-center">Asociado Anterior</th>
                    <th className="text-center">Asociado Actual</th>
                    <th className="text-center">No Asociado Anterior</th>
                    <th className="text-center">No Asociado Actual</th>
                  </tr>
                </thead>
                <tbody>
                {historial.map((row,key)=>{
                  return  <tr key={key}>
                            <td>{key+1}</td>
                            <td>{row.fecha}</td>
                            <td className="text-end">{row.precio_asociado_anterior}</td>
                            <td className="text-end">{row.precio_asociado_nuevo}</td>
                            <td className="text-end">{row.precio_no_asociado_anterior}</td>
                            <td className="text-end">{row.precio_no_asociado_nuevo}</td>
                          </tr>
                })}

                </tbody>
                </Table>
              </div>
            </div>
          </>:false}
        </div>

}

export default App
