import React, { useEffect, useState } from "react";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import StateContext from "../helpers/ContextState";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const App = () => {
  const context = React.useContext(StateContext);
  const [resultado, setResultado] = useState([]);
  const history = useHistory();
  const modulos = context.constants.Modulos;

  useEffect(() => {
    return history.listen((location) => {
      context.setExtraPosition("");
      process();
      // let  resultado_main     =    modulos.find( buscar => (buscar.url === history.location.pathname) || (buscar.items!==undefined && buscar.items.find( buscar2 =>buscar2.url===history.location.pathname)))
      //
      // if(resultado_main && resultado_main.items &&  resultado_main.items.length>0) {
      //   setResultado(resultado_main.items.find( buscar => buscar.url === history.location.pathname))
      // }else {
      //   setResultado(resultado_main?resultado_main:{label:"Home"});
      // }
    });
  }, [history]);

  useEffect(() => {
    process();
  }, [history.location.pathname]);

  const process = () => {
    let shortCode = "";
    history.location.pathname.split("/").map((row, key) => {
      if (row !== "" && key <= 3) {
        return (shortCode += "/" + row);
      } else {
        return false;
      }
    });

    let resultado_main = modulos.find(
      (buscar) =>
        buscar.shortCode === shortCode ||
        (buscar.items !== undefined &&
          buscar.items.find((buscar2) => buscar2.shortCode === shortCode))
    );
    let paths = [];
    let result2 = {};

    if (resultado_main && resultado_main.shortCode === "/configuracion") {
      paths.push({ label: resultado_main.subLabel, url: false });
      setResultado(paths);
    }

    if (
      resultado_main &&
      resultado_main.items &&
      resultado_main.items.length > 0
    ) {
      result2 = resultado_main.items.find(
        (buscar) => buscar.shortCode === shortCode
      );
      if (result2) {
        paths.push({ label: result2.subLabel, url: false });
        if (result2.goBack) {
          if (result2.goBack === "/Pagos/ListaEfectados/Detalle") {
            result2.goBack = "/Pagos/ListaEfectados";
          }
          if (!result2.subLabel2) {
            result2.subLabel2 = resultado_main.label;
          }
          paths.push({ label: result2.subLabel2, url: result2.goBack });
          //console.log(resultado_main);
          context.setGoBack(result2.goBack);
          if (
            result2.goBack !== "/Pagos/Lista" &&
            result2.goBack === "/Pagos/Lista" &&
            result2.goBack === "/Pagos/ListaEfectados"
          ) {
            //console.log(result2.goBack==="/Pagos/ListaEfectados");
            paths.push({ label: result2.subLabel2, url: result2.goBack });
          } else if (result2.goBack === "/Pagos/Lista") {
            paths.push({ label: "Pagos a realizar", url: result2.goBack });
          } else if (result2.goBack === "/Pagos/ListaEfectados") {
            paths.push({ label: "Pagos efectuados", url: result2.goBack });
          }
          paths.push({ label: result2.label, url: false });
        } else {
          paths.push({ label: result2.label, url: false });
          context.setGoBack(false);
        }

        setResultado(paths);
      }
    } else if (resultado_main && !resultado_main.items) {
      //console.log(500);
      //console.log(resultado_main);
    } else {
      setResultado([]);
    }

    if (history.location.pathname.includes("/Pagos/Lista/Detalle/")) {
      context.extraPosition = "/ Pago detallado";
      //console.log(history.location.pathname,context.extraPosition);
    }

    if (history.location.pathname.includes("/Pagos/ListaEfectados/Detalle/")) {
      context.extraPosition = "";
      //console.log(history.location.pathname,context.extraPosition);
    }
  };

  // console.log(resultado)
  return (
    <>
      <FontAwesomeIcon icon={faHome} />
      <span className="ms-2">
        <NavLink to={"/"}>Home</NavLink>
        {resultado.map((row, key) => {
          if (row.label || (row.label === undefined && row.url)) {
            return (
              <span key={key}>
                <span className="m-2">/</span>
                {row.url && row.label ? (
                  <NavLink to={row.url}>{row.label}</NavLink>
                ) : row.url && !row.label ? (
                  <NavLink to={row.url}>M</NavLink>
                ) : (
                  row.label
                )}
              </span> 
            );
          } else {
            return false;
          }
        })}
        <span className="ms-2">{context.extraPosition}</span>
      </span>
    </>
  );
};
export default App;
