import React, { useState, useEffect } from 'react';
import { Inputs, PostAsync } from '../helpers/Functions';
import StateContext from '../helpers/ContextState';
import Button from 'react-bootstrap/Button';

const App = (props) => {
    console.log(props.op_plantas_contactos_id);
    const context = React.useContext(StateContext);
    const [inputs, setInputs] = useState({});
    const [inputs2, setInputs2] = useState({});

    useEffect(() => {
        getInit();
    }, []);

    const getInit = () => {
        PostAsync("Users", "initContact", { idContacto: props.op_plantas_contactos_id }, { ...context }).then((response) => {
            setInputs2(response.contacto[0])
            console.log(response.contacto[0]);
        })
    }

    const onChange = (e) => {
        Inputs(e, inputs2, setInputs2)
    }

    const onSubmit = (e) => {
        PostAsync("Users", "editContact", { ...inputs2 }, { ...context }).then((response) => {
            context.setModalShow({
                show: true,
                size: "md",
                message: <div className="text-center">
                    <div className="mb-3">CONTACTO EDITADO CON ÉXITO!</div>
                    <div className="btn btn-primary mt-3" onClick={() => { context.setModalShow({ show: false, message: "", size: "sm", });}}>Cerrar</div>
                </div>
            })
        })



    }

    return <form onSubmit={onSubmit} id="myForm">
        <div className="row">
            <div className="col text-start">
                <input className="form-control"
                    defaultValue={inputs2.contacto}
                    required={true}
                    type="text"
                    name="contacto"
                    placeholder="Contacto"
                    onChange={onChange}
                />
            </div>
        </div>
        <div className="row">
            <div className="col text-start">
                <input className="form-control"
                    defaultValue={inputs2.cargo}
                    required={true}
                    type="text"
                    name="cargo"
                    placeholder="Cargo"
                    onChange={onChange}
                />
            </div>
        </div>
        <div className="row">
            <div className="col text-start">
                <input className="form-control"
                    defaultValue={inputs2.telefono}
                    required={true}
                    type="text"
                    name="telefono"
                    placeholder="Teléfono"
                    onChange={onChange}
                />
            </div>
        </div>
        <div className="row">
            <div className="col text-start">
                <input className="form-control"
                    defaultValue={inputs2.email}
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={onChange}
                />
            </div>
        </div>
        <div className="row">
            <div className="col text-start">
                <input className="form-control"
                    defaultValue={inputs2.comentario}
                    type="text"
                    name="comentario"
                    placeholder="Comentario"
                    onChange={onChange}
                />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col text-start">
                <div className="d-grid gap-2">
                    <Button variant="primary" type="submit">Editar</Button>
                </div>
            </div>
        </div>
    </form>
}

export default App
